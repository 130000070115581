<template>
    <!-- The Modal -->
    <div class="modal fade" id="myReportModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        Unsubscribe Email Report
                    </h2>

                    <ejs-grid ref="ugrid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete' :pdfExportComplete='exportComplete'
                              :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings" :actionBegin="actionBegin">
                        <e-columns>
                            <e-column field="email" headerText="Email" text-Align="Left" width="200"></e-column>
                            <e-column field="hash" headerText="Email Hash" text-Align="Left" width="700"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import {GridPlugin, Group, Page, Sort, Toolbar, PdfExport, ExcelExport} from "@syncfusion/ej2-vue-grids";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import axios from "axios";
import swal from "sweetalert";


Vue.use(GridPlugin);

export default {
    name: "ReportModal",
    provide: {
        grid: [Page, Sort, Group, Toolbar, PdfExport,ExcelExport]
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.filter();
                    this.$refs.ugrid.ej2Instances.pageSettings.currentPage = 1;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            page: 1,
            data: [],
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 20, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
        }
    },
    methods: {
        actionBegin(args) {
            console.log("action: ", args.requestType)
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                this.filter();
            }
        },
        exportComplete() {
            this.$refs.ugrid.hideSpinner();
        },
        toolbarClick: async function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.$refs.ugrid.showSpinner();
                    (this.$refs.ugrid).excelExport(await this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    this.$refs.ugrid.showSpinner();
                    (this.$refs.ugrid).pdfExport(await this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: async function() {
            let request = {page: 1, max: 0}
            let resp = await axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report/${this.item._id}`, {params: request, json: true,});

            return {
                fileName: `${this.item.name} - email report.xlsx`,
                dataSource: resp.data.Items
            };
        },
        getPdfExportProperties: async function() {
            let request = {page: 1, max: 0}
            let resp = await axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report/${this.item._id}`, {params: request, json: true,});

            return {
                fileName: `${this.item.name} - email report.pdf`,
                dataSource: resp.data.Items
            };
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report/${this.item._id}`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        filter() {
            let request = {max: this.$refs.ugrid.ej2Instances.pageSettings.pageSize, page: this.page};
            axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report/${this.item._id}`, {params: request}).then((resp) => {
                this.data = {result: resp.data.Items, count: resp.data.Count};
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
</style>
