<template>
    <!-- The Modal -->
    <div class="modal fade" id="myErrorDetailModal" style="margin-top: 20px;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header" style="background: teal">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        Proxy Error Report
                    </h2>

                    <div class="row">
                        <div v-if="item.proxy" class="col-md-12">
                            <div><strong>Proxy Info</strong></div>
                            <ul>
                                <li>host: {{item.proxy.ip}}</li>
                                <li>port: {{item.proxy.port}}</li>
                                <li>username: {{item.proxy.username}}</li>
                                <li>password: {{item.proxy.password}}</li>
                                <li>remote IP: {{item.proxy.remoteip}}</li>

                                <div class="mt-2 mb-2"><strong>Requested:</strong></div>
                                <li>Date: {{ item.date | userTime(user.timezone) }}</li>
                                <li>Country: {{ item.proxy.country }}</li>
                                <li>State: {{ item.proxy.state }}</li>
                                <li>City: {{ item.proxy.city }}</li>
                                <li>Radius: {{ item.distance }}</li>
                                <li>Max Radius: {{ item.max_radius }}</li>
                            </ul>
                        </div>

                        <div v-if="item.request" class="col-md-12 mt-3">
                            <div><strong>Request Info</strong></div>
                            <ul>
                                <li>url: {{item.request.url}}</li>
                                <li>method: {{item.request.method}}</li>
                                <li>
                                    proxy
                                    <ul>
                                        <li v-for="(value, key) in item.request.proxy" :key="key">{{key}}: {{value}}</li>
                                    </ul>
                                </li>
                                <li>
                                    headers
                                    <ul>
                                        <li v-for="(value, key) in item.request.headers" :key="key">{{key}}: {{value}}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div v-if="item.response" class="col-md-12 mt-3">
                            <div><strong>Response Info</strong></div>
                            <ul>
                                <li v-if="item.response.statusCode">Status Code: {{item.response.statusCode}}</li>
                                <li v-if="item.response.statusMessage">Status Message: {{item.response.statusMessage}}</li>
                                <li v-if="item.response.body">Body: {{item.response.body}}</li>

                                <li v-if="item.response.errno">Errno: {{item.response.errno}}</li>
                                <li v-if="item.response.message">Message: {{item.response.message}}</li>
                                <li v-if="item.response.hostname">Hostname: {{item.response.hostname}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ErrorDetailsModal",
    props: {
        user: {
            type: Object
        },
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            page: 1,
            count: 0,
            data: [],
            preloader: false,
            max: 25,
            loading: true,
        }
    },
    methods: {
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
    z-index: 9999999;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow-y: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
</style>
