<template>
    <!-- The Modal update -->
    <div id="app" class="container">
        <h3> Campaign: {{ campaign.campaign_name }}</h3>
        <h6 v-if="campaign.isp == 'Outlook'"> ISP: <span class="text-primary">{{campaign.isp}}</span>  <i class="fa fa-windows" aria-hidden="true"></i></h6>
        <h6 v-if="campaign.isp == 'Gmail'"> ISP: <span class="text-primary">{{campaign.isp}}</span>  <i class="fa fa-google" aria-hidden="true"></i></h6>
        <p :class="campaign.status == 'active' ? 'text-success text-right font-weight-bold' : 'text-danger text-right font-weight-bold' ">{{ campaign.status.toUpperCase() }}</p>
        <hr class="pb-4">
        <div class="card container-fluid ">

            <div class="row">
                <div class="col-3 col-md-3 col-lg-3 col-sm-3 pt-4">
                    <b-card
                        tag="article"
                        class="mb-2"
                        border-variant="dark"
                    >
                        <div class="text-center py-3">
                            <i class="fa fa-envelope fa-3x" aria-hidden="true"></i>
<!--                            <i class="fa fa-camera-retro fa-3x"></i> &lt;!&ndash; Font Awesome icon &ndash;&gt;-->
                        </div>
                        <b-card-body >
                            <h5>Read</h5>
                            <hr>
                            <p class="text-success">{{campaign.numberOfRead || 0}}</p>
                        </b-card-body>

                    </b-card>
                </div>

                <div class="col-3 col-md-3 col-lg-3 col-sm-3 pt-4">
                    <b-card
                        tag="article"
                        border-variant="dark"
                        class="mb-2"
                    >
                        <div class="text-center py-3">
                            <i class="fa fa-reply fa-3x" aria-hidden="true"></i>
<!--                            <i class="fa fa-camera-retro fa-3x"></i> &lt;!&ndash; Font Awesome icon &ndash;&gt;-->
                        </div>
                        <b-card-body >
                            <h5>Replied</h5>
                            <hr>
                            <p class="text-success">{{campaign.numberOfReply || 0}}</p>
                        </b-card-body>

                    </b-card>
                </div>

                <div class="col-3 col-md-3 col-lg-3 col-sm-3 pt-4">
                    <b-card
                        tag="article"
                        border-variant="dark"
                        class="mb-2"
                    >
                        <div class="text-center py-3">
                            <i class="fa fa-share fa-3x" aria-hidden="true"></i>
<!--                            <i class="fa fa-camera-retro fa-3x"></i> &lt;!&ndash; Font Awesome icons &ndash;&gt;-->
                        </div>
                        <b-card-body >
                            <h5>Forwarded</h5>
                            <hr>
                            <p class="text-success">{{campaign.numberOfForward || 0}}</p>
                        </b-card-body>

                    </b-card>
                </div>
                <div class="col-3 col-md-3 col-lg-3 col-sm-3 pt-4">
                    <b-card
                        tag="article"
                        border-variant="dark"
                        class="mb-2"
                    >
                        <div class="text-center py-3">
                            <i class="fa fa-trash fa-3x" aria-hidden="true"></i>
<!--                            <i class="fa fa-camera-retro fa-3x"></i> &lt;!&ndash; Font Awesome icon &ndash;&gt;-->
                        </div>
                        <b-card-body >
                            <h5>Deleted</h5>
                            <hr>
                            <p class="text-success">{{campaign.numberOfDelete || 0}}</p>
                        </b-card-body>

                    </b-card>
                </div>


            </div>
            <div class="row">
                <div class="col-3 col-md-3 col-lg-3 col-sm-3 pt-4">
                    <b-card
                        tag="article"
                        border-variant="dark"
                        class="mb-2"
                    >
                        <div class="text-center py-3">
                            <i class="fa fa-eraser fa-3x"></i> <!-- Font Awesome icon -->
                        </div>
                        <b-card-body >
                            <h6>Junk Removed</h6>
                            <hr>
                            <p class="text-success">{{campaign.numberOfJunk || 0}}</p>
                        </b-card-body>

                    </b-card>
                </div>
                <div class="col-9 col-md-9 col-lg-9 col-sm-9 pt-4">
                    <b-card
                        tag="article"
                        border-variant="dark"
                        class="mb-2 container-fluid"
                    >
<!--                        <div class="text-center py-3">-->
<!--                            <h6>Email Accounts</h6>-->
<!--                            <hr>&lt;!&ndash; Font Awesome icon &ndash;&gt;-->
<!--                        </div>-->
                        <b-card-body >
                            <div class="row">
                                <div class="col-4">
                                    <b-card>
                                        <div class="tab-content">
                                            <h6>Session Behaviors</h6>
                                            <hr>
                                        </div>
                                        <ol class="list-group list-group-flush p-0">
                                            <li v-for="(email, index) in campaign.emailBehaviors" :key="index" class="list-group-item p-0 m-0 text-left">
                                              <span class="font-weight-bold">{{ index+=1 }}:</span> {{ email }}
                                            </li>
                                        </ol>
                                    </b-card>
                                </div>
                                <div class="col-4">
                                    <b-card>
                                        <div class="tab-content">
                                            <h6>Date And Time</h6>
                                            <hr>
                                        </div>
                                        <ol class="list-group list-group-flush p-0">
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">From :</span> {{ campaign.from }}
                                            </li>
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">To :</span> {{ campaign.to }}
                                            </li>
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">Start Time :</span> {{ campaign.startTime }}
                                            </li>
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">End Time :</span> {{ campaign.endTime }}
                                            </li>
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">Timezone :</span> {{ campaign.timezone }}
                                            </li>
                                            <li  class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">Time On Site :</span> {{ campaign.time }} minutes
                                            </li>


                                        </ol>
                                    </b-card>

                                </div>
                                <div class="col-4">
                                    <b-card>
                                        <div class="tab-content">
                                            <h6>Day of the Week for Actions </h6>
                                            <hr>
                                        </div>
                                        <ol class="list-group list-group-flush p-0">
                                            <li v-for="(days, index) in campaign.daysOfTheWeek" :key="index" class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">{{ index+=1 }}:</span> {{ days }}
                                            </li>
                                        </ol>
                                    </b-card>
                                </div>

                            </div>

                        </b-card-body>

                    </b-card>
                </div>

            </div>

            <div class="row">
                <div class="col-6 col-md-6 col-lg-6 col-sm-6 pt-4">
                    <b-card tag="article" class="mb-2" border-variant="dark">

                        <!-- Header Section -->
                        <div class="text-center py-3">
                            <h6>Email Accounts</h6>
                            <hr><!-- Divider between the title and content -->
                        </div>

                        <!-- Card Body with Table -->
                        <b-card-body>
                            <b-table
                                :items="campaign.emailAddressList"

                                bordered
                                hover
                                striped
                                responsive
                            >
                                <!-- Email Column -->
                                <template #cell(email)="data">
                                    <span>{{ data.item.email }}</span>
                                </template>

                                <!-- Password Column -->
                                <template #cell(password)="data">
                                    <span>{{ data.item.password }}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                </div>
                <div class="col-6 col-md-6 col-lg-6 col-sm-6 pt-4">
                    <b-card tag="article" class="mb-2" border-variant="dark">

                        <!-- Header Section -->
                        <div class="text-center py-3">
                            <h6>Junk Removal</h6>
                            <hr><!-- Divider between the title and content -->
                            <p class="text-right">Junk Active: <span :class="campaign.junkActive == false ? 'text-danger':'text-success'">{{campaign.junkActive}}</span></p>
                        </div>

                        <!-- Card Body with Table -->
                        <b-card-body>
                            <b-table :items="campaign.junks" :fields="['sender_email','Removal']" bordered hover striped>
                                <!-- Email Column -->
                                <template #cell(sender_email)="data">
                                    <span>{{ data.item.sender_email }}</span>
                                </template>

                                <!-- Junk Select Column -->
                                <template #cell(Removal)="data">
                                    <span>{{ data.item.junkSelect ? 'Yes' : 'No' }}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-md-6 col-lg-6 col-sm-6 pt-4">
                    <b-card tag="article" class="mb-2" border-variant="dark">

                        <!-- Header Section -->
                        <div class="text-center py-3">
                            <h6>Fowarding Emails</h6>
                            <hr><!-- Divider between the title and content -->
                        </div>

                        <!-- Card Body with Table -->
                        <!-- Card Body with Table -->
                        <b-card-body>
                            <b-table :items="campaign.forwardings" :fields="['email','sender_email']" bordered hover striped>
                                <!-- Email Column -->
                                <template #cell(email)="data">
                                    <span>{{ data.item.email }}</span>
                                </template>

                                <!-- Junk Select Column -->
                                <template #cell(sender_email)="data">
                                    <span>{{ data.item.sender_email}}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                </div>
                <div class="col-6 col-md-6 col-lg-6 col-sm-6 pt-4">
                    <b-card tag="article" class="mb-2" border-variant="dark">

                        <!-- Header Section -->
<!--                        <div class="text-center py-3">-->
<!--                            <h6>Delete List</h6>-->
<!--                            <hr>&lt;!&ndash; Divider between the title and content &ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="text-right">Junk Active: <span :class="campaign.junkActive == false ? 'text-danger':'text-success'">{{campaign.junkActive}}</span></p>&ndash;&gt;-->
<!--                        </div>-->

                        <!-- Card Body with Table -->
                        <b-card-body>
                            <div class="row">
                            <div class="col-4">
                                <b-card>
                                    <div class="tab-content">
                                        <h6>Delete List</h6>
                                        <hr>
                                    </div>
                                    <ol class="list-group list-group-flush p-0">
                                        <li v-for="(list, index) in campaign.deleteList" :key="index" class="list-group-item p-0 m-0 text-left">
                                            <span class="font-weight-bold">{{ index+=1 }}:</span> {{ list }}
                                        </li>
                                    </ol>
                                </b-card>
                            </div>
                                <div class="col-4">
                                    <b-card>
                                        <div class="tab-content">
                                            <h6>Exclusion List</h6>
                                            <hr>
                                        </div>
                                        <ol class="list-group list-group-flush p-0">
                                            <li v-for="(list, index) in campaign.exclusionList" :key="index" class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">{{ index+=1 }}:</span> {{ list }}
                                            </li>
                                        </ol>
                                    </b-card>
                                </div>
                                <div class="col-4">
                                    <b-card>
                                        <div class="tab-content">
                                            <h6>Forward List</h6>
                                            <hr>
                                        </div>
                                        <ol class="list-group list-group-flush p-0">
                                            <li v-for="(list, index) in campaign.forwardList" :key="index" class="list-group-item p-0 m-0 text-left">
                                                <span class="font-weight-bold">{{ index+=1 }}:</span> {{ list }}
                                            </li>
                                        </ol>
                                    </b-card>
                                </div>
                            </div>

                        </b-card-body>
                    </b-card>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-12 col-lg-12 col-sm-12 pt-4">
                    <b-card tag="article" class="mb-2" border-variant="dark">

                        <!-- Header Section -->
                        <div class="text-center py-3">
                            <h6>Rules</h6>
                            <hr><!-- Divider between the title and content -->
                        </div>


                        <!-- Card Body with Table  just for pushing-->
                        <b-card-body>
                            <b-table :items="campaign.rules" :fields="['behaviors','campaign_name','junk_Email_Removal_Enabled','sender_email','status']" bordered hover striped>
                                <!-- Email Column -->
                                <template #cell(behaviors)="data">
                                    <span>{{ data.item.behaviors.join(',') }}</span>
                                </template>

                                <!-- Junk Select Column -->
                                <template #cell(campaign_name)="data">
                                    <span>{{ data.item.campaign_name}}</span>
                                </template>
                                <template #cell(junk_Email_Removal_Enabled)="data">
                                    <span>{{ data.item.junkEmailRemovalEnabled}}</span>
                                </template>
                                <template #cell(sender_email)="data">
                                    <span>{{ data.item.sender_email}}</span>
                                </template>
                                <template #cell(status)="data">
                                    <span :class="data.item.status == 'active'?'text-success':'text-danger'">{{ data.item.status}}</span>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                </div>
            </div>
<!--            <p><span class="label">Client:</span>{{ campaign.client}}</p>-->
<!--            <p><span class="label">ISP:</span> {{campaign.isp}}</p>-->
<!--            <p><span class="label">Status:</span> {{campaign.status}}</p>-->
<!--            <p><span class="label">Time:</span> {{campaign.time}}</p>-->
<!--            <p><span class="label">Date Range:</span> {{campaign.from}} - {{campaign.to}}</p>-->
<!--            <p><span class="label">Start Time:</span> {{campaign.startTime}}</p>-->
<!--            <p><span class="label">End Time:</span> {{campaign.endTime}}</p>-->
<!--            <p><span class="label">Days of the Week:</span> {{campaign.daysOfTheWeek.join(', ')}}</p>-->
<!--            <p><span class="label">Login Frequency:</span> {{campaign.loginFrequency}}</p>-->
<!--            <p><span class="label">Last Login:</span> {{campaign.lastLogin}}</p>-->
<!--            <p><span class="label">Login Times:</span> {{campaign.loginTimes}}</p>-->

        </div>

    </div>
</template>

<script>

export default {
    name: "campaignList",
    props:['campaign'],
    data: function() {
        return {
            xhrInterval: null,
            chartHeight: '100%', // Set the initial chart height
            chartWidth: '100%',

        };
    },
}
</script>

<style scoped>

</style>
