<template>
    <!-- The Modal update --->
    <div class="modal fade" id="myEditModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Campaign</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="row">
                                    <div class="pb-4 col-4 col-sm-4"><label class="text-danger"><strong class="text-danger">*</strong> Means required in order for a campaign to be successfully generated.</label></div>
                                </div>
                                <form class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="row col-7">
                                            <div class="col-12">
                                                <div class="row col-12"><h5>Product Info</h5><br><hr></div>
                                                <div class="row col-12">
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label >Client <strong class="text-danger">*</strong></label>
                                                            <select v-model="item.client_id" @change="clientChange($event)" class="form-control text-capitalize required" required>
                                                                <option v-for="item in client" :key="item.value" :value="item.value">{{ item.text }}</option>
                                                            </select>
                                                            <div class="form-text text-muted">The affiliated client to which the campaign belongs</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Product <strong class="text-danger">*</strong></label>
                                                            <select v-model="item.product_type" @change="loadPlans($event)" class="form-control text-capitalize required" required>
                                                                <option v-for="item in products" :key="item.key" :value="item.key">{{ item.value }}</option>
                                                            </select>
                                                            <div class="form-text text-muted">The affiliated product type for the campaign being created</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row col-12"><h5>Campaign Info</h5><br><hr></div>
                                                <div class="row col-12">
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Name <strong class="text-danger">*</strong></label>
                                                            <input type="text" v-model="item.name" class="form-control required" required/>
                                                            <div class="form-text text-muted">The name of the campaign</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <b-form-group>
                                                            <label>Upload Email Creative <strong class="text-danger">*</strong></label>
                                                            <b-input-group>
                                                                <b-form-input v-model="item.fileName" :disabled="busy"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button
                                                                        ref="button"
                                                                        :disabled="busy"
                                                                        variant="dark"
                                                                        v-b-modal.modalPopover
                                                                    >
                                                                        Upload
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div class="form-text text-muted">Allows upload of an email creative with links</div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-else-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                                        <b-form-group>
                                                            <label>Domain <strong class="text-danger">*</strong></label>
                                                            <input type="text" v-model="item.domain" class="form-control" />
                                                            <div class="form-text text-muted">Link that campaign will target</div>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                                        <b-form-group>
                                                            <label>Link Level</label>
                                                            <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                                            <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                                        <b-form-group>
                                                            <label>Language</label>
                                                            <select v-model="item.lang" class="form-control" >
                                                                <option :value="{code: 'en', codes: 'en-US,en'}">English</option>
                                                                <option :value="{code: 'es', codes: 'es-US,es'}">Spanish</option>
                                                            </select>
                                                            <div class="form-text text-muted">Determine the Language to use for this campaign.</div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="row col-12">
                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <b-form-group >
                                                            <label>Above the fold <strong class="text-danger">*</strong></label>
                                                            <b-row class="pr-3">
                                                                <b-col cols="10">
                                                                    <b-input-group class="">
                                                                        <b-form-input v-model="item.above" @change="ForcesUpdateComponent" type="range" step=".1" min="0" max="1"></b-form-input>
                                                                    </b-input-group>
                                                                </b-col>
                                                                <b-col cols="2" class="pt-1 border border-success text-center  ">
                                                                    <output :value="item.above" class="range-slider-tooltip text-danger">{{ item.above * 100 }}%</output>
                                                                </b-col>
                                                            </b-row>
                                                            <div class="form-text text-muted">Determines the percentage of clicks that should happen above the fold for the total number of clicks.</div>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <b-form-group>
                                                            <label>Unsubscribe Link</label>
                                                            <input type="text" v-model="item.unsubscribeLink" class="form-control" min="0" value="0"/>
                                                            <div class="form-text text-muted">Email creative unsubscribe link</div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="row col-12">

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <div class="form-group">
                                                            <label>Mode</label>
                                                            <select v-model="item.evMode" class="form-control" >
                                                                <option value="normal" selected>Normal</option>
                                                                <option value="append" >Append</option>
                                                            </select>
                                                            <div class="form-text text-muted">Select distribution flow rate</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' && item.evMode ==  'append'">
                                                        <b-form-group>
                                                            <label>Upload CSV File <strong class="text-danger"></strong></label>
                                                            <b-input-group>
                                                                <b-form-input v-model="item.csvAppend" :disabled="busyer"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button
                                                                        ref="button"
                                                                        :disabled="busyer"
                                                                        variant="dark"
                                                                        v-b-modal.modalEvPopover
                                                                    >
                                                                        Upload
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <div class="form-text text-muted">Allows upload of csv file</div>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <b-form-group>
                                                            <label>Link Level <strong class="text-danger">*</strong></label>
                                                            <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                                            <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' && item.evMode ==  'append'">
                                                        <b-form-group>
                                                            <b-button class="btn btn-dark btn-sm mr-1 font-weight-bold" @click="getCampaignData(item)" v-if="csvPanel == false">
                                                                <!--                                            <i class="fa fa-search" title="Search" v-if="!csvPanel"></i>-->
                                                                <span
                                                                    class="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    v-if="csvPanelLoader"
                                                                ></span>
                                                                Preview CSV</b-button>
                                                            <b-button class="btn btn-dark btn-sm mr-1 font-weight-bold" @click="getCampaignData(item)" v-else>Hide Preview</b-button>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="row col-12">
                                                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="item.product_type ==  'emailverifier' ">
                                                        <div class="form-group">
                                                            <label>Status</label>
                                                            <select v-model="item.status" class="form-control" >
                                                                <option value="active" selected>Active</option>
                                                                <option value="disabled">Disabled</option>
                                                                <option value="error">Error</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="complete">Complete</option>
                                                                <option value="unconfigured">Unconfigured</option>
                                                            </select>
                                                            <div class="form-text text-muted">Select campaign status</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row col-12" v-if="csvPanel == true">
                                                    <panelcsv :campaign="item" :stream="csvStream" :pid="csvPanel" />
                                                </div>
                                            </div>
                                            <div class="row col-12">
                                                <div class="row col-12"><h5>Schedule Info</h5><br><hr></div>
                                                <div class="row col-12">
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Start Date <strong class="text-danger">*</strong></label>
                                                            <input
                                                                type="date"
                                                                v-model="item.startDate"
                                                                class="form-control"
                                                            />
                                                            <div class="form-text text-muted">Start date for the campaign</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group"><label>Start Time <strong class="text-danger"></strong></label>
                                                            <b-input-group >
                                                                <b-form-input
                                                                    v-model="item.startTime" v-mask="'##:##'"
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    placeholder="00:00"
                                                                ></b-form-input>
                                                            </b-input-group>
                                                            <div class="form-text text-muted">Time of day when the campaign starts</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>End Date <strong class="text-danger">*</strong></label>
                                                            <input
                                                                type="date"
                                                                v-model="item.enddate"
                                                                class="form-control"
                                                            />
                                                            <div class="form-text text-muted">End date for the campaign</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Stop Time <strong class="text-danger">*</strong></label>
                                                            <b-input-group >
                                                                <b-form-input
                                                                    v-model="item.endTime"
                                                                    v-mask="'##:##'"
                                                                    type="text"
                                                                    autocomplete="off"
                                                                    placeholder="00:00"
                                                                ></b-form-input>
                                                            </b-input-group>
                                                            <div class="form-text text-muted">Time of day when the campaign end</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Time Zone <strong class="text-danger">*</strong></label>
                                                            <select v-model="item.timezone" class="form-control text-capitalize">
                                                                <option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</option>
                                                            </select>
                                                            <div class="form-text text-muted">Timezone to which campaign belongs. All time based interactions with the campaign by the crawler will be based on the timezone selected</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-5">
                                            <iframe style="width: 100%;" class="campaign-creative" :srcdoc="item.creative"></iframe>
                                        </div>
                                    </div>


                                    <!--                    ends here -->


                                    <div class="row col-12">
                                        <div class="row col-12"><h5>Click Distribution Info</h5><br><hr></div>
                                        <div class="row col-12">
                                            <div class="col-sm-3 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>Audience <strong class="text-danger">*</strong></label>
                                                    <input type="number" v-model="item.audience" class="form-control" min="0" value="0"/>
                                                    <div class="form-text text-muted">Campaign Total Audience</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label>Target Clicks <strong class="text-danger">*</strong></label>
                                                    <input type="number" v-model="item.target" class="form-control" min="0" value="0"/>
                                                    <div class="form-text text-muted">Maximum clicks on this campaign. (Set to 2% of Audience)</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Max Daily Clicks <strong class="text-danger">*</strong></label>
                                                    <input type="number" v-model="item.clicksdaily" class="form-control" min="0" max="999999999" value="0"/>
                                                    <div class="form-text text-muted">Maximum clicks to deliver in a day</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <b-form-group label="Crawl Threshold">
                                                    <input
                                                        type="number"
                                                        v-model="item.threshold"
                                                        class="form-control required"
                                                        min="1"
                                                        value="10"
                                                        required
                                                    />
                                                    <div class="form-text text-muted">Maximum number of crawl nodes that should run at once. Recommended 5.</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Country<strong class="text-danger">*</strong></label>
                                                    <select v-model="geos.country" @change="Getstates" class="form-control">
                                                        <option value="" selected>-- Any Country --</option>
                                                        <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                                    </select>
                                                    <div class="form-text text-muted">Country of campaign request.</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Max Proxy Distance</label>
                                                    <input type="number" v-model="item.max_distance" class="form-control required" min="1" value="10" required/>
                                                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">
                                                <div class="form-group">
                                                    <label>Flow Rate <strong class="text-danger">*</strong></label>
                                                    <select v-model="item.flowRate" class="form-control" @change="flowChange">
                                                        <option value="" selected>-- Select Flow --</option>
                                                        <option value="slow" >Slow</option>
                                                        <option value="fast" >Fast</option>
                                                        <option value="normal" >Normal</option>
                                                    </select>
                                                    <div class="form-text text-muted">Select distribution flow rate</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-12" v-if="item.product_type ==  'emailverifier' ">
                                                <diss v-if="item.flowRate" :itemDataset="item" />
                                            </div><!---->
                                        </div>
                                    </div>


                                    <div class="row col-12">
                                        <div class="row col-12 mt-3 mb-3">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-primary text-white mr-1"
                                                @click.prevent="toggleAdvance()" v-if="!advance"
                                            >
                                                Show More
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-primary text-white mr-1"
                                                @click.prevent="toggleAdvance()" v-if="advance"
                                            >
                                                Show Less
                                            </button>
                                            <br><hr>
                                        </div>
                                        <div class="row col-12" v-if="advance==true"><h3>Advance Info</h3><br><hr></div>
                                        <div class="row col-12" v-if="advance==true">
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group label="Method ">
                                                    <select v-model="item.method" class="form-control text-capitalize">
                                                        <option value="any" selected>Any</option>
                                                        <option value="worker">Worker</option>
                                                        <option value="crawl">Crawl</option>
                                                    </select>
                                                    <div class="form-text text-muted"></div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' " >
                                                <b-form-group label="Side Load " v-if="item.product_type !==  'emailverifier' " >
                                                    <select v-model="item.sideLoad" class="form-control text-capitalize">
                                                        <option value="yes" selected>Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Yes if crawler should load assets over a separate proxy (or non-proxy) connection</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <div class="form-group">
                                                    <label>Local Links Only </label>
                                                    <select v-model="item.localLinks" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no" selected>No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Yes only allows links from the campaign domain</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                                <b-form-group label="Bounce " v-if="item.product_type !==  'emailverifier' " >
                                                    <select v-model="item.bounce" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no" selected>No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Allows the campaign to bounce a modulus of the number of clicks based on Target Clicks</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                                <b-form-group  v-if="item.product_type !==  'emailverifier' ">
                                                    <label>Behavior </label>
                                                    <select v-model="item.behavior" class="form-control text-capitalize">
                                                        <option value="" selected>Select</option>
                                                        <option v-for="wait in behavior" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                                    </select>
                                                    <div class="form-text text-muted">The list of wait times that the system can shuffle through</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                                <b-form-group label="Navigation Timeout">
                                                    <input type="number" v-model="item.navigationTimeout" class="form-control" min="30000" placeholder="30000"/>
                                                    <div class="form-text text-muted">Maximum amount of time to wait for on-page navigation (in milliseconds)</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group label="Wait Until ">
                                                    <select v-model="item.waitUntil" class="form-control text-capitalize">
                                                        <option v-for="wait in waituntil" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                                    </select>
                                                    <div class="form-text text-muted">When to consider navigation succeeded.</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <div class="form-group">
                                                    <label>Mobile </label>
                                                    <select v-model="item.mobile" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Should the page be visited by a mobile device</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <div class="form-group">
                                                    <label>Flow </label>
                                                    <select v-model="item.flow" class="form-control text-capitalize">
                                                        <option v-for="wait in flow" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                                    </select>
                                                    <div class="form-text text-muted">Api is for SI campaigns Queue is everything else</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                                <b-form-group label="Snoop">
                                                    <select v-model="item.snoop" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no" selected>No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Check if IP Address has changed before starting the visit</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <div class="form-group">
                                                    <label>Provider</label>
                                                    <select v-model="item.provider"  class="form-control text-capitalize required" >
                                                        <option v-for="item in provider" :key="item.value" :value="item.value">{{ item.text }}</option>
                                                    </select>
                                                    <div class="form-text text-muted">The proxy provider that the campaigns proxy should be taken from</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                                <b-form-group label="Set Geo">
                                                    <select v-model="item.setGeo" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no" selected>No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Set the GeoLocation in the browser to the proxy location</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group label="Video">
                                                    <select v-model="item.video" class="form-control text-capitalize">
                                                        <option value="yes">Yes</option>
                                                        <option value="no" selected>No</option>
                                                    </select>
                                                    <div class="form-text text-muted">Record campaign visits</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group>
                                                    <label>Video Fps</label>
                                                    <input type="number" v-model="item.videoFps" class="form-control" min="5" max="30" value="0"/>
                                                    <div class="form-text text-muted">Maximum frames per second while recoring video. range(5-30)</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group>
                                                    <label>Video Quality</label>
                                                    <input type="number" v-model="item.videoQuality" class="form-control" min="1" max="100" value="0"/>
                                                    <div class="form-text text-muted">Video playback quality. range(1-100)</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group label="Referers ">
                                                    <b-form-tags input-id="tags-basic" v-model="item.referersArray" placeholder="Enter Referers here"></b-form-tags>
                                                    <div class="form-text text-muted">List of referrer links to use</div>
                                                </b-form-group>
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-3">
                                                <b-form-group label="Blacklist">
                                                    <b-form-tags input-id="tags-basic" v-model="item.blacklistArray" placeholder="Enter Blacklist here"></b-form-tags>
                                                    <div class="form-text text-muted">List of links to avoid</div>
                                                </b-form-group>
                                            </div>
                                        </div>

                                    </div>

                                </form>

                                <!--GOES .....right here -->

                                <div
                                    class="row d-flex justify-content-center align-content-center pt-3"
                                    v-if="campaignId != ''"
                                >
                                    <!--            Right Section-->
                                    <div class="col-12">
                                        <b-card no-body>
                                            <div class="card">
                                                <div class="row col">
                                                    <b-form-group class="col mb-0">
                                                        <div class="form-text text-muted mt-3">Modification done in this area will automatically saved to the campaign and does not require user to click the save button.</div>
                                                    </b-form-group>
                                                </div>
                                                <div>
                                                    <div id="Residentialcity">
                                                        <div class="row mt-4 mb-3">
                                                            <div class="col-3 pl-4 pr-4">
                                                                <label>Geo Type <strong class="text-danger">*</strong></label>
                                                                <select
                                                                    v-model="item.geo_type"
                                                                    class="form-control"
                                                                >
                                                                    <option value="" selected>-- type --</option>
                                                                    <option value="dma">
                                                                        DMA
                                                                    </option>
                                                                    <option value="geos">
                                                                        GEO
                                                                    </option>
                                                                </select>
                                                                <div class="form-text text-muted">Country of campaign request.</div>
                                                            </div>
                                                        </div>

                                                        <div v-if="item.geo_type != 'dma'" class="row mt-4">
                                                            <div class="col-3 pl-4 pr-4">
                                                                <label>Country <strong class="text-danger">*</strong></label>
                                                                <select
                                                                    v-model="geoL.country"
                                                                    @change="Getstates()"
                                                                    class="form-control"
                                                                >
                                                                    <option value="" selected>-- Any country --</option>
                                                                    <option
                                                                        v-for="country in countries"
                                                                        :key="country._id"
                                                                        :value="country.code"
                                                                    >
                                                                        {{ country.name }}
                                                                    </option>
                                                                </select>
                                                                <div class="form-text text-muted">Country of campaign request.</div>
                                                            </div>
                                                            <div class="col-2 pl-4 pr-4">
                                                                <label>State</label>
                                                                <select
                                                                    v-model="geoL.state"
                                                                    @change="Getcities()"
                                                                    class="form-control"
                                                                >
                                                                    <option value="" selected>-- Any state --</option>
                                                                    <option
                                                                        v-for="state in states"
                                                                        :key="state"
                                                                        :value="state"
                                                                    >
                                                                        {{ state }}
                                                                    </option>
                                                                </select>
                                                                <div class="form-text text-muted">State/Provence of campaign request.</div>
                                                            </div>
                                                            <div class="col-2 pl-4 pr-4" v-if="geoL.state">
                                                                <label>City</label>
                                                                <select v-model="geoL.city" class="form-control">
                                                                    <option value="" selected>-- Any City --</option>
                                                                    <option
                                                                        v-for="city in cities"
                                                                        :v-if="city.city_name !== null"
                                                                        :key="city.city_name"
                                                                        :value="city.city_name"
                                                                    >
                                                                        {{ city.city_name }}
                                                                    </option>
                                                                </select>
                                                                <div class="form-text text-muted">City/Town of campaign request.</div>
                                                            </div>
                                                            <div class="col-3 pl-4 pr-4">
                                                                <label>Max Radius <strong class="text-danger">*</strong></label>
                                                                <input
                                                                    type="number"
                                                                    v-model="geoL.maxRadius"
                                                                    class="form-control required"
                                                                    min="1"
                                                                    value="10"
                                                                    required
                                                                />
                                                                <div class="form-text text-muted">Location range of campaign request.</div>
                                                            </div>
                                                            <div class="col-2 pl-4 pr-4">
                                                                <label>Strict <strong class="text-danger">*</strong></label>
                                                                <select v-model="geoL.strict" class="form-control">
                                                                    <option
                                                                        v-for="stricts in strict"
                                                                        :key="stricts.strict"
                                                                        :value="stricts.value"
                                                                    >
                                                                        {{ stricts.text }}
                                                                    </option>
                                                                </select>
                                                                <div class="form-text text-muted">{{ itemInfo }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div v-if="item.geo_type == 'dma'" class="col-3 pl-4 pr-4">
                                                                <label>DMA <strong class="text-danger">*</strong></label>
                                                                <input
                                                                    type="text"
                                                                    v-model="geoL.dma"
                                                                    class="form-control"
                                                                    required
                                                                />
                                                                <div class="form-text text-muted">DMA code</div>
                                                            </div>
                                                            <div class="col-4 pl-4 pr-4 pt-4 mt-1">
                                                                <b-button
                                                                    size="md"
                                                                    variant="success"
                                                                    @click="addGeos(geoL)"
                                                                    v-text="'Add Geo'"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div  class="p-3">
                                                            <ejs-grid ref="egrid" :dataSource="{result: geo.data, count: geo.count}" :allowPaging="true" :allowFiltering="true"
                                                                      :allowSorting="true" :pageSettings="pageSettings"
                                                                      :actionBegin="actionBegin" :actionComplete="actionComplete"
                                                            >
                                                                <e-columns v-if="item.geo_type != 'dma'">
                                                                    <e-column field='country' headerText="Country" width='60'></e-column>
                                                                    <e-column field="state" headerText="State" textAlign="Left"  width='60'></e-column>
                                                                    <e-column field="city" headerText="City" textAlign="Left"  width="160"></e-column>
                                                                    <e-column field="maxRadius" headerText="Max Radius" ttextAlign="Left"  width="160"></e-column>
                                                                    <e-column field="strict" headerText="Strict" textAlign="Left"  width="160"></e-column>
                                                                    <e-column :template="actionTemplate" width="180" textAlign="Right"></e-column>
                                                                </e-columns>
                                                                <e-columns v-else>
                                                                    <e-column field='dma' headerText="DMA"></e-column>
                                                                    <e-column :template="actionTemplate" textAlign="Right"></e-column>
                                                                </e-columns>
                                                            </ejs-grid>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card>
                                    </div>
                                    <!--            End Right Section-->
                                </div>

                                <!--ends here-->

                                <!-- tos right here -->
                                <b-row v-if="campaignId != ''">
                                    <div class="col-12 pr-5 pl-4">
                                        <div class="row mt-3 mb-2 d-flex justify-content-center">
                                            <b-button
                                                class="mr-2"
                                                variant="info"
                                                size="sm"
                                                @click="tos = true"
                                            >Configure TOS</b-button
                                            >
                                        </div>
                                    </div>
                                    <t-o-s
                                        v-if="tos"
                                        :linklvlTOS="linkLevelTos"
                                        :campaign_id="campaignId"
                                        v-on:close="tos = false"
                                        v-on:modified="tos = false; tosNotify();"
                                    ></t-o-s>
                                </b-row>
                                <!--  -->

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button type="submit" class="btn btn-primary btn-lg"
                                        @click.prevent="save(true)">
                                    <span class="glyphicon glyphicon-ok"></span> Update Campaign
                                </button>
                                <button v-if="item.status != 'active'" type="submit" class="btn btn-success btn-lg ml-3"
                                        @click.prevent="saveActivate(true)">
                                    <span class="glyphicon glyphicon-ok"></span> Update and Activate Campaign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import diss from "@/components/campaigns/distribution";
import axios from "axios";
import swal from "sweetalert";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import {Edit, Page, Sort} from "@syncfusion/ej2-vue-grids";
import Vue from "vue";

let Bus = new Vue();

export default {
    name: "EditModal",
    props: [
        "item",
        "fields",
        "itemInfo",
        "busy",
        "geoL",
        "busyer",
        "csvStream",
        "csvPanel",
        "csvPanelLoader",
        "tos",
        "geos",
        "campaignId",
        "linkLevelTos",
        "advance",
        "client",
        "clients",
        "products",
        "timezones",
        "countries",
        "behavior",
        "waituntil",
        "provider",
        "states",
        "strict",
        "cities",
        "clientChange",
        "loadPlans",
        "ForcesUpdateComponent",
        "Getstates",
        "flowChange",
        "Getcities",
        "maxChange",
        "strictChange",
        "countDownChanged",
        "getCampaignData",
        "toggleAdvance",
        "cancel",
        "tosNotify",
        "save",
        "saveActivate"
    ],
    provide: {
        grid: [Page, Sort, Edit]
    },
    components: { diss },
    mounted() {
        Bus.$on("delete", (index, geo) => {
            this.removeGeos(index, {country: geo.country, state: geo.state, city: geo.city, dma: geo.dma})
        })
    },
    data() {
        return {
            loading: true,
            data: {},
            page: 1,
            geo: {},
            dma_fields: [
                {
                    key: "dma",
                    label: "Dma",
                    sortable: false,
                }
            ],
            pageSettings: {
                pageSize: 10,
                pageCount: 10,
                pageSizes: [10, 20, 30, 40, 100, 200]
            },
            editSettings: { allowEditing: true, mode: 'Batch' },
            actionTemplate: function () {
                return {
                    template: Vue.component('actionTemplate', {
                            template: `
                                  <b-button
                                            @click="removeGeos()"
                                            title="Delete Geo"
                                            variant="danger"
                                            size="sm"
                                        ><span class="fa fa-trash"></span
                                        ></b-button>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                removeGeos() {
                                    Bus.$emit("delete", Number(this.data.index), this.data);
                                },
                            }
                        }
                    )
                }
            },
        }
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.load();
                    this.loadGeos()
                    this.flowChange()
                    if(this.$refs.egrid) this.$refs.egrid.ej2Instances.pageSettings.currentPage = 1;
                }
            },
            deep: true
        }
    },
    methods: {
        loadGeos() {
            let request = {$skip: (this.page - 1) * this.pageSettings.pageSize, $top: this.pageSettings.pageSize}
            axios.get(`${this.$root.serverUrl}/admin/campaigns/geos/${this.item._id}`, {params:request}).then((resp) => {
                this.geo = resp.data || [];
                this.$forceUpdate()
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
            });
        },
        load() {
            this.loading = true;
            axios({
                url: `${this.$root.serverUrl}/admin/campaigns/${this.item._id}`,
                responseType: "json",
                method: "GET",
            }).then((resp) => {
                this.item.creative = resp.data.data.creative || null;
                this.loading = false;
                this.$forceUpdate()
            }).catch((err) => {
                this.loading = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
            });
        },
        editGeos(geos) {
            // console.log(geos)
            geos.id = geos._id;
            geos.campaign_id = this.item._id;
            axios
                .post(`${this.$root.serverUrl}/admin/campaigns/geo`, geos)
                .then((resp) => {
                    //Process the results
                    if (resp && resp.data && !resp.data.error) {
                        //Saved successfully, now update the parent
                        geos._id = resp.data.data.id;
                        swal({
                            title: "Success",
                            text: "Geo Location Edited Successfully.",
                            icon: "success",
                        });
                    }
                    if (resp && resp.data && resp.data.error) {
                        //Handle errors
                        this.errormsg = resp.data.error ? resp.data.error.message : false;
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }
                })
                .catch(
                    function (err) {
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                )
                .finally(() => {
                    this.$forceUpdate();
                });
        },
        removeGeos(index, geo) {
            swal({ title: "Processing", text: "Deleting......", icon: "warning" });
            axios
                .post(`${this.$root.serverUrl}/admin/campaigns/geo/gdma/${this.item._id}`, geo)
                .then((resp) => {
                    if (!resp.error) {
                        this.geo.data.splice(index, 1);
                        this.geo.count = this.geo.count - 1;
                        swal({
                            title: "Success",
                            text: "Deleted successfully.",
                            icon: "success",
                        });
                    } else {
                        this.$bvToast.toast("An Error Occurred.", {
                            title: <strong>ERROR</strong>,
                            variant: "warning",
                            solid: true,
                        });
                    }
                });
        },
        addGeos(geos) {
            geos.campaign_id = this.item._id;
            axios
                .put(`${this.$root.serverUrl}/admin/campaigns/geo`, geos)
                .then(
                    function (resp) {
                        //Process the results
                        if (resp && resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            geos._id = resp.data.data.id;
                            this.geo.data.push(Object.assign({}, geos));
                            this.geo.count = this.geo.count + 1;
                            // this.item = this.edit(this.item);
                            swal({
                                title: "Notice",
                                text: "Geo Location Added Successfully.",
                                icon: "success",
                            });
                        }
                        if (resp && resp.data && resp.data.error) {
                            //Handle errors
                            this.errormsg = resp.data.error ? resp.data.error.message : false;
                            swal({ title: "Error", text: this.errormsg, icon: "error" });
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                )
                .finally(() => {
                    this.$forceUpdate();
                });
        },
        toolbarClick(args = ClickEventArgs) {
            switch (args.item.text) {
                /* tslint:disable */
                case 'Update':
                    this.changes = this.$refs.grid.ej2Instances.getBatchChanges();
                    break;
            }
        },
        cellEdit: function(args) {
            console.log(args);
            if (args.columnName !== "maxRadius") {
                args.cancel = true;
            }
        },
        actionComplete: function(args) {
            if(args.requestType === "batchsave") {
                var changes = []
                for (let i = 0; i < this.changes.changedRecords.length; i++) {
                    changes.push({_id: this.changes.changedRecords[i]._id, status: this.changes.changedRecords[i].status})
                }

                axios.post(`${this.$root.serverUrl}/admin/campaigns/update/status`, {updates: changes}).then(() => {
                    this.changes = [];
                    this.$refs.grid.ej2Instances.batchSave();
                }).catch((err) => {
                    console.log(err)
                });
            }
        },
        actionBegin: function(args) {
            console.log(args);
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                this.loadGeos()
            }
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.campaign-container {
    gap: 40px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.modal-body {
    overflow: scroll;
}
.info-sec i {
    font-size: 100px;
}
.chart {
    width: 100%;
    height: 300px;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
