<template>
    <!-- The Modal -->
    <div class="modal fade" id="myChangeModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        Campaign Change Logs
                    </h2>

                    <div v-if="id" class="row">
                        <div class="col-md-12">
                            <div v-for="(data, index) in result.changes" :key="index">
                                <div v-if="data.field !== 'creative' && data.field !== 'geos'" class="d-flex mb-3" style="gap: 50px;">
                                    <div><strong>Field</strong> <div>{{ data.field }}</div></div>
                                    <div><strong>Original</strong> <div>{{ data.original }}</div></div>
                                    <div><strong>Modified</strong> <div>{{ data.modified }}</div></div>
                                </div>
                                <div v-if="data.field === 'creative'" class="row mb-3">
                                    <div class="col-12 mb-2"><strong>Field</strong> {{ data.field }}</div>
                                    <div class="col-md-6 col-sm-12">
                                        <div><strong>Original</strong></div>
                                        <iframe style="width: 100%;" class="campaign-creative" :srcdoc="data.original"></iframe>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div><strong>Modified</strong></div>
                                        <iframe style="width: 100%;" class="campaign-creative" :srcdoc="data.modified"></iframe>
                                    </div>
                                </div>
                                <div v-if="data.field === 'geos'" class="row mb-3">
                                    <div class="col-md-12 mb-3"><strong>Field</strong> {{ data.field }}</div>
                                    <div class="col-md-6 col-sm-12">
                                        <div><strong>Original</strong></div>
                                        <div style="width: 100%;" class="campaign-geo">
                                            <div>
                                                <table class="table table-striped table-hover mt-3">
                                                    <thead>
                                                    <tr v-if="data.original && data.original.length && data.original[0].dma">
                                                        <th scope="col">DMA</th>
                                                    </tr>
                                                    <tr v-else>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Max Radius</th>
                                                        <th scope="col">Strict</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template v-if="data.original && data.original.length && data.original[0].dma">
                                                        <tr v-for="(geo, index) in data.original" :key="index">
                                                            <td>{{ geo.dma }}</td>
                                                        </tr>
                                                    </template>
                                                    <template v-else>
                                                        <tr v-for="(geo, index) in data.original" :key="index">
                                                            <td>{{ geo.country }}</td>
                                                            <td>{{ geo.state }}</td>
                                                            <td>{{ geo.city }}</td>
                                                            <td>{{ geo.maxRadius }}</td>
                                                            <td>{{ geo.strict }}</td>
                                                        </tr>
                                                    </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div><strong>Modified</strong></div>
                                        <div style="width: 100%;" class="campaign-geo">
                                            <div>
                                                <table class="table table-striped table-hover mt-3">
                                                    <thead>
                                                    <tr v-if="data.modified && data.modified.length && data.modified[0].dma">
                                                        <th scope="col">DMA</th>
                                                    </tr>
                                                    <tr v-else>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Max Radius</th>
                                                        <th scope="col">Strict</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template v-if="data.modified && data.modified.length && data.modified[0].dma">
                                                        <tr v-for="(geo, index) in data.modified" :key="index">
                                                            <td>{{ geo.dma }}</td>
                                                        </tr>
                                                    </template>
                                                    <template v-else>
                                                        <tr v-for="(geo, index) in data.modified" :key="index">
                                                            <td>{{ geo.country }}</td>
                                                            <td>{{ geo.state }}</td>
                                                            <td>{{ geo.city }}</td>
                                                            <td>{{ geo.maxRadius }}</td>
                                                            <td>{{ geo.strict }}</td>
                                                        </tr>
                                                    </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-striped table-hover mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Changes</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(data, index) in data" :key="index">
                                        <td>{{ data.date | date_format_with_time }}</td>
                                        <td>{{ data.user }}</td>
                                        <td>{{ data.changes }} </td>
                                        <td><button
                                            type="button"
                                            class="dropdown-item"
                                            @click="view(data._id)"
                                            data-toggle='modal'
                                            data-target='#myChangeViewModal'
                                        ><span class="fa fa-eye" title="View"></span></button
                                        ></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-9">
                                <v-pagination
                                    v-model="page"
                                    :pageCount="Math.ceil(count / max)"
                                    :classes="bootstrapPaginationClasses"
                                    :labels="customLabels"
                                    :length="12"
                                    :total-visible="7"
                                    @input="load"
                                ></v-pagination>
                            </div>
                            <div class="col-12 col-sm-3 text-right">
                                <span>{{ data.length * Number(page | 1) }}</span> of
                                <span>{{ count }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="id" class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="id = null;">Back</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import swal from "sweetalert";
import vPagination from "vue-plain-pagination";


export default {
    name: "ChangeModal",
    components: {vPagination},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.data = []
                    this.count = 0;
                    this.filter()
                    this.page = 1;
                    this.id = null;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            page: 1,
            count: 0,
            data: [],
            preloader: false,
            max: 25,
            loading: true,
            id: null,
            bootstrapPaginationClasses: {
                ul: "pagination",
                li: "page-item",
                liActive: "active",
                liDisable: "disabled",
                button: "page-link",
            },
            customLabels: {
                first: "First",
                prev: "Previous",
                next: "Next",
                last: "Last",
            },
        }
    },
    methods: {
        view(id) {
            this.loadView(id);
        },
        load: function() {
            this.page = this.page < 1 ? 1 : this.page;
            if(!this.loading) this.filter()
        },
        loadView(id) {
            this.loading = true;
            let request = {};
            axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/change/logs/${id}`, {params: request}).then((resp) => {
                this.result = resp.data.data || {};
                this.loading = false;
                this.id = id;
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occurred";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
                this.loading = false;
            });
        },
        filter() {
            this.loading = true;
            let request = {max: this.max, page: this.page};
            axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/changes/${this.item._id}`, {params: request}).then((resp) => {
                this.data = resp.data.Items || [];
                this.count = resp.data.Count || 0;
                this.loading = false;
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow-y: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}
iframe.campaign-creative {
    min-height: 600px;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
