<template>
    <!-- The Modal -->
    <div class="modal fade" id="myErrorModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        Campaign Error Report
                    </h2>

                    <div class="row">
                        <div class="col-sm-4 col-md-3 col-lg-2">
                            <div class="form-group">
                                <h5>Date Range</h5>
                                <div class="wrap">
                                    <ejs-daterangepicker cssClass="e-outline" v-model="filters.date" ></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-3 col-lg-2">
                            <div class="form-group">
                                <h5>Service</h5>
                                <div class="wrap">
                                    <select v-model="filters.type" class="form-control text-capitalize">
                                        <option value="queue">Queue</option>
                                        <option value="crawler">Crawler</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="filters.type === 'crawler'" class="col-sm-4 col-md-3 col-lg-2">
                            <div class="form-group">
                                <h5>Error Type</h5>
                                <div class="wrap">
                                    <select v-model="filters.error_type" class="form-control text-capitalize">
                                        <option value="">All</option>
                                        <option value="fail">Fail</option>
                                        <option value="proxy">Proxy</option>
                                        <option value="startup">Startup</option>
                                        <option value="fail-link">Fail Link</option>
                                        <option value="follow-startup">Follow Startup</option>
                                        <option value="fail-captcha">Fail Captcha</option>
                                        <option value="fail-captcha-follow">Follow Captcha Follow</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 37px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <button type="button" class="btn btn-sm float-right" @click="exportToExcel"><i class="fa fa-file-pdf-o"></i> Export</button>
                            <table class="table table-striped table-hover mt-3">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th v-if="filters.type === 'crawler'" scope="col">Type</th>
                                    <th v-if="filters.type === 'crawler'" scope="col">Url</th>
                                    <th scope="col">Message</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(error, index) in data" :key="index">
                                    <td>{{ error.date | date_format_with_time }}</td>
                                    <td v-if="filters.type === 'crawler'">{{ error.type }}</td>
                                    <td v-if="filters.type === 'crawler'">{{ error.link }}</td>
                                    <td>{{ error.message }}</td>
                                    <td v-if="error.proxy" ><button class="btn btn-sm btn-primary" @click="$emit('errorDetail', error)"><i class="fa fa-eye"></i> </button> </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <v-pagination
                                v-model="page"
                                :pageCount="Math.ceil(count / max)"
                                :classes="bootstrapPaginationClasses"
                                :labels="customLabels"
                                :length="12"
                                :total-visible="7"
                                @input="load"
                            ></v-pagination>
                        </div>
                        <div class="col-12 col-sm-3 text-right">
                            <span>{{ data.length * Number(page | 1) }}</span> of
                            <span>{{ count }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import swal from "sweetalert";
import vPagination from "vue-plain-pagination";

import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);


export default {
    name: "ErrorModal",
    components: {vPagination},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.data = []
                    this.count = 0;
                    this.filter()
                    this.page = 1;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            page: 1,
            count: 0,
            errorDetails: {},
            data: [],
            preloader: false,
            max: 25,
            loading: true,
            filters: {
                date: [],
                type: "queue"
            },
            bootstrapPaginationClasses: {
                ul: "pagination",
                li: "page-item",
                liActive: "active",
                liDisable: "disabled",
                button: "page-link",
            },
            customLabels: {
                first: "First",
                prev: "Previous",
                next: "Next",
                last: "Last",
            },
        }
    },
    methods: {
        load: function() {
            this.page = this.page < 1 ? 1 : this.page;
            if(!this.loading) this.filter()
        },
        filter() {
            this.loading = true;
            let request = {max: this.max, page: this.page, filters: this.filters};

            let url = `${this.$root.serverUrl}/admin/reports/emailverifier/error/${this.item._id}`;
            if (this.filters.type === "crawler") {
                url = `${this.$root.serverUrl}/admin/reports/emailverifier/crawler/error/${this.item._id}`;
            }
            axios.get(url, {params: request}).then((resp) => {
                this.data = resp.data.Items || [];
                this.count = resp.data.Count || 0;
                this.loading = false;
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
                this.loading = false;
            });
        },
        exportToExcel: function(){
            let request = {filters: this.filters};
            // this.$root.preloader = true;

            let url = `${this.$root.serverUrl}/admin/reports/emailverifier/error/${this.item._id}/export`;
            if (this.filters.type === "crawler") {
                url = `${this.$root.serverUrl}/admin/reports/emailverifier/crawler/error/${this.item._id}/export`;
            }

            axios({
                url: url,
                params: request,
                method: 'GET',
                responseType: 'blob',
            }).then((resp) => {
                var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `campaign errors - ${new Date().toTimeString()}.csv`);
                document.body.appendChild(fileLink);

                fileLink.click();
                this.$root.preloader = false
            }).catch((err)=>{
                this.$root.preloader = false
                console.log('report.error', err)
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow-y: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@import '../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
</style>
