<template>
    <!-- The Modal -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">View Campaign</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="campaign-container">
                        <div class="campaign-details h-100">
                            <div class="container mx-content">
                                <div class="info-sec text-left">
                                    <div><i class="fa fa-3 fa-envelope" aria-hidden="true"></i></div>
                                    <div class="text-center">
                                        <h4>Status</h4>
                                        <div :class='`${item.status} status`' style="width: 150px;">{{item.status}}</div>
                                    </div>
                                    <div class="vrl"></div>
                                    <div>
                                        <div style="font-size: 40px;font-weight: bold;background: transparent !important" :class='`${item.status}`'>{{ item.delivered ? item.delivered.count: 0 }}</div>
                                        <h5>Successful Clicks</h5>
                                        <p v-if="item.statusMsg">Status Message: {{ item.statusMsg }}</p>
                                    </div>
                                </div>
                                <div class="row mt-5 text-left">
                                    <div class="col-12">
                                        <h4>Campaign Information</h4>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Name</label>
                                                    <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                                                        <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Name" v-model="item.name" readonly />
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Timezone</label>
                                                    <ejs-dropdownlist :enabled="false" cssClass="e-outline" id='sample-list3' :dataSource='timezones' :mode='true' v-model="item.timezone" placeholder='View'></ejs-dropdownlist>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Start Date</label>
                                                    <ejs-datetimepicker :enabled="false" cssClass="e-outline" id='startPicker' placeholder="Start Date" v-model="item.startDate" ></ejs-datetimepicker>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>End Date</label>
                                                    <ejs-datetimepicker :enabled="false" cssClass="e-outline" id='endPicker' placeholder="End Date" v-model="item.enddate" ></ejs-datetimepicker>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <label>Audience</label>
                                                    <input type="text" v-model="item.audience" class="form-control" readonly/>
                                                    <div class="form-text text-muted">Campaign Total Audience</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <label>Target Clicks</label>
                                                    <input type="text" v-model="item.target" class="form-control" readonly/>
                                                    <div class="form-text text-muted">Maximum clicks on this campaign. (Set to 2% of Audience)</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <label>Max Daily Clicks</label>
                                                    <input type="text" v-model="item.clicksdaily" class="form-control" readonly/>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Link Level</label>
                                                    <input type="text" v-model="item.linkLevel" class="form-control" readonly/>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Above the fold</label>
                                                    <div class="row">
                                                        <div class="col-md-10">
                                                            <input type="range" v-model="item.above" class="form-control" step=".1" min="0" max="1" disabled/>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <output :value="item.above" class="range-slider-tooltip text-danger">{{ item.above * 100 }}%</output>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12 col-md-12 mt-4 mb-4">
                                                <h4 class="mb-3">Geos</h4>
                                                <ejs-grid ref="dgrid" :dataSource="{result: geo.data, count: geo.count}" :allowPaging="true" :allowFiltering="true"
                                                          :allowSorting="true" :pageSettings="pageSettings"
                                                          :actionBegin="actionBegin"
                                                >
                                                    <e-columns v-if="item.geo_type != 'dma'">
                                                        <e-column field='country' headerText="Country" width='60'></e-column>
                                                        <e-column field="state" headerText="State" textAlign="Left"  width='60'></e-column>
                                                        <e-column field="city" headerText="City" textAlign="Left"  width="160"></e-column>
                                                        <e-column field="maxRadius" headerText="Max Radius" ttextAlign="Left"  width="160"></e-column>
                                                        <e-column field="strict" headerText="Strict" textAlign="Left"  width="160"></e-column>
                                                    </e-columns>
                                                    <e-columns v-else>
                                                        <e-column field='dma' headerText="DMA"></e-column>
                                                    </e-columns>
                                                </ejs-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <iframe class="campaign-creative" :srcdoc="item.creative"></iframe>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import moment from "moment-timezone";
import {DateTimePickerPlugin} from "@syncfusion/ej2-vue-calendars";
import {GridPlugin, Group, Page, Sort} from "@syncfusion/ej2-vue-grids";
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import axios from "axios";
import swal from "sweetalert";

Vue.use(DateTimePickerPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);

export default {
    name: "DetailModal",
    provide: {
        grid: [Page, Sort, Group]
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: true,
            data: {},
            page: 1,
            geo: {data: [], count: 0},
            pageSettings: { pageSize: 10, pageSizes: [10, 20, 30, 40, 100, 200] },
            timezones: moment.tz.names()
        }
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.loading = true;
                    this.load();
                    this.loadGeos()
                    if (this.$refs.dgrid) this.$refs.dgrid.ej2Instances.pageSettings.currentPage = 1;
                }
            },
            deep: true
        }
    },
    methods: {
        actionBegin: function(args) {
            console.log(args);
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                this.loadGeos()
            }
        },
        loadGeos() {
            let request = {$skip: (this.page - 1) * this.pageSettings.pageSize, $top: this.pageSettings.pageSize}
            axios.get(`${this.$root.serverUrl}/admin/campaigns/geos/${this.item._id}`, {params:request}).then((resp) => {
                this.geo = resp.data || [];
                this.$forceUpdate()
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
            });
        },
        load() {
            axios({
                url: `${this.$root.serverUrl}/admin/campaigns/${this.item._id}`,
                responseType: "json",
                method: "GET",
            }).then((resp) => {
                this.item.creative = resp.data.data.creative || null;
                this.loading = false;
                this.$forceUpdate()
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.campaign-container {
    gap: 40px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.modal-body {
    overflow: scroll;
}
.campaign-creative {
    flex: 1 0;
    border: 5px solid grey;
    background: grey;
    height: 100%;
    overflow: scroll;
}
/*.campaign-details {*/
/*  width: 50%;*/
/*}*/
.vrl {
    height: 200px;
    border-left: 1px solid grey;
}
.info-sec {
    display: flex;
    align-items: center;
    gap: 40px;
}
.info-sec i {
    font-size: 100px;
}
.mx-content {
    overflow: scroll;
    height: 100%;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
