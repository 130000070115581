import { render, staticRenderFns } from "./detailsModal.vue?vue&type=template&id=914f264c&scoped=true&"
import script from "./detailsModal.vue?vue&type=script&lang=js&"
export * from "./detailsModal.vue?vue&type=script&lang=js&"
import style0 from "./detailsModal.vue?vue&type=style&index=0&id=914f264c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "914f264c",
  null
  
)

export default component.exports