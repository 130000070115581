<template>
    <!-- The Modal -->
    <div class="modal fade" id="myClickModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        Clicks Distribution Report
                        <div style="float:right; margin-top: 30px; font-size: 13px;">{{ item.startDate }} - {{ item.endDate }}</div>
                    </h2>

                    <div class="row">
                        <div class="col-sm-4 col-md-3 col-lg-2">
                            <div class="form-group">
                                <h5>Date Range</h5>
                                <div class="wrap">
                                    <ejs-daterangepicker cssClass="e-outline" v-model="filters.date" ></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 37px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div v-if="ready" class="chart">
                                <apexchart width="100%" height="100%" type="area" :options="achartOptions" :series="series"></apexchart>
<!--                                <highcharts :options="chartOptions"></highcharts>-->
                            </div>
                        </div>
                        <div class="col-md-12">
                            <ejs-grid ref='clgrid' :dataSource="clickDisto" :allowResizing="true" :allowFiltering='true' :allowSorting='true' :columns="columns" :pageSettings='pageSettings' :allowPaging="true" :allowExcelExport="true" :allowPdfExport="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete' >
                                <e-aggregates>
                                    <e-aggregate>
                                        <e-columns>
                                            <e-column type="Sum" field="above" :footerTemplate="sumTemplate"></e-column>
                                            <e-column type="Sum" field="below" :footerTemplate="sumTemplate"></e-column>
                                            <e-column type="Sum" field="total" :footerTemplate="sumTemplate"></e-column>
                                            <e-column type="Sum" field="forecast" :footerTemplate="sumTemplate"></e-column>
                                            <e-column type="Sum" field="nationwide" :footerTemplate="sumTemplate"></e-column>
                                        </e-columns>
                                    </e-aggregate>
                                </e-aggregates>
                            </ejs-grid>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import swal from "sweetalert";
import axios from "axios";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import {Aggregate, ExcelExport, Page, Toolbar} from "@syncfusion/ej2-vue-grids";
// import {Chart} from "highcharts-vue";
import moment from "moment-timezone";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


export default {
    name: "ClickBreakDownModal",
    components: {
        // highcharts: Chart
    },
    provide: {
        grid: [ ExcelExport, Toolbar, Page, Aggregate ]
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {

                    let ed = moment(this.item.endDate).tz(this.item.timezone || 'EST');

                    if (ed.unix() <moment().unix()) this.filters.date = [ed.toDate(), ed.toDate()]
                    else this.filters.date = [moment().toDate(), moment().toDate()]
                    this.$forceUpdate();

                    this.data = []
                    this.count = 0;
                    this.filter()
                    this.page = 1;
                    this.id = null;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            page: 1,
            count: 0,
            clickDisto: null,
            preloader: false,
            max: 25,
            loading: true,
            id: null,
            ready: false,
            filters: {
              date: []
            },
            toolbar: ['ExcelExport'],
            pageSettings: { pageSize: 50 },
            sumTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            achartOptions: {
                chart: {
                    id: 'click-chart',
                    type: 'area'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
            },
            series: [
                {
                    name: 'Actual',
                    data: []
                },
                {
                    name: 'Forecast',
                    data: []
                }
            ],
            chartOptions: {
                title: {
                    text: 'Clicks Distribution'
                },
                yAxis: {
                    title: {
                        text: 'Number of Clicks'
                    }
                },
                xAxis: {
                    title: {
                        text: 'Date/Time'
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                series: [
                    {
                        name: 'Actual',
                        data: []
                    },
                    {
                        name: 'Forecast',
                        data: []
                    }
                ]
            },
            columns: [
                { field: 'ts', headerText: 'Date', allowGrouping: true, textAlign: 'left' },
                { field: 'hour', headerText: 'Hour', allowGrouping: true, textAlign: 'left' },
                { field: 'above', headerText: 'Above', allowGrouping: true, textAlign: 'left' },
                { field: 'below', headerText: 'Below', allowGrouping: true, textAlign: 'left' },
                { field: 'total', headerText: 'Actual', allowGrouping: false, textAlign: 'left' },
                { field: 'forecast', headerText: 'Forecast', allowGrouping: false, textAlign: 'left' },
                { field: 'nationwide', headerText: 'Nationwide', allowGrouping: false, textAlign: 'left' },
            ],
        }
    },
    methods: {
        load: function() {
            this.page = this.page < 1 ? 1 : this.page;
            if(!this.loading) this.filter()
        },
        exportComplete() {
            this.$refs.clgrid.hideSpinner();
        },
        toolbarClick(args = ClickEventArgs) {
            this.$refs.clgrid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.clgrid).excelExport(this.getExcelExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "clicks-breakdown.xlsx"
            };
        },
        async delivered() {
            return new Promise((resolve, reject) => {
                let request = {
                    filter: {
                        campaign_id: this.item._id,
                        date: {}
                    },
                };
                if (this.filters.date) {
                    request.filter.date.start = moment(this.item.startDate).subtract(1, 'day').startOf('day');
                    request.filter.date.end = moment(this.filters.date[0]).subtract(1, 'day').endOf('day');
                }

                axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/clicks-breakdown/delivered`, { params:request }).then((resp)=>{
                    resolve(resp.data.count)
                }).catch((err) => {
                    return reject(err)
                })
            })

        },
        async filter() {
            let request = {
               filter: {
                   campaign_id: this.item._id,
                   period: "hourly"
               },
            };

            this.ready = false;

            if (this.filters.date) request.filter.date = this.filters.date;

            this.loading = true;

            axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/clicks-breakdown`, { params:request }).then(async (resp)=>{
                // let delivered = await this.delivered();

                let pastDays = moment(request.filter.date[1]).diff(request.filter.date[0], 'days') + 1;

                let remaining = this.item.target;
                let date = moment(request.filter.date[0]).tz(this.item.timezone).startOf("day");

                let startdate = moment(this.item.startDate).startOf("day");
                let enddate = moment(this.item.endDate).endOf("day");


                let val = [];
                for (let i = 0; i < pastDays; i++) {
                    if (date >= startdate && date <= enddate) {
                        remaining = Number(this.item.target);
                        let dataset = this.calculateFlowRatez(this.item.flowRate, this.item.startDate, this.item.endDate, Number(this.item.target), remaining, this.item.timezone, true);
                        for (let d = 0; d < dataset.length; d++) {
                            let actual = {x: date.clone().add(i, 'days').add(d, 'hour').unix() * 1000, y: dataset[d]}
                            val.push(actual);
                        }
                    }
                }

                // if (resp.data.data.length < val.length) val.length = resp.data.data.length;

                this.series[1].data = val;

                for (let i = 0; i < resp.data.data.length; i++) {
                    resp.data.data[i].forecast = val[i] ? val[i].y : 0;
                    resp.data.data[i].ts = moment(resp.data.data[i].date).format("YYYY-MM-DD");
                    resp.data.data[i].x = moment(resp.data.data[i].date).startOf('hour').unix() * 1000;
                    resp.data.data[i].y = resp.data.data[i].delivered;
                }

                this.series[0].data = resp.data.data;

                this.clickDisto = ( resp.data && resp.data.data ) ? resp.data.data : [];
                this.loading = false;
                this.ready = true;

            }).catch(( err ) => {
                this.loading = false
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            })
        },
        calculateFlowRatez(speed, start, end, max, remaining, zone, full){
            //flowRate calculation for threshold
            let startDate = moment(start || Date.now()).startOf('day');
            let endDate = moment(end || Date.now()).endOf('day');
            let days = endDate.diff(startDate, 'days');
            if(days < 0)
                return false;

            let data = (max - remaining) == 0 ? max : (max - remaining);
            let daily = Math.ceil((data) / (days||1));

            let hourly = [];
            let used = 0;
            let rates = {
                'slow': {early: 32, peak: 0, late: 24, range:{min:8, max:14}},
                'normal': {early: 28, peak: 0, late: 28, range:{min:8, max:14}},
                'fast': {early: 16, peak: 0, late: 20, range:{min:8, max:14}},
            }

            let hour = (full)? 0 : moment().tz(zone || 'EST').hour();

            for(var h=0;h<24;h++) {
                //Check the hours and adjust according to the time of day and natural email
                let clicks = daily - used;
                hourly[h] = 0;

                if(clicks >= 0 && h >= hour) {
                    if (h < rates[speed].range.min) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].early + h));
                    } else if (h >= rates[speed].range.min && h <= rates[speed].range.max) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].peak + h));
                    } else {
                        hourly[h] = Math.ceil(clicks / (rates[speed].late + h));
                    }

                    used += hourly[h];
                }
            }

            //Returns the object with the hourly_threshold
            return hourly;
        },
        calculateFlowRate(campaign, speed, start, end, max, remaining, zone, full){
            console.log(campaign, speed, start, end, max, remaining, zone, full)
            //flowRate calculation for threshold
            let startDate = moment(start || Date.now()).startOf('day');
            let endDate = moment(end || Date.now()).endOf('day');
            let days = endDate.diff(startDate, 'days');
            if(days < 0)
                return false;

            let data = (max - remaining) == 0 ? max : (max - remaining);
            let daily = Math.ceil((data) / (days||1));
            let hourly = [];
            let used = 0;
            let rates = {
                'slow': {early: 32, peak: 0, late: 24, range:{min:8, max:14}},
                'normal': {early: 28, peak: -3, late: 28, range:{min:8, max:14}},
                'fast': {early: 16, peak: 0, late: 20, range:{min:8, max:14}},
            }
            if(campaign && campaign.flowRate && speed.length > 0){
                // console.log('item: ', campaign)
                rates = {
                    'slow': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                    'normal': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                    'fast': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                }
                // console.log('rates: ', rates[speed])

            }

            let hour = (full)? 0 : moment().tz(zone || 'EST').hour();

            for(var h=0;h<24;h++) {
                //Check the hours and adjust according to the time of day and natural email
                let clicks = daily - used;
                hourly[h] = 0;

                if(clicks >= 0 && h >= hour) {
                    if (h < rates[speed].range.min) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].early + h));
                    } else if (h >= rates[speed].range.min && h <= rates[speed].range.max) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].peak + h));
                    } else {
                        hourly[h] = Math.ceil(clicks / (rates[speed].late + h));
                    }

                    used += hourly[h];
                }
            }

            //Returns the object with the hourly_threshold
            return hourly;
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow-y: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}
iframe.campaign-creative {
    min-height: 600px;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.chart {
    width: 100%;
    height: 400px;
}
</style>
