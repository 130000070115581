<template>
    <div class="campaign widget-content widget-content-area component-card_1">
        <div class="mb-5 fit-width">
            <div class="mb-3">Status Legends</div>
            <div class="legend-cat">
                <div class="legend"><div class="complete-bg"></div> Complete</div>
                <div class="legend"><div class="active-bg"></div> Active</div>
                <div class="legend"><div class="disabled-bg"></div> Disabled</div>
                <div class="legend"><div class="pending-bg"></div> Pending</div>
                <div class="legend"><div class="error-bg"></div> Error</div>
                <div class="legend"><div class="unconfigured-bg"></div> Unconfigured</div>
            </div>
        </div>
        <div class="mb-5 fit-width">
            <div class="mb-3">Progress Legends</div>
            <div class="legend-cat">
                <div class="legend"><div style="background: #009933;"></div> 90% or higher on track</div>
                <div class="legend"><div style="background: #aaff80;"></div> 70% - 90%</div>
                <div class="legend"><div style="background: #ffff1a;"></div> 50% - 70%</div>
                <div class="legend"><div style="background: #ff944d;"></div> 30% - 50%</div>
                <div class="legend"><div style="background: #cc0000;"></div> 0% - 30%</div>
            </div>
        </div>
        <div class="d-flex mb-2 justify-content-between">
            <div class="mb-2" v-if="settings && settings.ev">
                <strong>Last Import:</strong> {{ settings.ev.last_scrape | date_format_with_time }}
                <div><strong>Next Import:</strong> {{ settings.ev.next_scrape | date_format_with_time }}</div>
            </div>
            <div v-if="selectedItems.length">
                <button type="button" class="btn btn-primary" @click="handleSelection">
                    <i class="fa fa-file-excel-o mr-2"></i>
                    <span>Export Unsubscribe List</span>
                </button>
<!--                <input style="display: none;" type="file" accept=".csv" ref="fileUploader" @change="handleFileUpload" class="form-control-file">-->
            </div>
        </div>

        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :allowFiltering="true" :allowSelection="true" :selectionSettings="selectionSettings"
                  :rowSelected="onSelectionChange" :rowDeselected="onSelectionChange" :editSettings='editSettings' :allowSorting="true" :pageSettings="pageSettings" :toolbar='toolbar'
                  :actionBegin="actionBegin" :actionComplete="actionComplete" :allowTextWrap='true' :textWrapSettings='wrapSettings'
                  :cellEdit='cellEdit' :toolbarClick="toolbarClick" :actionFailure='actionFailure'>
            <e-columns>
                <e-column type='checkbox' :allowFiltering='false' :allowSorting='false' width='60'></e-column>
                <e-column field="name" headerText="Name" text-Align="Left" width="260"></e-column>
                <e-column field="client" headerText="Client" text-Align="Left" width="160"></e-column>
                <e-column field="timezone" headerText="Timezone" text-Align="Left" width="160"></e-column>
                <e-column field="mobile" headerText="Mobile" text-Align="Left" width="160"></e-column>
                <e-column :template="nationwideTemplate" headerText="Nationwide" text-Align="Left" width="160"></e-column>
                <e-column field="user_start_date_display" headerText="Start Date" text-Align="Left" width="240"></e-column>
                <e-column field="user_end_date_display" headerText="End Date" text-Align="Left" width="240"></e-column>
                <e-column :template="deliveredTemplate" headerText="Delivered Clicks" text-Align="Left" width="240"></e-column>
                <e-column :template="progressTemplate" headerText="Total Clicks Delivered" text-Align="Left" width="180"></e-column>
                <e-column :template="targetTemplate" headerText="Daily Target" text-Align="Center" :isPrimaryKey='true' width="180"></e-column>
                <e-column :template="statusTemplate" headerText="Status" text-Align="Center" editType='dropdownedit' :edit="statusParams" width="160"></e-column>
                <e-column :template="daysTemplate" headerText="Number of Days" text-Align="Left" width="140"></e-column>
                <e-column :template="actionTemplate" width="180"></e-column>
            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="delivered.count" :footerTemplate="sumTemplate"></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>

        <DetailsModal :item="detail"  />
        <ErrorModal :item="error" @errorDetail="setError" />
        <ReportModal :item="report"  />
        <ChangeLogModal :item="change"  />
        <ClickBreakDownModal :item="clicks"  />
        <ProxyLogs :provider="provider" :user="user" :item="proxy" @errorDetail="setError" />
        <ErrorDetailModal :user="user" :item="errorDetails" />
    </div>
</template>

<script>

import Vue from "vue";
import DetailsModal from './detailsModal'
import ErrorModal from './errorModal'
import ReportModal from './reportModal'
import ChangeLogModal from './changeModal'
import ClickBreakDownModal from './clickReportModal'
import ErrorDetailModal from './errorDetails'

let Bus = new Vue();

import {Toolbar, Edit, GridPlugin, Page, Sort, Group, Aggregate} from '@syncfusion/ej2-vue-grids';
import { DropDownList, DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import moment from "moment-timezone";
import swal from "sweetalert";
import axios from "axios";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import {SERVER_URL} from "@/constants";
import ProxyLogs from "@/components/emailVerifier/proxyLogs";
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
let stateElem, stateObj;

export default {
    name: "EmailVerifier",
    auth: true,
    props: [
        "data",
        "item",
        "page",
        "user",
        "provider"
    ],
    provide: {
        grid: [Page, Sort, Group, Edit, Toolbar, Aggregate]
    },
    components: {ProxyLogs, DetailsModal, ErrorModal, ReportModal, ChangeLogModal, ClickBreakDownModal, ErrorDetailModal},
    data() {
        return {
            error: {},
            selectedItems: [],
            report: {},
            detail: {},
            change: {},
            clicks: {},
            proxy: {},
            query: null,
            info: {},
            settings: null,
            errorDetails: {},
            changes: [],
            search: "",
            wrapSettings: { wrapMode: 'Content' },
            selectionSettings: { type: 'Multiple' },
            toolbar: [
                'Update',
                'Cancel'
            ],
            filters: { search: '' },
            pageSettings: {
                pageSize: 25,
                pageCount: 10,
                pageSizes: [25, 50, 100, 250, 500, 1000]
            },
            sumTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            statusParams: {
                create: () => {
                    stateElem = document.createElement('input');
                    return stateElem;
                },
                read: () => {
                    return stateObj.text;
                },
                destroy: () => {
                    stateObj.destroy();
                },
                write: () => {
                    stateObj = new DropDownList({
                        dataSource: [
                            {text: "active", value: "active"},
                            {text: "disabled", value: "disabled"}
                        ],
                        fields: { value: 'value', text: 'text' },
                        enabled: true,
                        placeholder: 'Select a status',
                        floatLabelType: 'Never',
                        change: () => {
                            stateObj.enabled = true;

                            stateObj.dataBind();
                        },
                    });
                    stateObj.appendTo(stateElem);
                }
            },
            editSettings: { allowEditing: true, mode: 'Batch' },
            targetTemplate: "<div class='${status} target'>${clicksdaily}</div>",
            nationwideTemplate: "<div>${nationwide == 'yes' ? 'yes': 'no'}</div>",
            statusTemplate: function () {
                return {
                    template: Vue.component('statusTemplate', {
                            template: `<div :class="data.status + ' status'"> {{ data.status }}<span v-if="data.status === 'complete'" :class="data.verified === true ? 'verified' : data.verified === false ? 'unverified' : ''">{{data.verified === true ? '/verified' : data.verified === false ? '/unverified' : ''}}</span></div>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            }
                        }
                    )
                }
            },
            // statusTemplate: "<div class='${status} status'>${status}${verified === true ? '/verified' : verified === false ? '/unverified' : ''}</div>",
            dateTemplate: "<div>${new Date(startdate).toLocaleDateString()}-${new Date(enddate).toLocaleDateString()}</div>",
            actionTemplate: function () {
                return {
                    template: Vue.component('actionTemplate', {
                            template: `
                              <div class="btn-group">
                                    <button type='button' class='action-btn'  data-toggle="dropdown">...</button>
                                    <div class="dropdown-menu" role="menu">
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="details"
                                            data-toggle='modal'
                                            data-target='#myModal'
                                        ><span class="fa fa-eye" title="View"></span>
                                            Details</button
                                        >
                                        <button
                                            data-toggle='modal'
                                            data-target='#myEditModal' class="dropdown-item" @click="edit"
                                        ><span class="fa fa-pencil" title="edit"></span>
                                            Edit</button
                                        >
                                        <router-link
                                            class="dropdown-item"
                                            :to="'/campaign/' + data._id"
                                        ><span class="fa fa-copy" title="Copy"></span>
                                            Copy</router-link
                                        >
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="error"
                                            data-toggle='modal'
                                            data-target='#myErrorModal'
                                        ><span class="fa fa-clipboard" title="View"></span>
                                            Error Report</button
                                        >
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="proxy"
                                            data-toggle='modal'
                                            data-target='#myProxyLogModal'
                                        ><span class="fa fa-clipboard" title="View"></span>
                                            Proxy Logs</button
                                        >
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="change"
                                            data-toggle='modal'
                                            data-target='#myChangeModal'
                                        ><span class="fa fa-clipboard" title="View"></span>
                                            Change Logs</button
                                        >
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="links"
                                            data-toggle='modal'
                                            data-target='#myLinkModal'
                                        ><span class="fa fa-link" title="View"></span>
                                            Link Breakdown</button
                                        >
                                        <button
                                           type="button"
                                            class="dropdown-item"
                                            @click="clicks"
                                            data-toggle='modal'
                                            data-target='#myClickModal'
                                        ><span class="fa fa-line-chart" title="View"></span>
                                            Click Breakdown</button
                                        >
                                        <button v-if="data.unsubscribeLink && data.unsubscribe_list === 'yes'"
                                           type="button"
                                            class="dropdown-item"
                                            @click="report"
                                            data-toggle='modal'
                                            data-target='#myReportModal'
                                        ><span class="fa fa-clipboard" title="View"></span>
                                            Unsubscribe Report</button
                                        >
                                        <div class="dropdown-divider"></div>
                                        <div>
                                            <a
                                                v-if="data.status == 'active'"
                                                class="dropdown-item"
                                                @click="setStatus"
                                            >
                                                <span class="fa fa-archive" title="View"></span>
                                                Disable</a
                                            >
                                            <a
                                                v-else
                                                class="dropdown-item"
                                                @click="setStatus"
                                            >
                                                <span class="fa fa-check" title="View"></span>
                                                Activate</a
                                            >
                                        </div>
<!--                                        <a class="dropdown-item" @click="remove"-->
<!--                                        ><span class="fa fa-trash" title="Delete"></span>-->
<!--                                            Delete</a-->
<!--                                        >-->
                                    </div>
                                </div>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                clicks() {
                                    Bus.$emit("clicks", this.data)
                                },
                                change() {
                                    Bus.$emit("change", this.data)
                                },
                                report() {
                                    Bus.$emit("report", this.data)
                                },
                                error() {
                                    Bus.$emit("error", this.data)
                                },
                                proxy() {
                                    Bus.$emit('proxy', this.data)
                                },
                                details() {
                                    Bus.$emit("details", this.data)
                                },
                                edit() {
                                    Bus.$emit("edit", this.data)
                                },
                                links() {
                                    Bus.$emit("link", this.data)
                                },
                                setStatus() {
                                    let option = "activate"; // which route
                                    let value = "Activate"; //disabled or active

                                    if (this.data.status == "active") {
                                        option = "disable";
                                        value = "Disable";
                                    }
                                    swal({
                                        title: value,
                                        text: "Are you sure?",
                                        icon: "info",
                                        buttons: ["No", "Yes"],
                                    }).then(
                                        function (val) {
                                            if (!val) return;

                                            var request = { apikey: this.$root.apikey, id: this.data._id };
                                            // this.$root.preloader = true;

                                            axios({
                                                url: `${this.$root.serverUrl}/admin/campaigns/${option}/${this.data._id}`,
                                                data: request,
                                                responseType: "json",
                                                method: "POST",
                                            })
                                                .then(
                                                    function (resp) {
                                                        //Process the results
                                                        this.$root.preloader = false;
                                                        if (resp.data && !resp.data.error) {
                                                            //update successfully, now update the parent
                                                            swal({
                                                                title: value,
                                                                text: "Your request was completed successfully",
                                                                icon: "success",
                                                            }).then(
                                                                function () {
                                                                    Bus.$emit("refresh");
                                                                }.bind(this)
                                                            );
                                                        } else {
                                                            //Handle errors
                                                            swal({
                                                                title: value,
                                                                text: resp.data.error.message,
                                                                icon: "error",
                                                            });
                                                        }
                                                    }.bind(this)
                                                )
                                                .catch(
                                                    function (err) {
                                                        this.$root.preloader = false;
                                                        this.errormsg = err.message || "An unexpected error occured";
                                                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                                                    }.bind(this)
                                                );
                                        }.bind(this)
                                    );
                                },
                                remove: function () {
                                    //Handle removing the item
                                    swal({
                                        title: "Remove",
                                        text: "Are you sure?",
                                        icon: "info",
                                        buttons: ["No", "Yes"],
                                    }).then(
                                        function (val) {
                                            if (!val) return;

                                            var request = { apikey: this.$root.apikey, id: this.data._id };
                                            // this.$root.preloader = true;

                                            axios({
                                                url: `${this.$root.serverUrl}/admin/campaigns/${this.data._id}`,
                                                data: request,
                                                responseType: "json",
                                                method: "DELETE",
                                            })
                                                .then(
                                                    function (resp) {
                                                        //Process the results
                                                        this.$root.preloader = false;
                                                        if (resp.data && !resp.data.error) {
                                                            //Saved successfully, now update the parent
                                                            swal({
                                                                title: "Remove",
                                                                text: "Your request was completed successfully",
                                                                icon: "success",
                                                            }).then(
                                                                function () {
                                                                    Bus.$emit("refresh");
                                                                }.bind(this)
                                                            );
                                                        } else {
                                                            //Handle errors
                                                            swal({
                                                                title: "Remove",
                                                                text: resp.data.error.message,
                                                                icon: "error",
                                                            });
                                                        }
                                                    }.bind(this)
                                                )
                                                .catch(
                                                    function (err) {
                                                        this.$root.preloader = false;
                                                        this.errormsg = err.message || "An unexpected error occured";
                                                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                                                    }.bind(this)
                                                );
                                        }.bind(this)
                                    );
                                },
                            }
                        }
                    )
                }
            },
            progressTemplate: function () {
                return {
                    template: Vue.component('daysTemplate', {
                            template: `
                              <div :class="data.status + ' progresss'" style="position: relative;"> <div :class="data.status + '-bg progresss-bar'" :style="'width: ' + percent + '%'"><div style="position: absolute; left: 45%;">{{percent}}%</div></div></div>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            computed: {
                                percent: function() {
                                    if((this.data.delivered.count / Number(this.data.target || 0) * 100) > 100) return 100;
                                    return ((this.data.delivered.count / Number(this.data.target || 0)) * 100).toFixed(0)
                                }
                            }
                        }
                    )
                }
            },
            deliveredTemplate: function () {
                return {
                    template: Vue.component('daysTemplate', {
                            template: `
                              <div class="status" :style="{background: clickProgress, color: '#ffffff'}">
                                {{ data.delivered.count }}
                              </div>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            computed: {
                                clickProgress() {
                                    let code = "";

                                    const start = moment(this.data.startDate, "YYYY-MM-DD").tz(this.data.timezone || 'EST');
                                    const end = moment(this.data.endDate, "YYYY-MM-DD").tz(this.data.timezone || 'EST');

                                    //Difference in number of days
                                    const number_of_days = moment.duration(end.diff(start)).asDays();

                                    const delivered = this.data.delivered.count || 0;
                                    const total_clicks = this.data.target;

                                    const number_of_days_complete = Math.ceil(moment.duration(moment().diff(start)).asDays());

                                    const differential = Math.round((delivered - (total_clicks / number_of_days * number_of_days_complete)));

                                    const track = Math.abs((differential / (total_clicks / number_of_days * number_of_days_complete)));

                                    const percent_on_track = Math.round(100 - (track * 100));

                                    switch (true) {
                                        case percent_on_track >= 90:
                                            code = "#009933";
                                            break;
                                        case percent_on_track >= 70 && percent_on_track < 90:
                                            code = "#aaff80";
                                            break;
                                        case percent_on_track >= 50 && percent_on_track < 70:
                                            code = "#ffff1a";
                                            break;
                                        case percent_on_track >= 30 && percent_on_track < 50:
                                            code = "#ff944d";
                                            break;
                                        case percent_on_track >= 0 && percent_on_track < 30:
                                            code = "#cc0000";
                                            break;
                                        default:
                                            code = "#cc0000";
                                    }

                                    return code;
                                },
                            }
                        }
                    )
                }
            },
            daysTemplate: function () {
                return {
                    template: Vue.component('daysTemplate', {
                            template: `<div> {{ days }}</div>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            computed: {
                                days: function() {
                                    let start = moment(this.data.startDate, "YYYY-MM-DD");
                                    let end = moment(this.data.endDate, "YYYY-MM-DD");

                                    //Difference in number of days
                                    return moment.duration(end.diff(start)).asDays();
                                }
                            }
                        }
                    )
                }
            },
        };
    },
    created: function () {
        Bus.$on("details", (data) => {
            this.detail = data;
        })

        Bus.$on("clicks", (data) => {
            this.clicks = data;
        });

        Bus.$on("link", (data) => {
            this.$emit("link", data)
        });

        Bus.$on("edit", (data) => {
            this.$emit("edit", data)
        })

        Bus.$on("proxy", (data) => {
            this.proxy = data;
        })

        Bus.$on("error", (data) => {
            this.error = data;
        })

        Bus.$on("report", (data) => {
            this.report = data;
        })

        Bus.$on("change", (data) => {
            this.change = data;
        })

        Bus.$on("refresh", () => {
            this.$emit("refresh");
        })

        axios.get(`${SERVER_URL}/admin/settings`).then((resp) => {
            if(resp.data && resp.data.data) {
                this.settings = resp.data.data.settings;
            }
        }).catch(function (err) {
            console.error(err.message);
        });
    },
    mounted() {
        this.$refs.grid.showSpinner();
    },
    methods: {
        setError(e) {
            this.errorDetails = e;
            window.$('#myErrorDetailModal').modal('show')
        },
        onSelectionChange(args) {
            // This method is called when the selection changes
            // You can handle additional logic here if needed
            console.log('Selection Changed:', args);

            this.selectedItems = []
            const selectedRecords = this.$refs.grid.ej2Instances.getSelectedRecords();
            for (let i = 0; i < selectedRecords.length; i++) {
                this.selectedItems.push(selectedRecords[i].order_id)
            }
        },
        actionFailure(args) {
            console.log(args)
            if (args.error.error.status === 401) {
                localStorage.clear();
                document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                if(this.$route.path != "/login")
                    this.$router.push('/login')
            }
        },
        handleSelection() {
            const request = {
                orders: this.selectedItems
            }
            axios.post(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report/selected`, request, {responseType: "arraybuffer", headers: {contentType: 'application/json'}}).then((resp) => {
                console.log(resp)
                let fileURL = window.URL.createObjectURL(new Blob([resp.data], {type:'application/zip'}));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `unsubscribe export - ${new Date().toLocaleDateString()}.zip`);
                document.body.appendChild(fileLink);

                fileLink.click();
                this.$root.preloader = false
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: 'Communication Error', icon:"error"});
                this.$root.preloader = false;
            }).finally(() => {
                this.$refs.grid.hideSpinner();
            });
        },
        handleFileUpload() {
            this.$refs.grid.showSpinner();
            this.fileToUpload = this.$refs.fileUploader.files[0];
            const request = new FormData();
            if(this.fileToUpload === null){
                swal({title:"Error", text: "a file needs to be selected", icon:"error"});
                return;
            }
            console.log(this.fileToUpload.type)
            if(this.fileToUpload.type !== "text/csv"){
                swal({title:"Error", text: "a csv file needs to be selected", icon:"error"});
            }
            request.append("file", this.fileToUpload);

            axios.post(`${this.$root.serverUrl}/admin/reports/emailverifier/unsubscribe-report`, request, {responseType: "arraybuffer", headers: {contentType: 'multipart/form-data'}}).then((resp) => {
                console.log(resp)
                let fileURL = window.URL.createObjectURL(new Blob([resp.data], {type:'application/zip'}));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `unsubscribe export - ${new Date().toLocaleDateString()}.zip`);
                document.body.appendChild(fileLink);

                fileLink.click();
                this.$root.preloader = false
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: 'Communication Error', icon:"error"});
                this.$root.preloader = false;
            }).finally(() => {
                this.$refs.grid.hideSpinner();
            });
        },
        cellEdit: function(args) {
            console.log(args);
            if (args.columnName !== "status") {
                args.cancel = true;
            }
        },
        toolbarClick(args = ClickEventArgs) {
            switch (args.item.text) {
                /* tslint:disable */
                case 'Update':
                    this.changes = this.$refs.grid.ej2Instances.getBatchChanges();
                    break;
            }
        },
        actionComplete: function(args) {
            this.$emit('hideLoader', true)
            if(args.requestType === "batchsave") {
                var changes = []
                for (let i = 0; i < this.changes.changedRecords.length; i++) {
                    changes.push({_id: this.changes.changedRecords[i]._id, status: this.changes.changedRecords[i].status})
                }

                axios.post(`${this.$root.serverUrl}/admin/campaigns/update/status`, {updates: changes}).then(() => {
                    this.changes = [];
                    this.$refs.grid.ej2Instances.batchSave();
                }).catch((err) => {
                    console.log(err)
                });
            }
        },
        actionBegin: function(args) {
            console.log(args)
            if(args.requestType === "paging") {
                args.cancel = true;
                window.scrollTo(0,0);
                this.$emit('hideLoader', false)
                this.$emit("paginate", args.currentPage, this.$refs.grid.ej2Instances.pageSettings.pageSize);
            }
        }
    }
};
</script>

<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";

.progresss {
    height: 40px;
    width: 100%;
    border-radius: 10rem;
}

.progresss-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF !important;
    font-weight: bold;

    height: 40px;
    border-radius: 10rem;
    opacity: 1;
}
.target {
    background: transparent !important;
    font-weight: bold;
}
.status {
    width: max-content;
    padding: 5px;
    color: green;
    text-align: center;
    border-radius: 4px;
    font-weight: bold;
}
span.unverified {
    color: red;
}
span.verified {
    color: green;
}
.legend {
    gap: 10px;
    display: flex;
}
.legend > div {
    width: 20px;
    height: 20px;
}
div.complete {
    background: rgba(0, 0, 255, 0.15);
    color: blue;
}
.campaign div.active {
    background: rgba(0, 128, 0, 0.15);
    color: green;
}
div.error {
    background: rgba(255, 0, 0, 0.15);
    color: red;
}
div.disabled {
    background: rgba(128, 128, 128, 0.15);
    color: grey;
}
div.unconfigured {
    background: rgba(255, 165, 0, 0.15);
    color: orange;
}

div.pending {
    background: rgba(255, 255, 0, 0.15);
    color: rgb(117, 117, 28);
}
div.complete-bg {
    background: rgba(0, 0, 255, 1);
}
.campaign div.active-bg {
    background: rgba(0, 128, 0,1);
}
div.error-bg {
    background: rgba(255, 0, 0, 1);
}
div.disabled-bg {
    background: rgba(128, 128, 128, 1);
}
div.pending-bg {
    background: rgb(117, 117, 28);
}
div.unconfigured-bg {
    background: orange;
}
.legend-cat {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.action-btn {
    border-radius: 20px;
    width: 48px;
    border: 1px solid #d2d2d2;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    font-size: 20px;
    line-height: 27px;
    vertical-align: middle;
    height: 36px;
    color: #706c6c;
}
.e-templatecell:nth-last-of-type(1) {
    overflow: visible !important;
}
.e-gridcontent .e-content {
    min-height: 300px;
}
</style>
