<template>
    <div>
<!--        //Update to code-->
    <form-wizard ref="formwizard" @onComplete="onComplete" @onNextStep="nextStep" @onPreviousStep="previousStep" @onReset="reset" >
        <tab-content title="Product Info" >

            <!-- Email Warmer Section -->
            <div class="row col-12"  >
                <div class="row col-12"><h5>Campaign Info</h5><br><hr></div>
                <div class="row col-12">
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label >Select a Client </label>
                            <select v-model="item.client_id" @change="handleChange($event)" class="form-control text-capitalize required"  :class="hasError('client_id') ? 'is-invalid' : ''" >
                                <option v-for="item in client" :key="item.value" :value="item.value">{{ item.text }}</option>
                            </select>
<!--                            <div class="form-text text-muted">The affiliated client to which the campaign belongs</div>-->
                            <div v-if="hasError('client_id')" class="invalid-feedback">
                                <div class="error" v-if="!$v.formData.client_id.required">
                                    Please Select Client ID.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label > <strong class="text-white">*</strong></label>
                            <button class="btn btn-primary form-control"  @click.prevent ="addNewClient">Add a new client</button>
                        </div>
                    </div>
                </div>


                <div class="row col-12">
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label>Choose a Product <strong class="text-white">*</strong></label>
                            <select v-model="item.product_type" @change="handleLoadPlans($event)" class="form-control text-capitalize required" :class="hasError('product_type') ? 'is-invalid' : ''"  >
                                <option v-for="item in products" :key="item.key" :value="item.key">{{ item.value }}</option>
                            </select>
<!--                            <div class="form-text text-muted">The affiliated product type for the campaign being created</div>-->
                            <div v-if="hasError('product_type')" class="invalid-feedback">
                                <div class="error" v-if="!$v.formData.product_type.required">
                                    Please Select A Product Type.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label>Create a workflow/Campaign Name </label>
                            <input type="text" v-model="formData.campaign_name" class="form-control"  :class="hasError('campaign_name') ? 'is-invalid' : ''" />
<!--                            <div class="form-text text-muted">Name Of Campaign</div>-->
                            <div v-if="hasError('campaign_name')" class="invalid-feedback">
                                <div class="error" v-if="!$v.formData.campaign_name.required">
                                    Please Enter Campaign Name.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label>Domain for the Email Platform </label>
                                                <!--                                    <ejs-dropdownlist cssClass="e-outline" :dataSource='statuses' :mode='true' v-model="formData.status" placeholder='Status'  :class="hasError('status') ? 'is-invalid' : ''"></ejs-dropdownlist>-->
                            <select v-model="formData.isp" class="form-control text-capitalize required" :class="hasError('isp') ? 'is-invalid' : ''"  >
                                <option v-for="item in isps" :key="item" :value="item">{{ item }}</option>
                            </select>
<!--                            <multiselect v-model="formData.isp" :options="isps" :close-on-select="false" :multiple="true"   :class="hasError('isp') ? 'is-invalid' : ''"></multiselect>-->
<!--                            <div class="form-text text-muted">The email service provider (outlook, gamil, yahoo.. etc)</div>-->
                            <div v-if="hasError('isp')" class="invalid-feedback">
                                <div class="error" v-if="!$v.formData.isp.required">
                                    Please Select a email serivce.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </tab-content>
        <tab-content title="Configuration Settings">
            <div>
                <div class="row  pt-3">
                    <div class="col-12">
                        <h5>
                            Campaign Settings
                        </h5>
                    </div>
                    <div class=" row col-12">
                        <div class="row col-12">
                            <div class="col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>Session Behaviour(s) <strong class="text-white">*</strong></label>
                                    <multiselect v-model="formData.emailBehaviors" :options="options" :close-on-select="false" :multiple="true"  :class="hasError('emailBehaviors') ? 'is-invalid' : ''"></multiselect>
                                    <div v-if="hasError('emailBehaviors')" class="invalid-feedback">
                                        <div class="error" v-if="!$v.formData.emailBehaviors.required">
                                            Please Select Behaviors.
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>Session Duration in minutes <strong class="text-white">*</strong></label>
                                    <input type="number"  v-model="formData.time" class="form-control"  min="1" max="120"  :class="hasError('time') ? 'is-invalid' : ''">
                                    <div v-if="hasError('time')" class="invalid-feedback">
                                        <div class="error" v-if="!$v.formData.time.required">
                                            Please Select Session Duration.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>Status <strong class="text-white">*</strong></label>
                                    <!--                                    <ejs-dropdownlist cssClass="e-outline" :dataSource='statuses' :mode='true' v-model="formData.status" placeholder='Status'  :class="hasError('status') ? 'is-invalid' : ''"></ejs-dropdownlist>-->
                                    <select v-model="formData.status" class="form-control text-capitalize required" :class="hasError('status') ? 'is-invalid' : ''"  >
                                        <option v-for="item in statuses" :key="item" :value="item">{{ item }}</option>
                                    </select>
                                    <div v-if="hasError('status')" class="invalid-feedback">
                                        <div class="error" v-if="!$v.formData.status.required">
                                            Please Select Status.
                                        </div>
                                    </div>
                                </div>
                            </div>

<!--                            time settings-->
                            <div class="row col-12">
                                <div class="col-sm-2 col-md-2 col-lg-2">
                                    <div class="form-group ">
                                        <div class="form-group ">
                                            <label>Start Date <strong class="text-white">*</strong></label>
                                            <input type="date" v-model="formData.from" class="form-control date" placeholder="Date"  :class="hasError('from') ? 'is-invalid' : ''"/>
                                            <div v-if="hasError('from')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.from.required">
                                                    Please Select Client ID.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2">
                                    <div class="form-group ">
                                        <div class="form-group">
                                            <label>End Date <strong class="text-white">*</strong></label>
                                            <input type="date" v-model="formData.to" class="form-control date" placeholder="To"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2">
                                    <div class="form-group ">
                                        <div class="form-group ">
                                            <label>Start Time</label>
                                            <input type="time" v-model="formData.startTime" class="form-control date" placeholder="Date"  :class="hasError('startTime') ? 'is-invalid' : ''"/>
                                            <div v-if="hasError('startTime')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.startTime.required">
                                                    Please Select Start Time.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2 col-lg-2">
                                    <div class="form-group ">
                                        <div class="form-group">
                                            <label>End Date</label>
                                            <input type="time" v-model="formData.endTime" class="form-control date" placeholder="To" :class="hasError('endTime') ? 'is-invalid' : ''"/>
                                            <div v-if="hasError('endTime')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.endTime.required">
                                                    Please Select End Time.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-3 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>Time Zone</label>
                                    <select v-model="formData.timezone" class="form-control text-capitalize" :class="hasError('timezone') ? 'is-invalid' : ''"  >
                                        <option value="" disabled>Time Zone</option>
                                        <option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</option>
                                    </select>
                                    <div v-if="hasError('timezone')" class="invalid-feedback">
                                        <div class="error" v-if="!$v.formData.timezone.required">
                                            Please Select End Time.
                                        </div>
                                    </div>
                                </div>
                                </div>
<!--                                <div class="col-sm-3 col-md-3 col-lg-3">-->
<!--                                    <div class="form-group">-->
<!--                                        <label>Session Time Schedule(s) <strong class="text-danger">*</strong></label>-->
<!--                                        <multiselect v-model="formData.timeSchedule" :options="timeOptions" :close-on-select="false" :multiple="true"  :class="hasError('timeSchedule') ? 'is-invalid' : ''"></multiselect>-->
<!--                                        <div v-if="hasError('timeSchedule')" class="invalid-feedback">-->
<!--                                            <div class="error" v-if="!$v.formData.timeSchedule.required">-->
<!--                                                Please Select Behaviors.-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->

                            </div>

                        </div>
                        <div class="row col-12 pt-3">
                            <!--                        exclusion list-->
                            <div class="col-12">
                                <h5 class="mb-4">
                                    Email Rules
                                </h5>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Enter a list to <span class="text-danger">Avoid</span> replying to<strong class="text-white">*</strong></label>
                                            <b-form-tags input-id="tags-basic" v-model="formData.exclusionList" placeholder="Enter here" :class="hasError('exclusionList') ? 'is-invalid' : ''"></b-form-tags>
                                            <div class="form-text text-muted">Enter short name ex. ebay</div>
                                            <div v-if="hasError('exclusionList')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.exclusionList.required">
                                                    Please Select Client ID.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Enter a list to <span class="text-danger">Delete</span> emails from<strong class="text-white">*</strong></label>
                                            <b-form-tags input-id="tags-basic" v-model="formData.deleteList" placeholder="Enter here " :class="hasError('exclusionList') ? 'is-invalid' : ''"></b-form-tags>
                                            <div class="form-text text-muted">Enter short name ex. ebay</div>
                                            <div v-if="hasError('deleteList')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.deleteList.required">
                                                    Please Select Client ID.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Enter a list to <span class="text-danger">Forward</span> emails from<strong class="text-white">*</strong></label>
                                            <b-form-tags input-id="tags-basic" v-model="formData.forwardList" placeholder="Enter here " :class="hasError('exclusionList') ? 'is-invalid' : ''"></b-form-tags>
                                            <div class="form-text text-muted">Enter short name ex. ebay</div>
                                            <div v-if="hasError('deleteList')" class="invalid-feedback">
                                                <div class="error" v-if="!$v.formData.forwardList.required">
                                                    Please Select Client ID.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="row col-12 pt-3">
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Log in Frequency (Per day) <strong class="text-white">*</strong></label>
                                <input type="number"  v-model="formData.loginFrequency" class="form-control"  min="1" max="120"  :class="hasError('loginFrequency') ? 'is-invalid' : ''">
                                <div v-if="hasError('loginFrequency')" class="invalid-feedback">
                                    <div class="error" v-if="!$v.formData.loginFrequency.required">
                                        Please Select Session Duration.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Actions to perfrom </label>
                                <multiselect v-model="formData.actionsToPerform" :options="actionsToPerform" :close-on-select="false" :multiple="true"  :class="hasError('actionsToPerform') ? 'is-invalid' : ''"></multiselect>
                                <div v-if="hasError('actionsToPerform')" class="invalid-feedback">
                                    <div class="error" v-if="!$v.formData.actionsToPerform.required">
                                        Please Select Behaviors.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Day of the Week for Actions <strong class="text-white">*</strong></label>
                                <multiselect v-model="formData.daysOfTheWeek" :options="daysOfTheWeek" :close-on-select="false" :multiple="true"  :class="hasError('daysOfTheWeek') ? 'is-invalid' : ''"></multiselect>
                                <div v-if="hasError('daysOfTheWeek')" class="invalid-feedback">
                                    <div class="error" v-if="!$v.formData.daysOfTheWeek.required">
                                        Please Select Days.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </tab-content>
        <tab-content title="Add Email Accounts">
            <div class="row  col-12 mt-5">
                <div class="col-12">
                    <h5>
                        Add Sender Email Address
                    </h5>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Email Address </label>
                                <input type="text" v-model="formData.emailList.email" class="form-control" />
                                <p class="text-danger" v-if="errors.emailList.email">{{ errors.emailList.email}}</p>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Password </label>
                                <input type="text" v-model="formData.emailList.password" class="form-control" />
                                <p class="text-danger" v-if="errors.emailList.password">{{ errors.emailList.password}}</p>
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1 mt-4 p-2" >
                            <button class="btn btn-sm btn-info" v-if="!emailAddressEdit"  @click.prevent ="addPassword()">Add</button>
                            <button class="btn btn-sm btn-info" v-else  @click.prevent ="editPassword()">Update</button>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <label class="text-white">.</label>
                            <div class="row alert alert-success" role="alert" v-if="behaviorsSuccess">
                                <div class="col-10">
                                    <div class="mt-2" >
                                        <i class="fa fa-check-circle" aria-hidden="true"></i> {{behaviorsSuccessMessage}}
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button class="btn text-success" @click="updatebehaviorsSuccess()"><i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <h5 class="text-bold">
                        Manage  Email Addresses
                    </h5>
                </div>
                <div v-if="formData" class="col-12">
                    <!--                    <div v-if="formData.rules && formData.rules.length != 0" class="mt-2"><strong>Forwarding</strong></div>-->
                    <div>
                        <b-table striped hover :items="formData.emailAddressList" :fields="emailAddressFields">
<!--                            actionsPerformed-->
<!--                            <template #cell(actionsPerformed)="data">-->
<!--                                <span>{{ data.item.behaviors.join(', ')  }}</span>-->
<!--                            </template>-->
                            <template #cell(actions)="data">
                                <b-button size="sm" variant="info" class="mr-2" @click=" editEmailAddressList(data.item,data.index)"><i class="fa fa-pencil" /></b-button>
                                <b-button size="sm" variant="danger" @click=" removeRule(data.item)"><i class="fa fa-trash" /></b-button>

                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </tab-content>
        <tab-content title="Sender Email Address">
            <div class="row  col-12 mt-5">
                <div class="col-12">
                    <h5>
                        Add Sender Email Address
                    </h5>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Email </label>
                                <input type="text" v-model="formData.rule.sender_email" class="form-control" />
                                <p class="text-danger" v-if="errors.rule.sender_email">{{ errors.rule.sender_email}}</p>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label>Session Behaviour(s)</label>
                                <multiselect v-model="formData.rule.behaviors" :options="optionTwo" :close-on-select="false" :multiple="true"></multiselect>
<!--                                <multiselect v-model="formData.emailBehaviors" :options="options" :close-on-select="false" :multiple="true"  :class="hasError('emailBehaviors') ? 'is-invalid' : ''"></multiselect>-->

<!--                                <p class="text-danger" v-if="errors.rule.behaviors">{{ errors.rule.behaviors }}</p>-->
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1 mt-4 p-2" >
                            <button class="btn btn-sm btn-info"  @click.prevent ="addRule()">Add</button>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                                <label class="text-white">.</label>
                                <div class="row alert alert-success" role="alert" v-if="behaviorsSuccess">
                                        <div class="col-10">
                                            <div class="mt-2" >
                                                <i class="fa fa-check-circle" aria-hidden="true"></i> {{behaviorsSuccessMessage}}
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <button class="btn text-success" @click="updatebehaviorsSuccess()"><i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                </div>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <h5 class="text-bold">
                       Manage Sender Email Addresses
                    </h5>
                </div>
                <div v-if="formData" class="col-12">
<!--                    <div v-if="formData.rules && formData.rules.length != 0" class="mt-2"><strong>Forwarding</strong></div>-->
                    <div>
                        <b-table striped hover :items="formData.rules" :fields="fields">
                            actionsPerformed
                            <template #cell(actionsPerformed)="data">
                                <span>{{ data.item.behaviors.join(', ')  }}</span>
                            </template>
                            <template #cell(actions)="data">
                                <b-button size="sm" variant="danger" @click=" removeRule(data.item)"><i class="fa fa-trash" /></b-button>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </tab-content>
        <tab-content title="Forwarding Sender Email Address">
            <div class="row  col-12 mt-5">
                <div class="col-12">
                    <h5>
                        Forwarding Address
                    </h5>
                </div>
                <div class="col-6 pb-3 pt-2">
<!--                    <div class="row">-->
                        <b-form-group label="Select Option">
                            <b-form-radio-group v-model="formData.selectedOption" name="options">
                                <b-form-radio value="existing">Existing</b-form-radio>
                                <b-form-radio value="new">New</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>

<!--                    </div>-->

                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <div class="form-group">
                                <label>Sender Email </label>
                                <input type="text" v-model="formData.forwarding.sender_email" class="form-control"  />
                                <p class="text-danger" v-if="errors.forwarding.sender_email">{{ errors.forwarding.sender_email }}</p>
<!--                                <div v-if="hasError('forwarding.sender_email')" class="invalid-feedback">-->
<!--                                    <div class="error" v-if="!$v.formData.forwarding.sender_email">-->
<!--                                        Please Input Sender Email.-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3" v-if="formData.selectedOption === 'new'">
                            <div class="form-group">
                                <label>Enter ForwardingSender Email Address </label>
                                <input type="text" v-model="formData.forwarding.email" class="form-control" />
                                <p class="text-danger" v-if="errors.forwarding.email">{{ errors.forwarding.email }}</p>
<!--                                <div v-if="hasError('forwarding.email')" class="invalid-feedback">-->
<!--                                    <div class="error" v-if="!$v.formData.forwarding.email">-->
<!--                                        Please Select Client ID.-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3" v-else>
                            <div class="form-group">
                                <label>Select ForwardingSender Email Address </label>
                                <!--                                    <ejs-dropdownlist cssClass="e-outline" :dataSource='statuses' :mode='true' v-model="formData.status" placeholder='Status'  :class="hasError('status') ? 'is-invalid' : ''"></ejs-dropdownlist>-->
                                <select v-model="formData.forwarding.email" class="form-control text-capitalize required"  >
                                    <option v-for="item in formData.forwardings" :key="item.email" :value="item.email">{{ item.email }}</option>
                                </select>
                                <p class="text-danger" v-if="errors.forwarding.email">{{ errors.forwarding.email }}</p>
<!--                                <div v-if="hasError('status')" class="invalid-feedback">-->
<!--                                    <div class="error" v-if="!$v.formData.forwarding.email.required">-->
<!--                                        Please Select Status.-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1 mt-2 p-2" style="align-self: center">
                            <button class="btn btn-md btn-primary"  @click.prevent ="addForwarding()">Add</button>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <label class="text-white">.</label>
                            <div class="row alert alert-success" role="alert" v-if="behaviorsSuccess">
                                <div class="col-10">
                                    <div class="mt-2" >
                                        <i class="fa fa-check-circle" aria-hidden="true"></i> Changes added succesfully!
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button class="btn text-success" @click="updatebehaviorsSuccess()"><i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <hr class="mt-2">-->
<!--                    <div class='text-danger text-center'>Ensure to press the add button after input to add Sender Email and Forwarding Email</div>-->
<!--                    <hr>-->
                </div>


                <div v-if="formData" class=" row col-12 pt-3">
                    <div class="col-12">
                        <h5>
                            Manage Forwarding Sender Email Address
                        </h5>
                    </div>

<!--                    <div class="row">-->
                    <div class="col-6">
                        <b-table striped hover :items="formData.forwardings" :fields="forwardingsFieldsTwo">
                            <template #cell(actions)="data">
                                <b-button size="sm" variant="danger" @click="removeForwarding(data.item)"><i class="fa fa-trash" /></b-button>
<!--                                removeForwarding(index)-->
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </tab-content>
        <tab-content title="Junk Removal">
            <div class="row col-12 mt-5">
                <div class="col-12">
                    <h5>
                       Junk Email Removal
                    </h5>
                </div>
<!--                <div class=" row">-->
                    <div class="col-12 pt-3 pb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formData.junkActive" value="true">
                            <label class="form-check-label" for="flexCheckDefault">
                                Enable Junk Email Removal
                            </label>
                        </div>
                    </div>

<!--                </div>-->
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Email Address to remove from Junk </label>
                                <input type="text" v-model="formData.junk.sender_email" class="form-control"/>
                                <p class="text-danger" v-if="errors.junk.sender_email">{{ errors.junk.sender_email }}</p>
<!--                                <div v-if="hasError('formData.junk.sender_email')" class="invalid-feedback">-->
<!--                                    <div class="error" v-if="!$v.formData.junk.sender_email">-->
<!--                                        Please Select Client ID.-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="col-sm-1 col-md-1 col-lg-1 mt-2 p-2" style="align-self: center">
                            <button class="btn btn-md btn-primary"  @click.prevent ="addJunk()">Add</button>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3">
                            <label class="text-white">.</label>
                            <div class="row alert alert-success" role="alert" v-if="behaviorsSuccess">
                                <div class="col-10">
                                    <div class="mt-2" >
                                        <i class="fa fa-check-circle" aria-hidden="true"></i> {{behaviorsSuccessMessage}}
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button class="btn text-success" @click="updatebehaviorsSuccess()"><i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="formData" class="col-12">

                    <div class="col-12">
                        <h5>
                            Manage Forwarding Sender Email Address
                        </h5>
                    </div>

                    <!--                    <div class="row">-->
                    <div class="col-6">
                        <b-table striped hover :items="formData.junks" :fields="junksFields">
                            <template #cell(actions)="data">
                                <b-button size="sm" variant="danger" @click="deleteEmail(data.item)"><i class="fa fa-trash" /></b-button>
                                <!--                                removeForwarding(index)-->
                            </template>

                            <template #cell(active)="data">
                                <input type="checkbox" :checked="data.item.junkSelect" @change="updateJunkSelect(data.item, $event)">
                            </template>
                        </b-table>
                    </div>
<!--                    <div class="row" v-for="(junk, index) in formData.junks" :key="index">-->

<!--                        <div class="col-sm-4 col-md-4 col-lg-4">-->
<!--                            <div class="form-group">-->
<!--                                <label>Sender Email</label>-->
<!--                                <input type="text" v-model="junk.sender_email" class="form-control" :class="hasError('exclusionList') ? 'is-invalid' : ''" />-->
<!--                                <div v-if="hasError('forwarding.email')" class="invalid-feedback">-->
<!--                                    <div class="error" v-if="!$v.formData.forwarding.email">-->
<!--                                        Please Select Client ID.-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-sm-2 col-md-2 col-lg-2 mt-2 p-2" style="align-self: center">-->
<!--                            <button class="btn btn-sm btn-danger"  @click.prevent ="removeJunk(index)"><i class="fa fa-trash" /></button>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </tab-content>
    </form-wizard>
        <div>


            <b-modal id="modal-scrollable"  size="lg">

                <div class="container">
                    <h3 class="text-center">Campaign Review</h3>
                    <hr class="pb-4">
                    <div class="row">
                        <div class="col-md-6">
                            <h3>General Settings</h3>
                            <ul class="list-group">

                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Product
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.product_type }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Client
                                    <span class="badge bg-primary rounded p-2 text-light" >{{ formData.client }}</span>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Campaign Name
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.campaign_name }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Status
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.status }}</span>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Start Date
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.from }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    End Date
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.to }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Start Time
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.startTime }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    End Time
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.endTime }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                   Time Zone
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.timezone }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <h3>Email Settings</h3>
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Email Behaviors
                                    <span class="badge bg-primary rounded p-2 text-light" v-if="formData.emailBehaviors != null && formData.emailBehaviors.length != 0">{{ formData.emailBehaviors.join(', ') }}</span>
                                </li>

                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    List of Email Addresses (empty)
                                    <span class="badge bg-primary rounded p-2 text-light">
                                 {{formData.listOfEmailAddress!= undefined && formData.listOfEmailAddress.length === 0 ? 'N/A' : formData.listOfEmailAddress }}
                                 </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Junk Active
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.junkActive }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Login Frequency
                                    <span class="badge bg-primary rounded p-2 text-light">{{ formData.loginFrequency }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    ISP
                                    <span class="badge bg-primary rounded p-2 text-light" v-if="formData.isp != undefined &&  formData.isp.length != 0 || formData.isp != ''">{{ formData.isp }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Days of the Week to run
                                    <span class="badge bg-primary rounded p-2 text-light" v-if="formData.daysOfTheWeek != undefined && formData.daysOfTheWeek.length != 0" >{{ formData.daysOfTheWeek.join(', ')  }}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    Time in inbox
                                    <span class="badge bg-primary rounded p-2 text-light" >{{ formData.time  }} Minutes</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="forward in formData.forwardings" :key="forward.sender_email">
<!--                                    <div class="d-flex justify-content-between align-items-center">-->
                                        <span>Forwarding</span>
                                        <span class="badge bg-primary rounded p-2 text-light">{{ forward.email }}</span>
<!--                                    </div>-->
                                </li>
                            </ul>
                        </div>
                        <hr class="pb-4">
                        <div class="col-md-6  pt-4">
                            <hr style="width:100%;text-align:left;margin-left:0">
                            <h3 class="text-center">Email Address</h3>
                            <div>
                                <b-table striped hover :items="formData.emailAddressList" :fields ='emailAddressFieldsTwo'>
<!--                                    <template #cell(behaviors)="data">-->
<!--                                        <span>{{ data.item.behaviors.join(', ')  }}</span>-->
<!--                                    </template>-->
                                </b-table>
                            </div>
                        </div>
                        <div class="col-md-6  pt-4">
                            <hr style="width:100%;text-align:left;margin-left:0">
                                <h3 class="text-center">Email Settings</h3>
                            <div>
                                <b-table striped hover :items="formData.rules" :fields="fieldsTwo">
                                    <template #cell(behaviors)="data">
                                        <span>{{ data.item.behaviors.join(', ')  }}</span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div class="col-md-6  pt-4">
                            <hr style="width:100%;text-align:left;margin-left:0">
                            <h3 class="text-center">Forward</h3>
                            <div>
                                <b-table striped hover :items="formData.forwardings" :fields="forwardingsFieldsTwo">

                                </b-table>
                            </div>
                        </div>

                        <div class="col-md-6  pt-4">
                            <hr style="width:100%;text-align:left;margin-left:0">
                            <h3 class="text-center">Junk</h3>
                            <div>
                                <b-table striped hover :items="formData.junks" :fields="junksFieldsTwo">

                                </b-table>
                            </div>
                        </div>


                    </div>

                </div>
                <template #modal-footer="{cancel, submit }">
                    <b-button size="sm" variant="danger" @click="cancel()">
                        Cancel
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button size="sm" variant="outline-success" @click="saveChanges()">
                       Save Changes
                    </b-button>
                </template>
            </b-modal>
        </div>

    </div>

</template>

<script>
import {FormWizard, TabContent,ValidationHelper } from 'vue-step-wizard'
// import { required } from "vuelidate/lib/validators";
// import { email } from "vuelidate/lib/validators";
// import { numeric } from "vuelidate/lib/validators";
import "vue-step-wizard/dist/vue-step-wizard.css";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment-timezone";
// import swal from "sweetalert";
// import axios from "axios";

export default {
    name: 'BasicStepperForm',
    components: {
        FormWizard, TabContent,
    },
    mixins: [ValidationHelper],
    props: {
        item: Object,
        inboxData:Object,
        client: Array,
        products: Array,
        onSave: {
            type: Function,
            required: true,
        },
        editmood:Boolean,
    },
    data(){
        return {
            test:{},
            behaviorsSuccess:false,
            emailAddressEdit:false,
            emailAddressEditIndex:0,
            behaviorsSuccessMessage:'Changes added succesfully!',
            timezones: moment.tz.names(),
            fullName: '',
            companyName: '',
            referral: '',
            timeOptions: this.generateTimeOptions(),
            emailAddressFieldsTwo:[
                { key: 'email', label: 'Email Address' },
                { key: 'password', label: 'Password' },
            ],
            emailAddressFields:[
                { key: 'email', label: 'Email Address' },
                { key: 'password', label: 'Password', formatter: this.maskPassword},
                { key: 'actions', label: 'Actions' },

            ],
            forwardingsFields:[
                { key: 'sender_email', label: 'Forwarding Email Address' },
                { key: 'status', label: 'Status' },
                { key: 'lastLogin', label: 'Last Used' },
                { key: 'actions', label: 'Actions' }
            ],
            forwardingsFieldsTwo:[
                { key: 'sender_email', label: 'Forwarding Email Address' },
                { key: 'email', label: 'Forward To' },
            ],
            junksFields:[
                { key: 'active', label: '' },
                { key: 'sender_email', label: 'Sender Email Address' },
                { key: 'actions', label: 'Actions' }
            ],
            junksFieldsTwo:[
                { key: 'junkSelect', label: 'Active' },
                { key: 'sender_email', label: 'Sender Email Address' },
            ],
            fields: [
                { key: 'sender_email', label: 'Email Address' },
                { key: 'status', label: 'Status' },
                { key: 'lastLogin', label: 'Last Login Date' },
                { key: 'actionsPerformed', label: 'Actions Performed' },
                { key: 'campaign_name', label: 'Assigned Campaign' },
                { key: 'forwardingAddress', label: 'Forwarding Address' },
                { key: 'junkEmailRemovalEnabled', label: 'Junk Email Removal Enabled' },
                { key: 'actions', label: 'Actions' }
            ],
            fieldsTwo: [
                { key: 'sender_email', label: 'Email Address' },
                { key: 'status', label: 'Status' },
                { key: 'behaviors', label: 'Behaviors' },
            ],
                // formData:{"client":'Candice Brown',"junkActive":true,"selectedOption":"existing","daysOfTheWeek":["Sunday","Monday","Tuesday","Wednesday"],"actionsToPerform":"","loginFrequency":"3","listOfEmailAddress":[],"forwardings":[{"sender_email":"asdsadasd","email":"active"}],"isp":["Gmail","Outlook"],"timeSchedule":"","client_id":"5f32bd5f08daa66e44f1b757","product_type":"inboxEnhanced","campaign_name":"test","emailBehaviors":["reply","delete","forward","junk"],"time":"3","status":"active","from":"2024-07-12","to":"2024-07-11","startTime":"01:00","endTime":"03:00","exclusionList":["asdasdasd","asdadasd"],"deleteList":["asdadasdsad","asdasdsad"],"forwardList":["adsadsad","asdsadda"],"forwarding":{"email":"","sender_email":""},"junk":{"sender_email":""},"reply":{"sender_email":""},"rule":{"sender_email":"","behaviors":[]},"emailList":{"email":"","password":""},"timezone":"Africa/Abidjan","rules":[{"sender_email":"shawasasdd","behaviors":["reply","delete","forward"],"junkEmailRemovalEnabled":"No","status":"active","campaign_name":"test"}],"junks":[{"sender_email":"asasdasdsad","junkSelect":true},{"sender_email":"asdadasdasd","junkSelect":false}]},
            formData:{
                junkActive:false,
                selectedOption: 'existing',
                daysOfTheWeek:[],
                actionsToPerform:[],
                loginFrequency:'',
                listOfEmailAddress:[],
                forwardings:[],
                isp:'',
                timeSchedule:'',
                client_id: '',
                client:'',
                product_type: '',
                campaign_name: '',
                emailBehaviors: [],
                time: '',
                status: '',
                from: "",
                to:"",
                startTime:'',
                endTime:'',
                exclusionList:"",
                deleteList:"",
                forwardList:'',
                emailAddress: { email: '', password:''},
                emailAddressList: [],
                forwarding: {sender_email: '', email: ''},
                junk: { sender_email: ''},
                reply: { sender_email: ''},
                rule: { sender_email: '', behaviors: []},
                emailList:{ email:'', password:''},
                timezone:'',
                rules:[],
                junks:[]
            },
            // validationRules: [
            //     { isp: { required }, client_id: { required }, campaign_name: { required }},
            //     // { emailList:{email: { required },password:{ required }}},
            //     //
            //     { emailBehaviors: { required }, time: { required }, status: { required },
            //         from: { required }, startTime: { required }, endTime: { required },
            //         timezone:{required},loginFrequency:{required},
            //     }
            // ],
            page: 1,
            count: 0,
            statuses: ["active", "disabled"],
            actionsToPerform:[ 'open', 'forward', 'reply', 'delete', 'mark as read'],
            daysOfTheWeek:["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            isps:[ "Gmail","Outlook"],
            isLoading: false,
            accounts: [],
            junk: { sender_email: ''},
            reply: { sender_email: ''},
            rule: { sender_email: '', behaviors: null},
            forwarding: {sender_email: '', email: ''},
            emailList:{ email:'', password:''},
            errors: { rule: { sender_email: null, behaviors: null},
                forwarding: {sender_email: null, email: null},
                emailList: {email: null, password: null},
                junk: {sender_email: null},
                reply:{sender_email: null}},
            options: ['reply', 'delete', 'forward','junk'],
            optionTwo: ['reply', 'delete', 'forward','junk'],
            data: [],
            preloader: false,
            max: 25,
            loading: false,
        }
    },
    mounted() {
        let data = this.item
        this.formData =  {

            junkActive: data.junkActive || false,
            selectedOption: data.selectedOption || 'existing',
            daysOfTheWeek: data.daysOfTheWeek || [],
            actionsToPerform: data.actionsToPerform || '', // You can populate this based on your needs
            loginFrequency: data.loginFrequency || '',
            listOfEmailAddress: data.listOfEmailAddress || [],
            forwardings: data.forwardings || [],
            isp: data.isp || '',
            timeSchedule: '', // Assuming this is a separate computed field
            client_id: data.client_id || '',
            client: data.client || '',
            product_type: data.product_type || '',
            campaign_name: data.campaign_name || '',
            emailBehaviors: data.emailBehaviors || [],
            time: data.time || '',
            status: data.status || '',
            from: data.from || '',
            to: data.to || '',
            startTime: data.startTime || '',
            endTime: data.endTime || '',
            exclusionList: data.exclusionList || '',
            deleteList: data.deleteList || '',
            forwardList: data.forwardList || '',
            emailAddress: { email: '', password: '' },
            emailAddressList: data.emailAddressList || [],
            forwarding: { sender_email: '', email: '' },
            junk: { sender_email: '' },
            reply: { sender_email: '' },
            rule: { sender_email: '', behaviors: [] },
            emailList: { email: '', password: '' },
            timezone: data.timezone || '',
            rules: data.rules || [],
            junks: data.junks || []
        };

            this.formData.junk = { sender_email: ''},
            this.formData.forwarding = {sender_email: '', email: ''},
            this.formData.reply = { sender_email: ''},
            this.formData.emailList = { email:'', password:''}
    },
    created() {

        if(!this.editmood){
            this.assignFormData()

            this.formData.client_id = this.item.client_id
            this.formData.product_type = this.item.product_type
            this.formData.deleteList = this.inboxData.deleteList
            this.formData.exclusionList = this.inboxData.exclusionList
            this.formData.forwardList = this.inboxData.forwardList
            // this.formData.rule = { sender_email: '', behaviors: null},
            this.assignClient(this.item.client_id)
        }else{
            // this.formData.Rule = []
            // this.formData = this.item
            // this.formData.junk = { sender_email: ''},
            // this.formData.forwarding = {sender_email: '', email: ''},
            // this.formData.reply = { sender_email: ''},
            // this.formData.emailList = { email:'', password:''},

            this.$forceUpdate()

        }

    },
    methods:{
        assignFormData:function(){
            this.formData = {
                junkActive:false,
                selectedOption: 'existing',
                daysOfTheWeek:[],
                actionsToPerform:[],
                loginFrequency:'',
                listOfEmailAddress:[],
                forwardings:[],
                isp:'',
                timeSchedule:'',
                client_id: '',
                client:'',
                product_type: '',
                campaign_name: '',
                emailBehaviors: [],
                time: '',
                status: '',
                from: "",
                to:"",
                startTime:'',
                endTime:'',
                exclusionList:"",
                deleteList:"",
                forwardList:'',
                emailAddress: { email: '', password:''},
                emailAddressList: [],
                forwarding: {sender_email: '', email: ''},
                junk: { sender_email: ''},
                reply: { sender_email: ''},
                rule: { sender_email: '', behaviors: null},
                emailList:{ email:'', password:''},
                timezone:'',
                rules:[],
                junks:[]
            }
            this.$forceUpdate()
        },
        maskPassword() {
            return '******'; // Replace with asterisks or any masking character
        },
        addNewClient(){
            this.$router.push('/clients')
        },
        updatebehaviorsSuccess(){
            this.behaviorsSuccess = false;
        },
        updateJunkSelect(item, event) {
            // Update the value in the object at the specific index
            let index = this.formData.junks.findIndex(junk => junk.sender_email === item.sender_email);
            if (index !== -1) {
                this.formData.junks[index].junkSelect = event.target.checked;
                this.$forceUpdate()
                console.log(event.target.checked,'heeeeey')
            }
        },
        generateTimeOptions() {
            const timeOptions = [];
            for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const period = hour < 12 ? 'AM' : 'PM';
                    let hour12 = hour % 12;
                    if (hour12 === 0) hour12 = 12; // Handle midnight and noon

                    const hourString = hour12.toString().padStart(2, '0');
                    const minuteString = minute.toString().padStart(2, '0');

                    timeOptions.push(`${hourString}:${minuteString} ${period}`);
                }
            }

            // Add the final time "11:59 PM"
            timeOptions.push("11:59 PM");

            return timeOptions;
        },
        saveChanges(){
            console.log(this.item["_id"], 'form data')
            var request = {
                apikey: this.$root.apikey,
                id: this.formData._id,
                args: this.formData,
            };
            // this.$root.preloader = true;
            this.item["_id"]? this.formData["_id"] = this.item["_id"] : ''
            this.formData["_id"] ? this.formData.edit = true : this.formData.edit = false
            axios({
                url: `${this.$root.serverUrl}/admin/campaigns`,
                data: request,
                responseType: "json",
                method: this.formData["_id"] ? "POST" : "PUT",
            })
                .then(
                    function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            if(resp.data.data.id) this.formData["_id"]  = resp.data.data.id

                            //Saved successfully, now update the parent
                            swal({
                                title: "Notice",
                                text: "Your changes were saved successfully",
                                icon: "success",
                            });
                            this.$bvModal.hide('modal-scrollable');
                            this.$refs.formwizard.changeStatus();
                        }
                        if (resp.data.error) {
                            //Handle errors
                            this.$root.preloader = false;
                            // console.log(resp.data.error.message)
                            // alert(resp.data.error)
                            swal({ title: "Error", text: resp.data.error.message, icon: "error" });
                            // swal({title:"Error", text: resp.data.error, icon:"error"})
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.$root.preloader = false;
                        swal({ title: "Error", text: err.message, icon: "error" });
                    }.bind(this)
                );
        },
        onComplete(){
            this.$bvModal.show('modal-scrollable');
            // this.saveChanges()
            // this.$refs.formwizard.changeStatus();
        },

        reset(){

            this.formData ={
                junkActive:false,
                selectedOption: 'existing',
                daysOfTheWeek:[],
                actionsToPerform:[],
                loginFrequency:'',
                listOfEmailAddress:[],
                forwardings:[],
                isp:'',
                timeSchedule:'',
                client_id: '',
                client:'',
                product_type: '',
                campaign_name: '',
                emailBehaviors: [],
                time: '',
                status: '',
                from: "",
                to:"",
                startTime:'',
                endTime:'',
                exclusionList:"",
                deleteList:"",
                forwardList:'',
                emailAddress: { email: '', password:''},
                emailAddressList: [],
                forwarding: {sender_email: '', email: ''},
                junk: { sender_email: ''},
                reply: { sender_email: ''},
                rule: { sender_email: '', behaviors: []},
                emailList:{ email:'', password:''},
                timezone:'',
                rules:[],
                junks:[]
            }
            // for(let field in this.formData){
            //     this.formData[field] = null;
            // }
        },

        nextStep(){
            this.behaviorsSuccess = false
        },

        previousStep(){
            //alert("On Previous Step");
        },
        assignClient(value){
            let foundClient = this.client.find(client => client.value == value);
            this.formData.client = foundClient ? foundClient.text : 'Client not found';
        },
        handleChange(event) {
            this.formData.client_id = event.target.value
            this.assignClient(event.target.value)
            // let foundClient = this.client.find(client => client.value == event.target.value);
            // this.formData.client = foundClient ? foundClient.text : 'Client not found';
        },
        handleLoadPlans(event) {
            this.formData.product_type = event.target.value
            this.$forceUpdate()

        },
        addForwarding() {
            if(this.formData.forwardings == undefined)this.formData.forwardings = []


            if (!this.formData.forwarding.sender_email) this.errors.forwarding.sender_email = "Please enter a valid email";
            else this.errors.forwarding.sender_email = null;
            if (!this.formData.forwarding.email) this.errors.forwarding.email = "Please enter a valid email";
            else this.errors.forwarding.email = null;

            let error = false;
            for (let err in this.errors.forwarding) {
                if (this.errors.forwarding[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;


            this.formData.forwardings.push(this.formData.forwarding)

            this.formData.forwarding = {
                email: '',
                sender_email: ''
            }
            this.behaviorsSuccessMessage = 'Fowarding added succesfully!'
            this.behaviorsSuccess = true
        },
        addEmailList() {
            if(this.formData.listOfEmailAddress == undefined)this.formData.listOfEmailAddress = []
            if (!this.formData.emailList.email) this.errors.emailList.email = "Please enter a valid email";
            else this.errors.emailList.email = null;
            if (!this.formData.emailList.password) this.errors.emailList.password = "Please enter a valid password";
            else this.errors.emailList.password = null;

            let error = false;
            for (let err in this.errors.emailList) {
                if (this.errors.emailList[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;
            this.formData.listOfEmailAddress.push(this.formData.emailList)

            this.formData.emailList = {
                email: '',
                password: ''
            }
        },

        editEmailAddressList(data,index){
            this.emailAddressEditIndex = index
            this.behaviorsSuccess = false
            this.formData.emailList = { email:data.email, password:data.password}
            this.emailAddressEdit = true
            this.$forceUpdate()
        },
        addPassword(){
            if(  this.formData.emailAddressList == undefined)this.formData.emailAddressList = []

            // if(  this.item.rules == undefined)this.item.rules = []
            // if(  this.formData.rules == undefined)this.formData.rules = []
            if (!this.formData.emailList.email) this.errors.emailList.email = "Please enter a valid email";
            else this.errors.emailList.email = null;
            if (!this.formData.emailList.password) this.errors.emailList.password = "Please add password";
            else this.errors.emailList.password = null;

            let error = false;
            for (let err in this.errors.emailList) {
                if (this.errors.emailList[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;

            this.formData.emailAddressList.push(this.formData.emailList)
            this.formData.emailList = { email:'', password:''},
            this.behaviorsSuccessMessage = 'Email Address and Password added successfully!'
            this.behaviorsSuccess = true

        },
        editPassword(){
            // if(  this.formData.emailAddressList == undefined)this.formData.emailAddressList = []

            // if(  this.item.rules == undefined)this.item.rules = []
            // if(  this.formData.rules == undefined)this.formData.rules = []
            if (!this.formData.emailList.email) this.errors.emailList.email = "Please enter a valid email";
            else this.errors.emailList.email = null;
            if (!this.formData.emailList.password) this.errors.emailList.password = "Please add password";
            else this.errors.emailList.password = null;

            let error = false;
            for (let err in this.errors.emailList) {
                if (this.errors.emailList[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;

            // this.formData.emailAddressList[1] = this.formData.emailList
            this.$set(this.formData.emailAddressList, this.emailAddressEditIndex, this.formData.emailList);
            this.$forceUpdate()
            this.formData.emailList = { email:'', password:''},
                this.behaviorsSuccessMessage = 'Email Address and Password Updated successfully!'
            this.behaviorsSuccess = true


        },


        addRule() {
            if(  this.formData.rules == undefined)this.formData.rules = []
            if(this.formData.rule.behaviors != null)this.formData.rule.junkEmailRemovalEnabled = this.formData.rule.behaviors.includes('junk')? 'Yes':'No'
            this.formData.rule.status = this.formData.status
            this.formData.rule.campaign_name = this.formData.campaign_name

            // if(  this.item.rules == undefined)this.item.rules = []
            // if(  this.formData.rules == undefined)this.formData.rules = []
            if (!this.formData.rule.sender_email) this.errors.rule.sender_email = "Please enter a valid email";
            else this.errors.rule.sender_email = null;
            if (!this.formData.rule.behaviors) this.errors.rule.behaviors = "Please add at least 1 behaviour";
            else this.errors.rule.behaviors = null;

            let error = false;
            for (let err in this.errors.rule) {
                if (this.errors.rule[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;



            this.formData.rules.push(this.formData.rule)
            this.formData.rule = {
                sender_email: '',
                behaviors: []
            }
            this.behaviorsSuccessMessage = 'Email added succesfully!'
            this.behaviorsSuccess = true
        },
        addJunk() {
            if(this.formData.junks == undefined) this.formData.junks = []

            if (!this.formData.junk.sender_email) this.errors.junk.sender_email = "Please enter a valid email";
            else this.errors.junk.sender_email = null;


            let error = false;
            for (let err in this.errors.junk) {
                if (this.errors.junk[err]) {
                    error = true
                    break;
                }
            }

            if (error) return;

            this.formData.junk.junkSelect = false
            this.formData.junks.push(this.formData.junk)
            this.formData.junk = {
                sender_email: ''
            }
            this.behaviorsSuccessMessage = 'Email added succesfully!'
            this.behaviorsSuccess = true
        },
        addReply() {

            if(  this.formData.replys == undefined) this.formData.replys = []
            if (!this.reply.sender_email) this.errors.reply.sender_email = "Please enter a valid email";
            else this.errors.reply.sender_email = null;
            let error = false;
            for (let err in this.errors.reply) {
                if (this.errors.reply[err]) {
                    error = true
                    break;
                }
            }
            if (error) return;

            this.formData.replys.push(this.reply)
            this.reply = {
                sender_email: '',
                behaviors: []
            }

            this.behaviorsSuccess = true
        },
        removeReply(index){
            this.formData.replys.splice(index, 1)
            this.$forceUpdate()
        },
        removeJunk(index){
            this.formData.junks.splice(index, 1)
            this.$forceUpdate()
        },
        removeRule(item){
            // this.formData.rules.splice(index, 1)
            this.formData.rules = this.formData.rules.filter(rules => rules.sender_email !== item.sender_email);
            this.$forceUpdate()
        },
        removeForwarding(item) {
            this.formData.forwardings = this.formData.forwardings.filter(rules => rules.sender_email !== item.sender_email);
            // this.formData.forwardings.splice(index, 1)
            this.$forceUpdate()
        },
        removeEmailList(index) {
            this.formData.listOfEmailAddress.splice(index, 1)
            this.$forceUpdate()
        }
    }
}
</script>

<style>
.alert {
    position: relative;
    padding: 0 !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 0.25rem;
}
.vue-step-wizard {
    background-color: #f7f8fc !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: auto !important;
    padding: 21px !important;
}

@media (min-width: 576px) {
    .vue-step-wizard {
        padding: 15px !important;
    }
}

@media (min-width: 768px) {
    .vue-step-wizard {
        padding: 20px !important;
    }
}

@media (min-width: 992px) {
    .vue-step-wizard {
        padding: 25px !important;
    }
}

@media (min-width: 1200px) {
    .vue-step-wizard {
        padding: 30px !important;
    }
}
</style>

