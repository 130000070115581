<template>
    <div>
        <!-- The Modal update -->
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Campaigns
        </h2>
        <div style="background-color:white;position: relative;">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>
            <div style="padding:2%;">
                <div class="row listing" v-if="!editor && !showReport">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-9"  v-show="!selectedCamp.length">
                                <div class="row">
                                    <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                v-model="filter.search"
                                                class="form-control"
                                                placeholder="Keyword Search"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                        <div class="form-group">
                                            <select
                                                v-model="filter.client"
                                                @change="clientChange($event)"
                                                class="form-control text-capitalize required"
                                                required
                                            >
                                                <option value="" selected>-- Any Client --</option>
                                                <option
                                                    v-for="item in client"
                                                    :key="item.value"
                                                    :value="item.value"
                                                >
                                                    {{ item.text }}
                                                </option>
                                            </select>
                                            <div class="form-text text-muted">Select client</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                        <div class="form-group">
                                            <select
                                                v-model="filter.product"
                                                @change="loadPlans($event)"
                                                class="form-control text-capitalize required"
                                            >
                                                <option value="">-- Product --</option>
                                                <option
                                                    v-for="item in products"
                                                    :key="item.key"
                                                    :value="item.key"
                                                >
                                                    {{ item.value }}
                                                </option>
                                            </select>
                                            <div class="form-text text-muted">Select product</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                        <div class="form-group">
                                            <select v-model="filter.status" class="form-control">
                                                <option value="" selected>-- Any Status --</option>
                                                <option value="active">Active</option>
                                                <option value="proxy">Proxy</option>
                                                <option value="error">Error</option>
                                                <option value="pending">Pending</option>
                                                <option value="disabled">Disabled</option>
                                                <option value="complete">Completed</option>
                                                <option value="duplicate">Duplicate</option>
                                                <option value="unconfigured">Unconfigured</option>
                                            </select>
                                            <div class="form-text text-muted">Select campaign status</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                        <div class="form-group">
                                            <ejs-daterangepicker placeholder="End Date" cssClass="e-outline" v-model="filter.end_date" ></ejs-daterangepicker>
                                            <div class="form-text text-muted">Select campaign end date</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2 text-center" v-show="!selectedCamp.length">
                                        <div class="form-group" v-if="!advanceFilter">
                                            <button
                                                type="button"
                                                class="btn btn-primary text-white mr-1"
                                                @click.prevent="toggleAdvanceFilter"
                                            >
                                                Show Filters
                                            </button>
                                        </div>
                                        <div class="form-group" v-else>
                                            <button
                                                type="button"
                                                class="btn btn-primary text-white mr-1"
                                                @click.prevent="toggleAdvanceFilter"
                                            >
                                                Hide Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-9"  v-show="selectedCamp.length">
                                <div class="row">
                                    <div class="col-sm-4 col-md-3 col-lg-2" >
                                        <div class="form-group">
                                            <select v-model="action.status" class="form-control">
                                                <option value="" selected>-- Select Status --</option>
                                                <option value="active">Active</option>
                                                <option value="disabled">Disabled</option>
                                                <option value="proxy">Proxy</option>
                                                <option value="pending">Pending</option>
                                                <option value="complete">Complete</option>
                                                <option value="error">Error</option>
                                            </select>
                                            <div class="form-text text-muted">Set campaign status</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" >
                                        <div class="form-group">
                                            <select v-model="action.mobile" class="form-control">
                                                <option value="" selected>-- Is Mobile --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div class="form-text text-muted">Set campaign device type</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" >
                                        <div class="form-group">
                                            <select
                                                v-model="action.method"
                                                class="form-control text-capitalize"
                                            >
                                                <option value="" selected>-- Select a method --</option>
                                                <option value="any">Any</option>
                                                <option value="worker">Worker</option>
                                                <option value="crawl">Crawl</option>
                                            </select>
                                            <div class="form-text text-muted">Set campaign execute method</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-3 col-lg-2" >
                                        <div class="form-group">
                                            <input
                                                placeholder="Threshold"
                                                type="number"
                                                step="0.1"
                                                v-model="action.threshold"
                                                class="form-control"
                                            />
                                            <div class="form-text text-muted">Set campaign threshold</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-3">
                                <button
                                    v-show="selectedCamp.length"
                                    type="button"
                                    class="btn btn-success mr-1"
                                    @click="bulk"
                                >
                                    <i class="fa fa-save"></i>
                                    Bulk Submit
                                </button>
                                <button
                                    v-show="selectedCamp.length"
                                    type="button"
                                    class="btn btn-warning mr-1"
                                    @click="simulate"
                                >
                                    <i class="fa fa-clock-o"></i>
                                    Simulate
                                </button>
                                <button
                                    v-show="!selectedCamp.length"
                                    type="button"
                                    class="btn text-white mr-1"
                                    style="background-color: #383838"
                                    @click="searchN"
                                >
                                    <i class="fa fa-search" title="Search"></i>
                                    <span>Search Campaign</span>
                                </button>
                                <button
                                    v-show="!selectedCamp.length"
                                    type="button"
                                    class="btn text-white mr-1"
                                    style="background-color: #31e85b"
                                    @click="create"
                                >
                                    <i class="fa fa-plus" title="New Campaign"></i> New Campaign
                                </button>

                            </div>
                            <hr/>
                            <div class="row col-12" v-if="advanceFilter">
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.client_product_id"
                                            class="form-control text-capitalize required"
                                            required
                                        >
                                            <option value="">-- Client Product --</option>
                                            <option
                                                v-for="item in client_product"
                                                :key="item.value"
                                                :value="item.value"
                                            >
                                                {{ item.text }}
                                            </option>
                                        </select>
                                        <div class="form-text text-muted">Select client product</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.type"
                                            class="form-control text-capitalize mr-1"
                                        >
                                            <option value="" selected>-- Select a type --</option>
                                            <option
                                                v-for="item in types"
                                                :key="item.key"
                                                :value="item.key"
                                            >
                                                {{ item.value }}
                                            </option>
                                        </select>
                                        <div class="form-text text-muted">Select campaign type</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.setGeo"
                                            class="form-control text-capitalize mr-1"
                                        >
                                            <option value="" selected>-- Select SetGeo --</option>
                                            <option value="yes" >Yes</option>
                                            <option value="no" >No</option>
                                        </select>
                                        <div class="form-text text-muted">Select campaign set geo</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.video"
                                            class="form-control text-capitalize mr-1"
                                        >
                                            <option value="" selected>-- Select Video --</option>
                                            <option value="yes" >Yes</option>
                                            <option value="no" >No</option>
                                        </select>
                                        <div class="form-text text-muted">Select campaign video</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.country"
                                            @change="loadstates"
                                            class="form-control"
                                        >
                                            <option value="" selected>-- Any Country --</option>
                                            <option
                                                v-for="country in countries"
                                                :key="country._id"
                                                :value="country.code"
                                            >
                                                {{ country.name }}
                                            </option>
                                        </select>
                                        <div class="form-text text-muted">Select country</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length" v-if="filter.country">
                                    <div class="form-group">
                                        <select
                                            v-model="filter.state"
                                            @change="loadcities"
                                            class="form-control"
                                        >
                                            <option value="" selected>-- Any State --</option>
                                            <option v-for="state in states" :key="state" :value="state">
                                                {{ state }}
                                            </option>
                                        </select>
                                        <div class="form-text text-muted">Select state/province</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length" v-if="filter.state">
                                    <div class="form-group">
                                        <select v-model="filter.city" class="form-control">
                                            <option value="" selected>-- Any City --</option>
                                            <option
                                                v-for="city in cities"
                                                :key="city._id"
                                                :value="city.city_name"
                                            >
                                                {{ city.city_name }}
                                            </option>
                                        </select>
                                        <div class="form-text text-muted">Select city</div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2" v-show="!selectedCamp.length" >
                                    <div class="form-group">
                                        <select v-model="filter.touch" class="form-control">
                                            <option value="" selected>-- Sort --</option>
                                            <option value="asc">Ascending</option>
                                            <option value="desc">Descending</option>
                                        </select>
                                        <div class="form-text text-muted">Select sorting</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 justify-content-center">
                                <table v-if="currentView == 'adverifier'" class="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <td><input v-model="selectAll" type="checkbox" /></td>
                                        <td>Campaign ID</td>
                                        <td>
                                            Name<a
                                            @click="sortSearch"
                                            style="margin-left: 3px; cursor: pointer"
                                        ><i :class="sortClass"></i
                                        ></a>
                                        </td>
                                        <td>Type</td>
                                        <td>Domain</td>
                                        <td>Client</td>
                                        <td>Total Clicks Delivered</td>
                                        <td>Daily/Target</td>
                                        <td>Mobile</td>
                                        <td>Status</td>
                                        <td>Touched Date</td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="data in items.data" v-bind:key="data._id">
                                        <td>
                                            <input
                                                v-model="selectedCamp"
                                                :value="data._id"
                                                type="checkbox"
                                                class="dcheck"
                                            />
                                        </td>
                                        <td>{{ data._id }}</td>
                                        <td>{{ data.name }}</td>
                                        <td>{{ data.type }}</td>
                                        <td>{{ (data.domain || "").substr(0, 100) }}</td>
                                        <td>{{ data.client }}</td>
                                        <td>{{ data.delivered? data.delivered.count : 0 }}</td>
                                        <td>
                                            {{ data.clicksdaily ? data.clicksdaily : 0 }} /
                                            {{ data.target || "Unlimited" }}
                                        </td>
                                        <td>{{ data.mobile || "N/A" }}</td>
                                        <td>{{ data.status }}</td>
                                        <td>{{ data.lastTouchDateFormatted }}</td>
                                        <td>
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-primary">
                                                    Actions
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary dropdown-toggle dropdown-icon"
                                                    data-toggle="dropdown"
                                                >
                                                    <span class="sr-only">Toggle Dropdown</span>
                                                    <div class="dropdown-menu" role="menu">
                                                        <a
                                                            class="dropdown-item"
                                                            @click="
                                                            $router.push({
                                                            path: '/campaigns/csvdata',
                                                            query: { campaign: data.csvData },
                                                            params: { campaign: data.csvData },
                                                            })
                                                        "
                                                        ><span class="fa fa-eye" title="View CSV"></span>
                                                            View CSV</a
                                                        >
                                                        <a
                                                            class="dropdown-item"
                                                            @click="
                                                            $router.push({
                                                            path: '/campaignview',
                                                            query: { campaign: data._id },
                                                            params: { campaign: data._id },
                                                            })
                                                        "
                                                        ><span class="fa fa-eye" title="View"></span>
                                                            Details</a
                                                        >
                                                        <a class="dropdown-item" @click="edit(data)"
                                                        ><span class="fa fa-pencil" title="edit"></span>
                                                            Edit</a
                                                        >
                                                        <router-link
                                                            class="dropdown-item"
                                                            :to="`/campaign/${data._id}`"
                                                        ><span class="fa fa-copy" title="Copy"></span>
                                                            Copy</router-link
                                                        >
                                                        <div class="dropdown-divider"></div>
                                                        <a
                                                            class="dropdown-item"
                                                            @click="resetItemModal(data)"
                                                            v-if="data.status === 'proxy'"
                                                        ><span class="fa fa-refresh" title="Reload"></span>
                                                            Reset</a
                                                        >
                                                        <a class="dropdown-item" @click="selectItem(data)"
                                                        ><span class="fa fa-signal" title="Reports"></span>
                                                            Reports</a
                                                        >
                                                        <a class="dropdown-item" @click="simulateOne(data)"
                                                        ><span class="fa fa-clock-o" title="Simulate"></span>
                                                            Simulate</a
                                                        >
                                                        <div class="dropdown-divider"></div>
                                                        <div>
                                                            <a
                                                                v-if="data.status == 'active'"
                                                                class="dropdown-item"
                                                                @click="setStatus(data)"
                                                            >
                                                                <span class="fa fa-archive" title="View"></span>
                                                                Disable</a
                                                            >
                                                            <a
                                                                v-else
                                                                class="dropdown-item"
                                                                @click="setStatus(data)"
                                                            >
                                                                <span class="fa fa-check" title="View"></span>
                                                                Activate</a
                                                            >
                                                        </div>
                                                        <a class="dropdown-item" @click="remove(data)"
                                                        ><span class="fa fa-trash" title="Delete"></span>
                                                            Delete</a
                                                        >
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="btn-group icons"></div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div v-if="currentView == 'adverifier'" class="row">
                                    <div class="col-12 col-sm-9">
                                        <v-pagination
                                            v-model="page"
                                            :pageCount="Math.ceil(items.total/max)"
                                            :classes="bootstrapPaginationClasses"
                                            :labels="customLabels"
                                            @input="search"></v-pagination>
                                    </div>
                                    <div class="col-12 col-sm-3 text-right">
                                        <span>{{currentCount}}</span> of <span>{{items.total}}</span>
                                    </div>
                                </div>

<!--                                inbox Enhanced-->
                                <table v-if="currentView == 'inboxEnhanced'" class="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        <td><input v-model="selectAll" type="checkbox" /></td>
                                        <td>Client Name</td>
                                        <td>Campaign Name</td>
<!--                                        <td>-->
<!--                                            Name<a-->
<!--                                            @click="sortSearch"-->
<!--                                            style="margin-left: 3px; cursor: pointer"-->
<!--                                        ><i :class="sortClass"></i-->
<!--                                        ></a>-->
<!--                                        </td>-->
                                        <td>Status</td>
                                        <td>Time Zone</td>
                                        <td>Start Date</td>
                                        <td>End Date</td>
                                        <td>Last Run</td>

                                        <td>Log In Frequency</td>
                                        <td>Session Time</td>
                                        <td>Junk Email Removal</td>


                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="data in items.data" v-bind:key="data._id">

                                        <td>
                                            <input
                                                v-model="selectedCamp"
                                                :value="data._id"
                                                type="checkbox"
                                                class="dcheck"
                                            />
                                        </td>
                                        <td>{{ data.client }}</td>
                                        <td>{{ data.campaign_name }}</td>
                                        <td>{{ data.status }}</td>
                                        <td>{{data.timezone}}</td>
                                        <td>{{ data.from }}</td>
                                        <td>{{ data.to}}</td>
<!--                                        <td>{{ data.mobile || "N/A" }}</td>-->
<!--                                        <td>{{ data.status }}</td>-->
                                        <td>{{ data.lastLogin || "N/A" }}</td>

                                        <td>{{ data.loginFrequency}}</td>
                                        <td>{{ data.time}}</td>
                                        <td>{{ data.junkActive }}</td>



                                        <td>
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-primary">
                                                    Actions
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary dropdown-toggle dropdown-icon"
                                                    data-toggle="dropdown"
                                                >
                                                    <span class="sr-only">Toggle Dropdown</span>
                                                    <div class="dropdown-menu" role="menu">

                                                        <a
                                                            class="dropdown-item"
                                                            id="show-btn" @click="campaignListShow(data)"
                                                        ><span class="fa fa-eye" title="View"></span>
                                                            Display
                                                        </a>
                                                        <a class="dropdown-item" @click="editInboxEnhance(data)"
                                                        ><span class="fa fa-pencil" title="edit"></span>
                                                            Edit</a
                                                        >
<!--                                                        <a-->
<!--                                                            class="dropdown-item"-->
<!--                                                            @click="disable(data)"-->
<!--                                                        ><span class="fa fa-times" title="Reload"></span>-->
<!--                                                            Disable</a-->
<!--                                                        >-->
<!--                                                        <a-->
<!--                                                            class="dropdown-item"-->
<!--                                                            @click="enable(data)"-->
<!--                                                        ><span class="fa fa-check" title="Reload"></span>-->
<!--                                                            Enable</a-->
<!--                                                        >-->
                                                        <a
                                                            v-if="data.status == 'active'"
                                                            class="dropdown-item"
                                                            @click="setStatus(data)"
                                                        >
                                                            <span class="fa fa-archive" title="View"></span>
                                                            Disable</a
                                                        >
                                                        <a
                                                            v-else
                                                            class="dropdown-item"
                                                            @click="setStatus(data)"
                                                        >
                                                            <span class="fa fa-check" title="View"></span>
                                                            Activate</a
                                                        >
<!--                                                        <a class="dropdown-item" @click="selectItem(data)"-->
<!--                                                        ><span class="fa fa-signal" title="Reports"></span>-->
<!--                                                            Change Log</a-->
<!--                                                        >-->
<!--                                                        <router-link-->
<!--                                                            class="dropdown-item"-->
<!--                                                            :to="`/campaign/${data._id}`"-->
<!--                                                        ><span class="fa fa-copy" title="Copy"></span>-->
<!--                                                            Go to Dashboard</router-link-->
<!--                                                        >-->


                                                    </div>
                                                </button>
                                            </div>
                                            <div class="btn-group icons"></div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div v-if="currentView == 'inboxEnhanced'" class="row">
                                    <div class="col-12 col-sm-9">
                                        <v-pagination
                                            v-model="page"
                                            :pageCount="Math.ceil(items.total/max)"
                                            :classes="bootstrapPaginationClasses"
                                            :labels="customLabels"
                                            @input="search"></v-pagination>
                                    </div>
                                    <div class="col-12 col-sm-3 text-right">
                                        <span>{{currentCount}}</span> of <span>{{items.total}}</span>
                                    </div>
                                </div>

                                <ECamp
                                    v-if="currentView == 'emailverifier'"
                                    :data="items"
                                    :item="itemView"
                                    @paginate="paginate"
                                    @edit="edit"
                                    @link="link"
                                    @refresh="search"
                                    :page="page"
                                    @hideLoader="hideLoader"
                                    :user="user"
                                    :provider="provider"
                                />

                                <LinkModal :item="linkItem" />

                                <EditModal
                                    :item="item"
                                    :clientChange="clientChange"
                                    :loadPlans="loadPlans"
                                    :ForcesUpdateComponent="ForcesUpdateComponent"
                                    :Getstates="Getstates"
                                    :flowChange="flowChange"
                                    :Getcities="Getcities"
                                    :maxChange="maxChange"
                                    :strictChange="strictChange"

                                    :busy="busy"
                                    :geos="geos"
                                    :geoL="geoL"
                                    :busyer="busyer"
                                    :csvStream="csvStream"
                                    :csvPanel="csvPanel"
                                    :csvPanelLoader="csvPanelLoader"
                                    :tos="tos"
                                    :campaignId="campaignId"
                                    :linkLevelTos="linkLevelTos"
                                    :advance="advance"
                                    :client="client"
                                    :clients="clients"
                                    :products="products"
                                    :timezones="timezones"
                                    :countries="countries"
                                    :behavior="behavior"
                                    :waituntil="waituntil"
                                    :provider="provider"
                                    :states="states"
                                    :strict="strict"
                                    :cities="cities"
                                    :itemInfo="itemInfo"
                                    :fields="fields"

                                    :countDownChanged="countDownChanged"
                                    :getCampaignData="getCampaignData"
                                    :toggleAdvance="toggleAdvance"
                                    :cancel="cancel"
                                    :tosNotify="tosNotify"
                                    :save="save"
                                    :saveActivate="saveActivate"
                                />

                            </div>
                        </div>


                        <b-modal id="bv-campaign-example" ref="bvCampaignExample" size="xl" hide-footer>
                            <template #modal-title>
                                <code>Campaign Details</code>
                            </template>
                            <div class="d-block text-center">
                                <campaignList :campaign="campaignListData"> </campaignList>
                            </div>
                        </b-modal>

                        <!-- <br>
            <div class="row">
              <div class="col-12">
                <div v-show="!selectedCamp.length" class="form-inline">
                  <div class="form-group mr-1">
                    <input
                      type="text"
                      v-model="filter.search"
                      class="form-control"
                      placeholder="Keyword Search"
                    />
                  </div>
                  <div class="form-group mr-1">
                    <select v-model="filter.client" class="form-control">
                        <option value="" selected>&#45;&#45; Any Client &#45;&#45;</option>
                        <option v-for="client in clients" :key="client" :value="client">{{client}}</option>
                    </select>
                    <select
                      v-model="filter.client"
                      @change="clientChange($event)"
                      class="form-control text-capitalize required"
                      required
                    >
                      <option value="" selected>-- Any Client --</option>
                      <option
                        v-for="item in client"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mr-1">
                    <select
                      v-model="filter.product"
                      @change="loadPlans($event)"
                      class="form-control text-capitalize required"
                    >
                      <option value="">-- Product --</option>
                      <option
                        v-for="item in products"
                        :key="item.key"
                        :value="item.key"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mr-1">
                    <select v-model="filter.product"  @change="loadPlans($event)" class="form-control text-capitalize required">
                        <option value="">&#45;&#45; Product &#45;&#45;</option>
                        <option v-for="item in products" :key="item.key" :value="item.key">{{ item.value }}</option>
                    </select>
                    <select
                      v-model="filter.client_product_id"
                      class="form-control text-capitalize required"
                      required
                    >
                      <option value="">-- Client Product --</option>
                      <option
                        v-for="item in client_product"
                        :key="item.value"
                        :value="item.value"
                      >
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mr-1">
                    <select v-model="filter.status" class="form-control">
                      <option value="" selected>-- Any Status --</option>
                      <option value="active">Active</option>
                      <option value="proxy">Proxy</option>
                      <option value="pending">Pending</option>
                      <option value="duplicate">Duplicate</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div>
                  <div class="form-group">
                      <select v-model="filter.method" class="form-control text-capitalize mr-1">
                          <option value="" selected>&#45;&#45; Select a method &#45;&#45;</option>
                          <option value="any">Any</option>
                          <option value="worker">Worker</option>
                          <option value="crawl">Crawl</option>
                      </select>
                  </div>
                  <div class="form-group">
                    <select
                      v-model="filter.type"
                      class="form-control text-capitalize mr-1"
                    >
                      <option value="" selected>-- Select a type --</option>
                      <option
                        v-for="item in types"
                        :key="item.key"
                        :value="item.key"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mr-1">
                    <select
                      v-model="filter.country"
                      @change="loadstates"
                      class="form-control"
                    >
                      <option value="" selected>-- Any Country --</option>
                      <option
                        v-for="country in countries"
                        :key="country._id"
                        :value="country.code"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mr-1" v-if="filter.country">
                    <select
                      v-model="filter.state"
                      @change="loadcities"
                      class="form-control"
                    >
                      <option value="" selected>-- Any State --</option>
                      <option v-for="state in states" :key="state" :value="state">
                        {{ state }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mr-1" v-if="filter.state">
                    <select v-model="filter.city" class="form-control">
                      <option value="" selected>-- Any City --</option>
                      <option
                        v-for="city in cities"
                        :key="city._id"
                        :value="city.city_name"
                      >
                        {{ city.city_name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mr-1">
                    <select v-model="filter.touch" class="form-control">
                      <option value="" selected>-- Touch Date --</option>
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    class="btn text-white mr-1"
                    style="background-color: #383838"
                    @click="search"
                  >
                    <i class="fa fa-search" title="Search"></i>
                    <span>Search Campaign</span>
                  </button>
                  <button
                    type="button"
                    class="btn text-white mr-5"
                    style="background-color: #31e85b"
                    @click="create"
                  >
                    <i class="fa fa-plus" title="New Campaign"></i> New Campaign
                  </button>
                </div>
                <div class="form-inline">
                  <div v-show="selectedCamp.length" class="form-group mr-1">
                    <select v-model="action.status" class="form-control">
                      <option value="" selected>-- Select Status --</option>
                      <option value="active">Active</option>
                      <option value="disabled">Disabled</option>
                      <option value="proxy">Proxy</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>

                  <div v-show="selectedCamp.length" class="form-group mr-1">
                    <select v-model="action.mobile" class="form-control">
                      <option value="" selected>-- Is Mobile --</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div v-show="selectedCamp.length" class="form-group">
                    <select
                      v-model="action.method"
                      class="form-control text-capitalize"
                    >
                      <option value="" selected>-- Select a method --</option>
                      <option value="any">Any</option>
                      <option value="worker">Worker</option>
                      <option value="crawl">Crawl</option>
                    </select>
                  </div>

                  <div v-show="selectedCamp.length" class="form-group mr-1">
                    <input
                      placeholder="Threshold"
                      type="number"
                      step="0.1"
                      v-model="action.threshold"
                      class="form-control"
                    />
                  </div>

                  <button
                    v-show="selectedCamp.length"
                    type="button"
                    class="btn btn-success mr-1"
                    @click="bulk"
                  >
                    <i class="fa fa-save"></i>
                    Bulk Submit
                  </button>
                  <button
                    v-show="selectedCamp.length"
                    type="button"
                    class="btn btn-warning mr-1"
                    @click="simulate"
                  >
                    <i class="fa fa-clock-o"></i>
                    Simulate
                  </button>
                </div>
              </div>
            </div> -->

                        <!-- <div class="listing pt-3">
              <table class="table table-hover table-responsive table-striped">
                <thead>
                  <tr>
                    <td><input v-model="selectAll" type="checkbox" /></td>
                    <td>Campaign ID</td>
                    <td>
                      Name<a
                        @click="sortSearch"
                        style="margin-left: 3px; cursor: pointer"
                        ><i :class="sortClass"></i
                      ></a>
                    </td>
                    <td>Type</td>
                    <td>Domain</td>
                    <td>Client</td>
                    <td>Total Clicks Delivered</td>
                    <td>Daily/Target</td>
                                               <td>Threshold</td>
                    <td>Mobile</td>
                    <td>Status</td>
                    <td>Touched Date</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in items.data" v-bind:key="data._id">
                    <td>
                      <input
                        v-model="selectedCamp"
                        :value="data._id"
                        type="checkbox"
                        class="dcheck"
                      />
                    </td>
                    <td>{{ data._id }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.type }}</td>
                    <td>{{ (data.domain || "").substr(0, 100) }}</td>
                    <td>{{ data.client }}</td>
                    <td>{{ data.delivered? data.delivered.count : 0 }}</td>
                    <td>
                      {{ data.clicksdaily ? data.clicksdaily : 0 }} /
                      {{ data.target || "Unlimited" }}
                    </td>
                                               <td v-if="data.hourly_thresholds && data.hourly_thresholds[new Date().getHours()]">{{data.hourly_thresholds[new Date().getHours()]}}</td>
                                <td v-else>{{data.threshold||'N/A'}}</td>
                    <td>{{ data.mobile || "N/A" }}</td>
                    <td>{{ data.status }}</td>
                    <td>{{ data.lastTouchDateFormatted }}</td>
                    <td>
                      <div class="btn-group">
                        <button type="button" class="btn btn-primary">
                          Actions
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary dropdown-toggle dropdown-icon"
                          data-toggle="dropdown"
                        >
                          <span class="sr-only">Toggle Dropdown</span>
                          <div class="dropdown-menu" role="menu">
                            <a
                              class="dropdown-item"
                              @click="
                                $router.push({
                                  path: '/campaignview',
                                  query: { campaign: data._id },
                                  params: { campaign: data._id },
                                })
                              "
                              ><span class="fa fa-eye" title="View"></span>
                              Details</a
                            >
                            <a class="dropdown-item" @click="edit(data)"
                              ><span class="fa fa-pencil" title="edit"></span>
                              Edit</a
                            >
                            <router-link
                              class="dropdown-item"
                              :to="`/campaign/${data._id}`"
                              ><span class="fa fa-copy" title="Copy"></span>
                              Copy</router-link
                            >
                            <b-button v-b-modal.modal-1>Launch demo modal</b-button>
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item"
                              @click="resetItemModal(data)"
                              v-if="data.status === 'proxy'"
                              ><span class="fa fa-refresh" title="Reload"></span>
                              Reset</a
                            >
                            <a class="dropdown-item" @click="selectItem(data)"
                              ><span class="fa fa-signal" title="Reports"></span>
                              Reports</a
                            >
                            <a class="dropdown-item" @click="simulateOne(data)"
                              ><span class="fa fa-clock-o" title="Simulate"></span>
                              Simulate</a
                            >
                            <div class="dropdown-divider"></div>
                            <div>
                              <a
                                v-if="data.status == 'active'"
                                class="dropdown-item"
                                @click="setStatus(data)"
                              >
                                <span class="fa fa-archive" title="View"></span>
                                Disable</a
                              >
                              <a
                                v-else
                                class="dropdown-item"
                                @click="setStatus(data)"
                              >
                                <span class="fa fa-check" title="View"></span>
                                Activate</a
                              >
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 d-flex ">
                            <div v-show="!selectedCamp.length" class="form-inline">
                                <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="search"><i class="fa fa-search" title="Search"></i> Search Campaign</button>
                                <button type="button" class="btn text-white mr-5" style="background-color: #31E85B" @click="create"><i class="fa fa-plus" title="New Campaign"></i> New Campaign</button>
                            </div>
                        </div>
                    </div>

            </div> -->

                    </div>
                </div>

                <!-- section for editor -->
                <div class="row editor" v-if="editor && !showReport">
                    <div class="col-12 col-sm-12">
                        <div class="row">
                            <div class="pb-4 col-4 col-sm-4"><label class="text-danger"><strong class="text-danger">*</strong> Means required in order for a campaign to be successfully generated.</label></div>
                            <div class="pb-4 col-8 col-sm-4 text-center" v-if="this.$route.query.campaign && !newCampaignId"><h3 class="font-weight-bold text-secondary">Campaign Copy</h3> </div>
                            <div class="pb-4 col-8 col-sm-4 text-center" v-else-if="this.$route.query.campaign && newCampaignId"><h3 class="font-weight-bold text-secondary">Edit Copied Campaign</h3> </div>
                        </div>
                        <form  v-if="item.product_type !==  'inboxEnhanced' " class="form" role="form" style="">
                            <!-- section one -->
                            <div class="row col-12">
                                <div class="row col-12"><h5>Product Info</h5><br><hr></div>
                                <div class="row col-12">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label >Client <strong class="text-danger">*</strong></label>
                                            <select v-model="item.client_id" @change="clientChange($event)" class="form-control text-capitalize required" required>
                                                <option v-for="item in client" :key="item.value" :value="item.value">{{ item.text }}</option>
                                            </select>
                                            <div class="form-text text-muted">The affiliated client to which the campaign belongs</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Product <strong class="text-danger">*</strong></label>
                                            <select v-model="item.product_type" @change="loadPlans($event)" class="form-control text-capitalize required" required>
                                                <option v-for="item in products" :key="item.key" :value="item.key">{{ item.value }}</option>
                                            </select>
                                            <div class="form-text text-muted">The affiliated product type for the campaign being created</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label>Flow </label>
                                            <select v-model="item.flow" class="form-control text-capitalize">
                                                <option v-for="wait in flow" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                            </select>
                                            <div class="form-text text-muted">Api is for SI campaigns Queue is everything else</div>
                                        </div>
                                    </div>
                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Plan <strong class="text-danger">*</strong></label>-->
                                    <!--                                        <select v-model="item.client_product_id" class="form-control text-capitalize required" required>-->
                                    <!--                                            <option v-for="item in client_product" :key="item.value" :value="item.value">{{ item.text }}</option>-->
                                    <!--                                        </select>-->
                                    <!--                                        <div class="form-text text-muted">The affiliated plan to which the campaign will belong</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                </div>
                            </div>

                            <div class="row col-12">
                                <div class="row col-12"><h5>Campaign Info</h5><br><hr></div>
                                <div class="row col-12">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Name <strong class="text-danger">*</strong></label>
                                            <input type="text" v-model="item.name" class="form-control required" required/>
                                            <div class="form-text text-muted">The name of the campaign</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">
                                        <b-form-group>
                                            <label>Upload Email Creative <strong class="text-danger">*</strong></label>
                                            <b-input-group>
                                                <b-form-input v-model="item.fileName" :disabled="busy"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button
                                                        ref="button"
                                                        :disabled="busy"
                                                        variant="dark"
                                                        v-b-modal.modalPopover
                                                    >
                                                        Upload
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <div class="form-text text-muted">Allows upload of an email creative with links</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4" v-else-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                        <b-form-group>
                                            <label>Domain <strong class="text-danger">*</strong></label>
                                            <input type="text" v-model="item.domain" class="form-control" />
                                            <div class="form-text text-muted">Link that campaign will target</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                        <b-form-group>
                                            <label>Link Level</label>
                                            <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                            <div class="form-text text-muted">{{ item.flow == 'custom' ? "Minimum" : "Maximum"}} depth of links to follow this campaign.</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="(item.product_type ==  'adverifier' || item.product_type == undefined) && item.flow == 'custom'" >
                                        <b-form-group>
                                            <label>Max Link Level</label>
                                            <input type="number" v-model="item.maxLinkLevel" class="form-control" min="0" value="0"/>
                                            <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3" v-if="item.product_type ==  'adverifier' || item.product_type == undefined">
                                        <b-form-group>
                                            <label>Language</label>
                                            <select v-model="item.lang" class="form-control" >
                                                <option :value="{code: 'en', codes: 'en-US,en'}">English</option>
                                                <option :value="{code: 'es', codes: 'es-US,es'}">Spanish</option>
                                            </select>
                                            <div class="form-text text-muted">Determine the Language to use for this campaign.</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">
                                        <b-form-group >
                                            <label>Above the fold <strong class="text-danger">*</strong></label>
                                            <b-row class="pr-3">
                                                <b-col cols="10">
                                                    <b-input-group class="">
                                                        <b-form-input v-model="item.above" @change="ForcesUpdateComponent" type="range" step=".1" min="0" max="1"></b-form-input>
                                                    </b-input-group>
                                                </b-col>
                                                <b-col cols="2" class="pt-1 border border-success text-center  ">
                                                    <output :value="item.above" class="range-slider-tooltip text-danger">{{ item.above * 100 }}%</output>
                                                </b-col>
                                            </b-row>
                                            <div class="form-text text-muted">Determines the percentage of clicks that should happen above the fold for the total number of clicks.</div>
                                        </b-form-group>
                                    </div>


                                </div>
                                <div class="row col-12">

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">
                                        <div class="form-group">
                                            <label>Mode</label>
                                            <select v-model="item.evMode" class="form-control" >
                                                <option value="normal" selected>Normal</option>
                                                <option value="append" >Append</option>
                                            </select>
                                            <div class="form-text text-muted">Select distribution flow rate</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' && item.evMode ==  'append'">
                                        <b-form-group>
                                            <label>Upload CSV File <strong class="text-danger"></strong></label>
                                            <b-input-group>
                                                <b-form-input v-model="item.csvAppend" :disabled="busyer"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button
                                                        ref="button"
                                                        :disabled="busyer"
                                                        variant="dark"
                                                        v-b-modal.modalEvPopover
                                                    >
                                                        Upload
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <div class="form-text text-muted">Allows upload of csv file</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">
                                        <b-form-group>
                                            <label>Link Level <strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                            <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                                        </b-form-group>
                                    </div>

                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' && item.evMode ==  'append'">
                                        <b-form-group>
                                            <b-button class="btn btn-dark btn-sm mr-1 font-weight-bold" @click="getCampaignData(item)" v-if="csvPanel == false">
                                                <!--                                            <i class="fa fa-search" title="Search" v-if="!csvPanel"></i>-->
                                                <span
                                                    class="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    v-if="csvPanelLoader"
                                                ></span>
                                                Preview CSV</b-button>
                                            <b-button class="btn btn-dark btn-sm mr-1 font-weight-bold" @click="getCampaignData(item)" v-else>Hide Preview</b-button>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row col-12" v-if="csvPanel == true">
                                    <panelcsv :campaign="item" :stream="csvStream" :pid="csvPanel" />
                                </div>
                            </div>

                            <!--                    ends here -->
                            <div class="row col-12">
                                <div class="row col-12"><h5>Schedule Info</h5><br><hr></div>
                                <div class="row col-12">
                                    <div class="col-sm-4 col-md-4 col-lg-2">
                                        <div class="form-group">
                                            <label>Start Date <strong class="text-danger">*</strong></label>
                                            <input
                                                type="date"
                                                v-model="item.startDate"
                                                class="form-control"
                                            />
                                            <div class="form-text text-muted">Start date for the campaign</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-2">
                                        <div class="form-group"><label>Start Time <strong class="text-danger">*</strong></label>
                                            <b-input-group >
                                                <b-form-input
                                                    v-model="item.startTime" v-mask="'##:##'"
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder="00:00"
                                                ></b-form-input>
                                            </b-input-group>
                                            <div class="form-text text-muted">Time of day when the campaign starts</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-2">
                                        <div class="form-group">
                                            <label>End Date <strong class="text-danger">*</strong></label>
                                            <input
                                                type="date"
                                                v-model="item.enddate"
                                                class="form-control"
                                            />
                                            <div class="form-text text-muted">End date for the campaign</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-2">
                                        <div class="form-group">
                                            <label>Stop Time <strong class="text-danger">*</strong></label>
                                            <b-input-group >
                                                <b-form-input
                                                    v-model="item.endTime"
                                                    v-mask="'##:##'"
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder="00:00"
                                                ></b-form-input>
                                            </b-input-group>
                                            <div class="form-text text-muted">Time of day when the campaign end</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Time Zone <strong class="text-danger">*</strong></label>
                                            <select v-model="item.timezone" class="form-control text-capitalize">
                                                <option v-for="(timezone, index) in timezones" :value="timezone" :key="index">{{ timezone }}</option>
                                            </select>
                                            <div class="form-text text-muted">Timezone to which campaign belongs. All time based interactions with the campaign by the crawler will be based on the timezone selected</div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <label>Country</label>
                                <select v-model="geos.country" @change="Getstates" class="form-control">
                                    <option value="" selected>-- Any Country --</option>
                                    <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                </select>
                            </div> -->

                            <div class="row col-12">
                                <div class="row col-12"><h5>Click Distribution Info</h5><br><hr></div>
                                <div class="row col-12">
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Target Clicks <strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="item.target" class="form-control" min="0" value="0"/>
                                            <div class="form-text text-muted">Maximum clicks on this campaign. (Set to 0 for unlimited)</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Min Daily Clicks <strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="item.clicksdaily_min" class="form-control" min="0" max="999999999" value="0"/>
                                            <div class="form-text text-muted">Minimum clicks to deliver in a day</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Max Daily Clicks <strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="item.clicksdaily" class="form-control" min="0" max="999999999" value="0"/>
                                            <div class="form-text text-muted">Maximum clicks to deliver in a day</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <b-form-group label="Crawl Threshold">
                                            <input
                                                type="number"
                                                v-model="item.threshold"
                                                class="form-control required"
                                                min="1"
                                                value="10"
                                                required
                                            />
                                            <div class="form-text text-muted">Maximum number of crawl nodes that should run at once. Recommended 5.</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Country<strong class="text-danger">*</strong></label>
                                            <select v-model="geos.country" @change="Getstates" class="form-control">
                                                <option value="" selected>-- Any Country --</option>
                                                <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                            </select>
                                            <div class="form-text text-muted">Country of campaign request.</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Max Proxy Distance</label>
                                            <input type="number" v-model="item.max_distance" class="form-control required" min="1" value="10" required/>
                                            <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' || item.flow == 'custom'">
                                        <div class="form-group">
                                            <label>Flow Rate <strong class="text-danger">*</strong></label>
                                            <select v-model="item.flowRate" class="form-control" @change="flowChange">
                                                <option value="" selected>-- Select Flow --</option>
                                                <option value="slow" >Slow</option>
                                                <option value="fast" >Fast</option>
                                                <option value="normal" >Normal</option>
                                            </select>
                                            <div class="form-text text-muted">Select distribution flow rate</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' || item.flow == 'custom'">
                                        <div class="form-group">
                                            <label>Bounce Rate <strong class="text-danger">*</strong></label>
                                            <input
                                                type="number"
                                                v-model="item.bounce_rate"
                                                class="form-control required"
                                                min="1"
                                                max="100"
                                                required
                                            />
                                            <div class="form-text text-muted">Campaign bounce rate percentage</div>
                                        </div>
                                    </div>


                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Flow Rate: Early</label>-->
                                    <!--                                        <input type="number" v-model="item.flowEarly" class="form-control" min="0"/>-->
                                    <!--                                        <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Flow Rate: Late</label>-->
                                    <!--                                        <input type="number" v-model="item.flowLate" class="form-control" min="0"/>-->
                                    <!--                                        <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Flow Rate: Peak</label>-->
                                    <!--                                        <input type="number" v-model="item.flowPeak" class="form-control" min="0" />-->
                                    <!--                                        <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Flow Rate: Range min</label>-->
                                    <!--                                        <input type="number" v-model="item.flowMin" class="form-control" min="0"/>-->
                                    <!--                                        <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->
                                    <!--                                <div class="col-sm-4 col-md-4 col-lg-4" v-if="item.product_type ==  'emailverifier' ">-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label>Flow Rate: Range max</label>-->
                                    <!--                                        <input type="number" v-model="item.flowMax" class="form-control" min="0"/>-->
                                    <!--                                        <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>-->
                                    <!--                                    </div>-->
                                    <!--                                </div>-->

                                    <div class="form-group col-sm-4 col-md-6 col-lg-12" v-if="item.product_type ==  'emailverifier' || item.flow == 'custom' ">
                                        <diss v-if="item.flowRate" :itemDataset="item" />
                                    </div><!---->
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <label>Max Proxy Distance</label>
                                <input type="number" v-model="item.max_distance" class="form-control required" min="1" value="10" required/>
                                <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                            </div> -->

                            <div class="row col-12">
                                <div class="row col-12 mt-3 mb-3">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary text-white mr-1"
                                        @click.prevent="toggleAdvance()" v-if="!advance"
                                    >
                                        Show More
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary text-white mr-1"
                                        @click.prevent="toggleAdvance()" v-if="advance"
                                    >
                                        Show Less
                                    </button>
                                    <!-- <a @click.prevent="toggleAdvance()" v-if="!advance">Show More</a>
                                    <a @click.prevent="toggleAdvance()" v-if="advance">Show Less</a> -->
                                    <br><hr>
                                </div>
                                <div class="row col-12" v-if="advance==true"><h3>Advance Info</h3><br><hr></div>
                                <div class="row col-12" v-if="advance==true">
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group label="Method ">
                                            <select v-model="item.method" class="form-control text-capitalize">
                                                <option value="any" selected>Any</option>
                                                <option value="worker">Worker</option>
                                                <option value="crawl">Crawl</option>
                                            </select>
                                            <div class="form-text text-muted"></div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' " >
                                        <b-form-group label="Side Load " v-if="item.product_type !==  'emailverifier' " >
                                            <select v-model="item.sideLoad" class="form-control text-capitalize">
                                                <option value="yes" selected>Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div class="form-text text-muted">Yes if crawler should load assets over a separate proxy (or non-proxy) connection</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label>Local Links Only </label>
                                            <select v-model="item.localLinks" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </select>
                                            <div class="form-text text-muted">Yes only allows links from the campaign domain</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                        <b-form-group label="Bounce " v-if="item.product_type !==  'emailverifier' " >
                                            <select v-model="item.bounce" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </select>
                                            <div class="form-text text-muted">Allows the campaign to bounce a modulus of the number of clicks based on Target Clicks</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                        <b-form-group  v-if="item.product_type !==  'emailverifier' ">
                                            <label>Behavior </label>
                                            <select v-model="item.behavior" class="form-control text-capitalize">
                                                <option value="" selected>Select</option>
                                                <option v-for="wait in behavior" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                            </select>
                                            <div class="form-text text-muted">The list of wait times that the system can shuffle through</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                        <b-form-group label="Navigation Timeout">
                                            <input type="number" v-model="item.navigationTimeout" class="form-control" min="30000" placeholder="30000"/>
                                            <div class="form-text text-muted">Maximum amount of time to wait for on-page navigation (in milliseconds)</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group label="Wait Until ">
                                            <select v-model="item.waitUntil" class="form-control text-capitalize">
                                                <option v-for="wait in waituntil" :key="wait.value" :value="wait.value">{{wait.text}}</option>
                                            </select>
                                            <div class="form-text text-muted">When to consider navigation succeeded.</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label>Mobile </label>
                                            <select v-model="item.mobile" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div class="form-text text-muted">Should the page be visited by a mobile device</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                        <b-form-group label="Snoop">
                                            <select v-model="item.snoop" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </select>
                                            <div class="form-text text-muted">Check if IP Address has changed before starting the visit</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <div class="form-group">
                                            <label>Provider</label>
                                            <select v-model="item.provider"  class="form-control text-capitalize required" >
                                                <option v-for="item in provider" :key="item.value" :value="item.value">{{ item.text }}</option>
                                            </select>
                                            <div class="form-text text-muted">The proxy provider that the campaigns proxy should be taken from</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3" v-if="item.product_type !==  'emailverifier' ">
                                        <b-form-group label="Set Geo">
                                            <select v-model="item.setGeo" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </select>
                                            <div class="form-text text-muted">Set the GeoLocation in the browser to the proxy location</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group label="Video">
                                            <select v-model="item.video" class="form-control text-capitalize">
                                                <option value="yes">Yes</option>
                                                <option value="no" selected>No</option>
                                            </select>
                                            <div class="form-text text-muted">Record campaign visits</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group>
                                            <label>Video Fps</label>
                                            <input type="number" v-model="item.videoFps" class="form-control" min="5" max="30" value="0"/>
                                            <div class="form-text text-muted">Maximum frames per second while recoring video. range(5-30)</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group>
                                            <label>Video Quality</label>
                                            <input type="number" v-model="item.videoQuality" class="form-control" min="1" max="100" value="0"/>
                                            <div class="form-text text-muted">Video playback quality. range(1-100)</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group label="Referers ">
                                            <b-form-tags input-id="tags-basic" v-model="item.referersArray" placeholder="Enter Referers here"></b-form-tags>
                                            <div class="form-text text-muted">List of referrer links to use</div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-3">
                                        <b-form-group label="Blacklist">
                                            <b-form-tags input-id="tags-basic" v-model="item.blacklistArray" placeholder="Enter Blacklist here"></b-form-tags>
                                            <div class="form-text text-muted">List of links to avoid</div>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>

                            <div class="row col-12" v-if="item.flow=='custom'">
                                <div class="row col-12"><h5>Click Config</h5><br><hr></div>
                                <div class="row col-12">
                                    <div class="col-sm-2 col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Url <strong class="text-danger">*</strong></label>
                                            <input type="text" v-model="click_config.url" class="form-control" />
                                            <p class="text-danger" v-if="errors.click_config.url">{{ error.click_config.url }}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <div class="form-group">
                                            <label>Click Range Min<strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="click_config.min_range" class="form-control" min="0" value="0"/>
                                            <p class="text-danger" v-if="errors.click_config.min_range">{{ errors.click_config.min_range }}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <div class="form-group">
                                            <label>Click Range Max<strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="click_config.max_range" class="form-control" min="0" value="0"/>
                                            <p class="text-danger" v-if="errors.click_config.max_range">{{ errors.click_config.max_range }}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1" style="align-self: center">
                                        <button class="btn btn-sm btn-info" @click="addConfig()"><i class="fa fa-plus" /></button>
                                    </div>
                                </div>

                                <div class="row col-12" v-for="(config, index) in item.click_config" :key="index">
                                    <div class="col-sm-2 col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Url <strong class="text-danger">*</strong></label>
                                            <input type="text" v-model="config.url" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <div class="form-group">
                                            <label>Click Range Min<strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="config.min_range" class="form-control" min="0" value="0"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1">
                                        <div class="form-group">
                                            <label>Click Range Max<strong class="text-danger">*</strong></label>
                                            <input type="number" v-model="config.max_range" class="form-control" min="0" value="0"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 col-md-1 col-lg-1" style="align-self: center">
                                        <button class="btn btn-sm btn-danger" @click="removeConfig(index)"><i class="fa fa-trash" /></button>
                                    </div>
                                </div>
                            </div>



                        </form    >
                        <emailWarmer v-if="item.product_type ==  'inboxEnhanced' " :editmood="editMood" :item="item" :inboxData="inboxEnhanced" :client = 'client' :products = 'products' :onSave="save" :onLoadPlans="loadPlans" />

                        <!--GOES .....right here -->

                        <div
                            class="row d-flex justify-content-center align-content-center pt-3"
                            v-if="campaignId != '' && item.product_type !==  'inboxEnhanced'"
                        >
                            <!--            Right Section-->
                            <div class="col-12" >
                                <b-card no-body>
                                    <div class="card">
                                        <div class="row col">
                                            <b-form-group class="col mb-0">
                                                <div class="form-text text-muted mt-3">Modification done in this area will automatically saved to the campaign and does not require user to click the save button.</div>
                                            </b-form-group>
                                        </div>
                                        <div>
                                            <div id="Residentialcity">
                                                <div class="row mt-4">
                                                    <div class="col-3 pl-4 pr-4">
                                                        <label>Country <strong class="text-danger">*</strong></label>
                                                        <select
                                                            v-model="geoL.country"
                                                            @change="Getstates()"
                                                            class="form-control"
                                                        >
                                                            <option value="" selected>-- Any country --</option>
                                                            <option
                                                                v-for="country in countries"
                                                                :key="country._id"
                                                                :value="country.code"
                                                            >
                                                                {{ country.name }}
                                                            </option>
                                                        </select>
                                                        <div class="form-text text-muted">Country of campaign request.</div>
                                                    </div>
                                                    <div class="col-2 pl-4 pr-4">
                                                        <label>State</label>
                                                        <select
                                                            v-model="geoL.state"
                                                            @change="Getcities()"
                                                            class="form-control"
                                                        >
                                                            <option value="" selected>-- Any state --</option>
                                                            <option
                                                                v-for="state in states"
                                                                :key="state"
                                                                :value="state"
                                                            >
                                                                {{ state }}
                                                            </option>
                                                        </select>
                                                        <div class="form-text text-muted">State/Provence of campaign request.</div>
                                                    </div>
                                                    <div class="col-2 pl-4 pr-4" v-if="geoL.state">
                                                        <label>City</label>
                                                        <select v-model="geoL.city" class="form-control">
                                                            <option value="" selected>-- Any City --</option>
                                                            <option
                                                                v-for="city in cities"
                                                                :v-if="city.city_name !== null"
                                                                :key="city.city_name"
                                                                :value="city.city_name"
                                                            >
                                                                {{ city.city_name }}
                                                            </option>
                                                        </select>
                                                        <div class="form-text text-muted">City/Town of campaign request.</div>
                                                    </div>
                                                    <div class="col-3 pl-4 pr-4">
                                                        <label>Max Radius <strong class="text-danger">*</strong></label>
                                                        <input
                                                            type="number"
                                                            v-model="geoL.maxRadius"
                                                            class="form-control required"
                                                            min="1"
                                                            value="10"
                                                            required
                                                        />
                                                        <div class="form-text text-muted">Location range of campaign request.</div>
                                                    </div>
                                                    <div class="col-2 pl-4 pr-4">
                                                        <label>Strict <strong class="text-danger">*</strong></label>
                                                        <select v-model="geoL.strict" class="form-control">
                                                            <option
                                                                v-for="stricts in strict"
                                                                :key="stricts.strict"
                                                                :value="stricts.value"
                                                            >
                                                                {{ stricts.text }}
                                                            </option>
                                                        </select>
                                                        <div class="form-text text-muted">{{ itemInfo }}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 pl-4 pr-4 pt-4 mt-1">
                                                        <b-button
                                                            size="md"
                                                            variant="success"
                                                            @click="addGeos(geoL)"
                                                            v-text="'Add Geo'"
                                                        />
                                                    </div>
                                                </div>
                                                <div  class="p-3">
                                                    <b-table
                                                        striped
                                                        :fields="fields"
                                                        ref="selectedRow"
                                                        :items="geo"
                                                    >
                                                        <template v-slot:cell(maxRadius)="row">
                                                            <!-- <div class="row"> -->

                                                            <input
                                                                type="number"
                                                                @change="maxChange($event, row.index)"
                                                                :value="row.item.maxRadius"
                                                                class="form-control required"
                                                                min="1"
                                                                required
                                                            />

                                                            <!-- </div> -->
                                                        </template>
                                                        <template v-slot:cell(strict)="row">
                                                            <!-- <div class="row"> -->
                                                            <div class="col-6">
                                                                <!--                                                        <select  class="form-control" @change="strictChange($event)">-->

                                                                <b-form-select
                                                                    class="form-control"
                                                                    :options="strict"
                                                                    :value="row.item.strict"
                                                                    @change="strictChange($event, row.index)"
                                                                >
                                                                </b-form-select>
                                                                <!--                                                            <option v-for="stricts in strict" :key="stricts.strict" :value="row.item.strict">{{stricts.text }}</option>-->
                                                                <!--                                                        </select>-->
                                                            </div>
                                                        </template>
                                                        <template v-slot:cell(Actions)="row">
                                                            <b-row>
                                                                <b-col sm="2" class="">
                                                                    <b-button
                                                                        @click="editGeos(row.item)"
                                                                        title="Edit Geo"
                                                                        variant="warning "
                                                                        size="sm"
                                                                    ><span class="fa fa-pencil text-white"></span
                                                                    ></b-button>
                                                                </b-col>

                                                                <b-col sm="4" class="">
                                                                    <b-button
                                                                        @click="
                                                                      removeGeos(
                                                                        row.index,
                                                                        row.item.campaign_id,
                                                                        row.item._id
                                                                      )
                                                                    "
                                                                        title="Delete Geo"
                                                                        variant="danger"
                                                                        size="sm"
                                                                    ><span class="fa fa-trash"></span
                                                                    ></b-button>
                                                                </b-col>
                                                            </b-row>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card>
                            </div>
                            <!--            End Right Section-->
                        </div>

                        <!--ends here-->

                        <!-- tos right here -->
                        <b-row v-if="campaignId != '' && item.product_type !==  'inboxEnhanced' ">
                            <div class="col-12 pr-5 pl-4">
                                <div class="row mt-3 mb-2 d-flex justify-content-center">
                                    <b-button
                                        class="mr-2"
                                        variant="info"
                                        size="sm"
                                        @click="tos = true"
                                    >Configure TOS</b-button
                                    >
                                </div>
                            </div>
                            <t-o-s
                                v-if="tos"
                                :linklvlTOS="linkLevelTos"
                                :campaign_id="campaignId"
                                v-on:close="tos = false"
                                v-on:modified="
                                  tos = false;
                                  tosNotify();
                                "
                            ></t-o-s>
                        </b-row>
                        <!--  -->

                        <div class="row pt-4"  v-if="item.product_type !==  'inboxEnhanced' ">
                            <div class="col-12">
                                <div class="form-group text-center">
                                    <button
                                        type="button"
                                        class="btn btn-secondary  mr-2"
                                        @click="cancel"
                                    >
                                        <span class="glyphicon glyphicon-remove"></span> Cancel
                                    </button>
                                    <button type="submit" class="btn btn-success"
                                            @click.prevent="save(false)" v-if="this.$route.query.campaign && !this.newCampaignId">
                                        <span class="glyphicon glyphicon-ok"></span> Save a copy
                                    </button>
                                    <button type="submit" class="btn btn-success ml-2"
                                            @click.prevent="save(true)"
                                            v-else-if="this.newCampaignId && this.$route.query.campaign">
                                        <span class="glyphicon glyphicon-ok"></span> Save changes to copied campaign
                                    </button>
                                    <button type="submit" class="btn btn-success"
                                            @click.prevent="save(true)" v-else>
                                        <span class="glyphicon glyphicon-ok"></span> Save Changes
                                    </button>

                                </div>
                                <div class="pb-4 row text-center" v-if="this.$route.query.campaign && !this.newCampaignId">
                                    <div class=" col-12 col-sm-12 text-secondary">
                                        <label class="text-danger">
                                            Saving changes made to this campaign will create a new copy.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-4"  v-else>
                            <div class="col-12">
                                <div class="form-group text-center">
                                    <button
                                        type="button"
                                        class="btn btn-secondary  mr-2"
                                        @click="cancel"
                                    >
                                        <span class="glyphicon glyphicon-remove"></span> Cancel
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="report" v-if="showReport  ">
                    <div class="row mb-4">
                        <div class="col-8">
                            <h4>
                                Tracking Report:
                                <small class="text-muted">
                                    {{ report.name }}
                                    <button
                                        type="button"
                                        @click="back"
                                        class="btn btn-danger btn-sm rep-btn"
                                    >
                                        <i class="fa fa-close"></i>
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        @click="reports(selected)"
                                        class="btn btn-primary btn-sm rep-btn"
                                    >
                                        <i class="fa fa-refresh"></i>
                                        Refresh
                                    </button>
                                    <button
                                        type="button"
                                        @click="nextReport(selected)"
                                        class="btn btn-success btn-sm"
                                        v-show="nextCampaign"
                                    >
                                        <i class="fa fa-arrow-right"></i>
                                        Next Campaign
                                    </button>
                                </small>
                            </h4>
                        </div>
                        <div class="col-4">
                            <b-input-group>
                                <template v-slot:prepend>
                                    <b-dropdown text="Showing Results For" variant="primary">
                                        <b-dropdown-item @click="setSelectedStat('Today')">
                                            Today
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setSelectedStat('Yesterday')">
                                            Yesterday
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setSelectedStat('This Month')">
                                            This Month
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setSelectedStat('Total')">
                                            Total
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                                <b-form-input v-model="selectedStat"></b-form-input>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="row stats mb-12" v-if="selectedStat === 'Today'">
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('hits', 'today', 'hitsToday')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="success"
                                        v-if="hitsToday === false"
                                    ></b-spinner>
                                    <div v-if="hitsToday === true">
                                        <h4>
                                            {{ Number(report.stats.hits.today.total).toLocaleString() }}
                                            hits
                                        </h4>
                                        <h5>Today</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('misses', 'today', 'missesToday')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="danger"
                                        v-if="missesToday === false"
                                    ></b-spinner>
                                    <div v-if="missesToday === true">
                                        <h4>
                                            {{ Number(report.stats.misses.today.total).toLocaleString() }}
                                            failed
                                        </h4>
                                        <h5>Today</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row stats mb-12" v-if="selectedStat === 'Yesterday'">
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('hits', 'yesterday', 'hitsYesterday')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="success"
                                        v-if="hitsYesterday === false"
                                    ></b-spinner>
                                    <div v-if="hitsYesterday === true">
                                        <h4>
                                            {{
                                                Number(report.stats.hits.yesterday.total).toLocaleString()
                                            }}
                                            hits
                                        </h4>
                                        <h5>Yesterday</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('misses', 'yesterday', 'missesYesterday')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="danger"
                                        v-if="missesYesterday === false"
                                    ></b-spinner>
                                    <div v-if="missesYesterday === true">
                                        <h4>
                                            {{
                                                Number(report.stats.misses.yesterday.total).toLocaleString()
                                            }}
                                            failed
                                        </h4>
                                        <h5>Yesterday</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row stats mb-12" v-if="selectedStat === 'This Month'">
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('hits', 'month', 'hitsMonth')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="success"
                                        v-if="hitsMonth === false"
                                    ></b-spinner>
                                    <div v-if="hitsMonth === true">
                                        <h4>
                                            {{ Number(report.stats.hits.month.total).toLocaleString() }}
                                            hits
                                        </h4>
                                        <h5>This Month</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('misses', 'month', 'missesMonth')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="danger"
                                        v-if="missesMonth === false"
                                    ></b-spinner>
                                    <div v-if="missesMonth === true">
                                        <h4>
                                            {{ Number(report.stats.misses.month.total).toLocaleString() }}
                                            failed
                                        </h4>
                                        <h5>This Month</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row stats mb-12" v-if="selectedStat === 'Total'">
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('hits', 'total', 'hitsTotal')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="success"
                                        v-if="hitsTotal === false"
                                    ></b-spinner>
                                    <div v-if="hitsTotal === true">
                                        <h4>
                                            {{ Number(report.stats.hits.total.total).toLocaleString() }}
                                            hits
                                        </h4>
                                        <h5>Total</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card">
                                <div
                                    class="card-body text-center"
                                    @click="viewChart('misses', 'total', 'missesTotal')"
                                >
                                    <b-spinner
                                        type="grow"
                                        label="Loading..."
                                        variant="danger"
                                        v-if="missesTotal === false"
                                    ></b-spinner>
                                    <div v-if="missesTotal === true">
                                        <h4>
                                            {{ Number(report.stats.misses.total.total).toLocaleString() }}
                                            failed
                                        </h4>
                                        <h5>Total</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row hours mb-4">
                        <div class="col-12">
                            <div class="chart">
                                <line-chart :data="report.chartdata.data" area />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="result" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Hourly limits</h5>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form class="form" role="form">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>12:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[0]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>01:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[1]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>02:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[2]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>03:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[3]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>04:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[4]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>05:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[5]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>06:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[6]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>07:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[7]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>08:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[8]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>09:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[9]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>10:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[10]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>11:00 AM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[11]"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>12:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[12]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>01:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[13]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>02:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[14]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>03:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[15]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>04:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[16]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>05:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[17]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>06:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[18]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>07:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[19]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>08:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[20]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>09:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[21]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>10:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[22]"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label>11:00 PM</label>
                                                <input
                                                    type="number"
                                                    v-model="limits[23]"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Discard
                                </button>
                                <button type="button" class="btn btn-primary" @click="saveLimits">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal ref="my-modal" hide-footer :title="resetTitle">
                    <div class="d-block text-center">
                        <div class="form-group">
                            <label>Please state reason...</label>
                            <b-textarea
                                no-resize
                                rows="4"
                                class="form-control"
                                v-model="note.description"
                                :state="resetDescriptionEmpty"
                                :placeholder="resetPlaceHolder"
                            >
                            </b-textarea>
                        </div>
                    </div>
                    <b-button class="mt-3" variant="primary" block @click="resetItem"
                    >Reset</b-button
                    >
                </b-modal>
                <b-modal ref="simulate-modal" hide-footer :title="simulationTitle">
                    <div class="d-block text-center">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="simulationActive === true">
                                    <b-spinner
                                        type="grow"
                                        label="Running simulation. Please wait..."
                                        variant="primary"
                                    ></b-spinner>
                                    <h3>Running simulation. Please wait...</h3>
                                </div>

                                <div v-if="simulationActive === false">
                                    <template>
                                        <div>
                                            <b-table
                                                :items="simulationData"
                                                :fields="['message']"
                                                :tbody-tr-class="simulationRowClass"
                                            ></b-table>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <b-modal ref="simulate-modal-bulk" hide-footer :title="simulationTitle">
                    <div class="d-block text-center">
                        <div v-if="simulationActive === true">
                            <b-spinner
                                type="grow"
                                label="Running simulation. Please wait..."
                                variant="primary"
                            ></b-spinner>
                            <h3>Running simulation. Please wait...</h3>
                        </div>
                        <div v-if="simulationActive === false">
                            <template>
                                <div class="accordion" role="tablist">
                                    <b-card
                                        no-body
                                        class="mb-1"
                                        v-for="item in simulationData"
                                        :key="item.campaign"
                                    >
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button
                                                block
                                                :v-b-toggle="item.campaign"
                                                variant="primary"
                                            >{{ item.campaign }}</b-button
                                            >
                                        </b-card-header>
                                        <b-collapse :id="item.campaign" visible role="tabpanel">
                                            <b-card-body>
                                                <template>
                                                    <div>
                                                        <b-table
                                                            :items="item.stack"
                                                            :fields="['message']"
                                                            :tbody-tr-class="simulationRowClass"
                                                        ></b-table>
                                                    </div>
                                                </template>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-modal>
                <!--            <campaigncsv :campaign="item" :stream="csvStream" />-->
            </div>

        </div>

        <!-- model for uploading document -->
        <b-modal id="modalPopover" hide-header hide-footer ref="modalPopover">
            <b-container>
                <h6 class="text-center p-2">Upload Creative</h6>
                <p>Select and Upload email file:</p>
                <div id="dropArea" class="pb-2">
                    <input
                        type="file"
                        accept=".html"
                        ref="uploadObj"
                        @change="fileupload"
                    />
                </div>

                <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant="alert.variant"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                >
                    <p class="m-0" v-html="alert.message" />
                </b-alert>
                <b-button
                    class="text-danger float-right mt-3"
                    @click="hideUploadModal"
                    variant="outline-light"
                >Done</b-button
                >
            </b-container>
        </b-modal>
        <!-- end of model for uploading document -->


        <!-- model for uploading document -->
        <b-modal id="modalEvPopover" hide-header hide-footer ref="modalEvPopover">
            <b-container>
                <h6 class="text-center p-2">Upload Csv</h6>
                <p>Select and Upload email file:</p>
                <div id="dropArea" class="pb-2">
                    <input
                        type="file"
                        accept=".csv"
                        ref="uploadObj"
                        @change="fileuploader(true)"
                    />
                </div>

                <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant="alert.variant"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                >
                    <p class="m-0" v-html="alert.message" />
                </b-alert>
                <b-button
                    class="text-danger float-right mt-3"
                    @click="hideEvUploadModal"
                    variant="outline-light"
                >Done</b-button
                >
            </b-container>
        </b-modal>
        <!-- end of model for uploading document -->
    </div>
</template>

<script>
import moment from "moment-timezone";
import papa from "papaparse"
import axios from "axios";
import swal from "sweetalert";
import vPagination from 'vue-plain-pagination'
//todo fix page responsiveness and insert link level
// import Vue from "vue";
// import citySelect from "../components/domain/citySelect";
// import campaignEditor from '@/components/campaigns/campaignEditor';
// import campaigncsv from '@/components/campaigns/singleCsv';
import panelcsv from '@/components/campaigns/panelCsv';
import campaignList from '@/components/campaigns/campaignList';
import { LineChart } from "dr-vue-echarts";
// import Workflow from '../components/Workflow'
import ECamp from '../components/emailVerifier/campaigns'
import TOS from "@/components/campaigns/tos";
import EditModal from '../components/emailVerifier/editModal'
import LinkModal from '../components/emailVerifier/linksModal'

import diss from "@/components/campaigns/distribution";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import emailWarmer from "@/components/campaigns/emailWarmer";

import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);

export default {
    name: "Campaigns",
    props: ["user"],

    components: {
        vPagination,
        ECamp,
        EditModal,
        LinkModal,
        LineChart,
        TOS,
        diss,
        // campaigncsv,
        panelcsv,
        emailWarmer,
        campaignList
        // citySelect
        // 'workflow': Workflow,
        // campaignEditor,
    },
    data: function () {
        return {
            campaignListData:'',
            click_config: {
                url: '',
                min_range: '',
                max_range: ''
            },
            errors: {
                click_config: {
                    url: null,
                    min_range: null,
                    max_range: null
                }
            },
            loading: false,
            linkItem: null,
            busy: null,
            busyer: null,
            csvPanelLoader:false,
            csvPanel:false,
            seen:false,
            advance:false,
            disBtn:false,
            advanceFilter:false,
            itemInfo: "Description to be replaced.",
            timezones: moment.tz.names(),
            clients: [],
            flowRatePropEarly:34,
            flowRatePropLate:28,
            flowRatePropPeak:0,
            flowRatePropMin:8,
            flowRatePropMax:14,
            inboxEnhanced: {},
            item: {
                geo: [],
                click_config: [],
                flowRate:'slow',
                flowEarly:34,
                flowLate:28,
                flowPeak:0,
                flowMin:8,
                flowMax:14,
                timezone: 'UTC',
                above: 0.7,
                limiter:'yes',
                evMode:'append',
            },
            geo: [],
            email: {
                preview: "",
                file: null,
                uploading: false,
            },
            csvStream: [],
            limits: {},
            hour: {},
            itemView: {},
            customerQuery: "",
            action: { mobile: "", status: "", method: "" },
            types: [
                { key: "domain", value: "Domain" },
                { key: "campaign", value: "Campaign" },
                { key: "email", value: "Email" },
            ],
            products: [
                { key: "adverifier", value: "Ad Verifier" },
                // {key: "cc", value:"Content Catcher"},
                { key: "emailverifier", value: "Email Verifier" },
                { key: "inboxEnhanced", value: "Inbox Enhanced" },
            ],
            selectedCamp: [],
            items: { total: 0, data: [] },
            editor: false,
            editMood:false,
            countries: [],
            states: [],
            cities: [],
            selected: {},
            showReport: null,
            currentView: "adverifier",
            originalReport: {
                name: "",
                stats: {
                    hits: {
                        today: {
                            total: 0,
                            report: {},
                        },
                        yesterday: {
                            total: 0,
                            report: {},
                        },
                        month: {
                            total: 0,
                            report: {},
                        },
                        total: {
                            total: 0,
                            report: {},
                        },
                    },
                    misses: {
                        today: {
                            total: 0,
                            report: {},
                        },
                        yesterday: {
                            total: 0,
                            report: {},
                        },
                        month: {
                            total: 0,
                            report: {},
                        },
                        total: {
                            total: 0,
                            report: {},
                        },
                    },
                },
                chartdata: {},
            },
            report: {
                name: "",
                stats: {
                    hits: {
                        today: {
                            total: 0,
                            report: {},
                        },
                        yesterday: {
                            total: 0,
                            report: {},
                        },
                        month: {
                            total: 0,
                            report: {},
                        },
                        total: {
                            total: 0,
                            report: {},
                        },
                    },
                    misses: {
                        today: {
                            total: 0,
                            report: {},
                        },
                        yesterday: {
                            total: 0,
                            report: {},
                        },
                        month: {
                            total: 0,
                            report: {},
                        },
                        total: {
                            total: 0,
                            report: {},
                        },
                    },
                },
                chartdata: {},
            },
            geos: {},
            geoL: {},
            note: {
                description: "",
                user_id: null,
                campaign_id: null,
                created_ts: null,
            },
            from: null,
            resetModal: false,
            resetTitle: "",
            resetSelected: {},
            resetDescriptionEmpty: null,
            resetPlaceHolder: "",
            sortClass: "fa fa-long-arrow-up",
            nextCampaign: true,
            nextCampaignTrigger: false,
            infiniteId: +new Date(),
            linkLevelTos: [],
            filter: {
                name: "",
                client: "",
                proxy: "",
                status: "",
                country: "",
                state: "",
                city: "",
                video: "",
                setGeo: "",
                method: "",
                touch: "",
                type: "",
                search: "",
                end_date: [],
                product: "adverifier",
                client_product_id: "",
            },
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            currentCount:0,
            max: 25,
            page: 0,
            customLabels: {
                first: "First",
                prev: "Previous",
                next: "Next",
                last: "Last",
            },
            hitsToday: false,
            hitsYesterday: false,
            hitsMonth: false,
            hitsTotal: false,
            missesToday: false,
            missesYesterday: false,
            missesMonth: false,
            missesTotal: false,
            selectedStat: "Today",
            simulationTitle: "",
            simulationActive: false,
            simulationData: [],
            searching: false,
            behavior: "",
            provider: "",
            waituntil: "",
            client: "",
            method: "",
            flow: "",
            cityFields: { text: "city_name", value: "city_name" },
            tos: false,
            client_product: "",
            strict: "",
            campaignId: "",
            newCampaignId:false,
            plansid: "",
            geoEdit: {},
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,

            alert: {
                variant: "",
                message: "",
            },
        };
    },
    mounted: function () {
        this.$root.preloader = false;
    },
    created: function () {
        this.load();
        this.loadClients();
        this.loadcountries();
        //called when url is sent from plan management
        if (this.$route.query.cp_id != undefined) {
            this.item.client_id = this.$route.query.client_id;
            this.plansid = this.$route.query.pt;
            this.filter.client_product_id = this.$route.query.cp_id;
            this.filter.client = this.$route.query.client_id;
            this.filter.product = this.$route.query.pt;
            this.loadPlan();
            if (
                this.filter.client_product_id != undefined &&
                this.filter.client != undefined &&
                this.filter.product != undefined
            ) {
                // this.search();
            }
        } else {
            this.search();
        }
        if(this.$route.query.campaign ) this.searchById(this.$route.query.campaign);

        this.behaviors();
        this.getProvider();
        this.waitUntil();
        this.getClient();
        this.methods();
        this.flows();
        this.stricts();


        window.addEventListener("keydown", this.windowListener);
    },
    destroyed: function () {
        window.removeEventListener("keydown", this.windowListener);
    },
    computed: {
        selectAll: {
            get: function () {
                //debugger;
                return this.items.data
                    ? this.selectedCamp.length == this.items.data.length
                    : false;
            },

            set: function (value) {
                var selected = [];

                if (value) {
                    this.items.data.forEach(function (user) {
                        //debugger;
                        selected.push(user._id);
                    });
                }
                //debugger;
                this.selectedCamp = selected;
            },
        },
        fields() {
            return [
                {
                    key: "country",
                    label: "Country",
                    sortable: false,
                },
                {
                    key: "state",
                    label: "State",
                    sortable: false,
                },
                {
                    key: "city",
                    label: "City",
                    sortable: false,
                },
                {
                    key: "maxRadius",
                    label: "Max Radius",
                    sortable: false,
                },
                {
                    key: "strict",
                    label: "Strict",
                    sortable: false,
                },
                {
                    key: "Actions",
                    label: "Actions",
                    sortable: false,
                },
            ];
        },
    },
    methods: {
        disable() {
            swal({title:"Disable", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                // this.selected = this.selected.filter(function(e) { return e.text !== args.value[0] })
            });
        },
        enable() {
            swal({title:"Enable", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                // this.selected = this.selected.filter(function(e) { return e.text !== args.value[0] })
            });
        },
        load () {
            //Load the settings

            var request = { apikey: this.$root.apikey, year:  moment().format('YYYY') };
            // this.$root.preloader = true;

            axios
                .get(`${this.$root.serverUrl}/admin/settings`, { params: request })
                .then(
                    function (resp) {
                        if(resp && resp.data){
                            this.inboxEnhanced = resp.data.data.settings.inboxEnhanced
                            this.$forceUpdate();
                        }
                    }.bind(this)
                );
        },
        addConfig() {
            if (!this.click_config.url) this.errors.click_config.url = "Please enter a valid url";
            else this.errors.click_config.url = null;
            if (!this.click_config.min_range) this.errors.click_config.min_range = "Please enter a valid minimum range for clicks";
            else this.errors.click_config.min_range = null;
            if (!this.click_config.max_range) this.errors.click_config.max_range = "Please enter a valid maximum range for clicks";
            else this.errors.click_config.max_range = null;

            let error = false;
            for (let err in this.error) {
                if (err) {
                    error = true
                    break;
                }
            }

            if (error) return;

            this.item.click_config.push(this.click_config)

            this.click_config = {
                url: '',
                min_range: '',
                max_range: ''
            }
        },
        removeConfig(index){
            this.item.click_config.splice(index, 1)
        },
        getCampaignData(campaign = null){
            if(this.csvPanel == false){
                this.csvPanelLoader = true;
                let id = campaign.csvData ? campaign.csvData : '';
                // console.log('c_id: ', id, campaign, campaign.csvData);
                let request = {
                    c_id:id
                };
                request.timestamp = Date.now();

                // console.log('cv request: ', request);
                axios
                    .get(`${this.$root.serverUrl}/admin/campaigns/csvData`, {params:request})
                    .then((resp) => {

                        // console.log('singleCSV DATA resp data: ', resp.data, resp);
                        if (resp.data && resp.data.length > 0) {
                            let data = resp.data;
                            this.csvStream = data;
                            // console.log('singleCSV DATA resp data: ', data);
                            this.$forceUpdate();
                            this.csvPanel = true;
                        }
                    });
            }
            else{
                this.csvPanel = this.csvPanelLoader = false;
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        toggleAdvance(){
            this.advance = !this.advance;
        },
        toggleAdvanceFilter(){
            this.advanceFilter = !this.advanceFilter;
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs;
            this.alert.message = "Creative Uploaded Successfully";
            this.alert.variant = "success";
        },
        ForcesUpdateComponent() {
            this.$forceUpdate();
        },
        hideUploadModal() {
            this.$bvModal.hide("modalPopover");
        },
        hideEvUploadModal() {
            this.$bvModal.hide("modalEvPopover");
        },
        fileupload() {
            let reader = new FileReader();
            let file = this.$refs.uploadObj.files[0];
            this.item.fileName = file.name;
            // let reader = new FileReader();
            reader.onload = (event) => {
                this.item.creative = event.target.result;
            };
            reader.readAsText(file);
            // this.$refs["modalPopover"].hide();
            this.showAlert();

        },
        fileuploader(csv = false) {
            let reader = new FileReader();
            if(csv){
                let file = this.$refs.uploadObj.files[0];
                this.item.csvAppend = file.name;
                reader.onload = (event) => {
                    this.item.csvContent = event.target.result;
                    let gp = papa.parse(this.item.csvContent);
                    console.log('parsed data: ', gp);
                    let targets = gp.data.length-1;
                    this.item.target = targets;
                    this.item.campaignData = gp;
                };
                reader.readAsText(file);
                console.log('csv: ', csv, file.name, this.item.csvAppend, this.item.csvContent )
            }else{
                let file = this.$refs.uploadObj.files[0];
                this.item.fileName = file.name;
                // let reader = new FileReader();
                reader.onload = (event) => {
                    this.item.creative = event.target.result;
                };
                reader.readAsText(file);
                // this.$refs["modalPopover"].hide();
            }
            this.showAlert();

        },
        clientChange(event) {
            this.item.client_id = event.target.value;
            if (this.plansid == "emailverifier") this.loadPlan();
        },
        loadPlans(event) {
            this.plansid = event.target.value;

            if (this.item.above == undefined) this.item.above = 0.7;

            this.loadPlan();
        },
        loadPlan() {
            if (this.item.client_id && this.plansid) {
                return axios
                    .get(
                        `${this.$root.serverUrl}/admin/dropdown/client-product/${this.item.client_id}/${this.plansid}`
                    )
                    .then((resp) => {
                        //Store the stats
                        this.client_product =
                            resp && resp.data && resp.data.data ? resp.data.data : [];
                    });
            } else {
                this.client_product = [];
            }
        },

        tosNotify() {
            this.$bvToast.toast("TOS updated successfully", {
                title: <strong>Information: TOS Updated</strong>,
                variant: "info",
                solid: true,
            });
            this.getDomain();
        },
        removeGeos(idx, campaign_id, id) {
            swal({ title: "Processing", text: "Deleting......", icon: "warning" });
            axios
                .delete(
                    `${this.$root.serverUrl}/admin/campaigns/geo/${id}/${campaign_id}`
                )
                .then((resp) => {
                    if (!resp.error) {
                        this.geo.splice(idx, 1);
                        swal({
                            title: "Success",
                            text: "Deleted successfully.",
                            icon: "success",
                        });
                    } else {
                        this.$bvToast.toast("An Error Occurred.", {
                            title: <strong>ERROR</strong>,
                            variant: "warning",
                            solid: true,
                        });
                    }
                });
        },
        addGeos(geos) {
            geos.campaign_id = this.campaignId;
            axios
                .put(`${this.$root.serverUrl}/admin/campaigns/geo`, geos)
                .then(
                    function (resp) {
                        //Process the results
                        if (resp && resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            geos._id = resp.data.data.id;
                            this.geo.push(Object.assign({}, geos));
                            this.search();
                            // this.item = this.edit(this.item);
                            swal({
                                title: "Notice",
                                text: "Geo Location Added Successfully.",
                                icon: "success",
                            });
                        }
                        if (resp && resp.data && resp.data.error) {
                            //Handle errors
                            this.errormsg = resp.data.error ? resp.data.error.message : false;
                            swal({ title: "Error", text: this.errormsg, icon: "error" });
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                )
                .finally(() => {
                    this.$forceUpdate();
                });
        },
        strictChange(event, index) {
            this.$forceUpdate();
            this.geo[index].strict = event;
        },
        maxChange(event, index) {
            this.$forceUpdate();
            this.geo[index].maxRadius = event.target.value;
        },

        editGeos(geos) {
            // console.log(geos)
            geos.id = geos._id;
            geos.campaign_id = this.campaignId;
            // console.log(geos);
            axios
                .post(`${this.$root.serverUrl}/admin/campaigns/geo`, geos)
                .then((resp) => {
                    //Process the results
                    if (resp && resp.data && !resp.data.error) {
                        //Saved successfully, now update the parent
                        geos._id = resp.data.data.id;
                        swal({
                            title: "Success",
                            text: "Geo Location Edited Successfully.",
                            icon: "success",
                        });
                    }
                    if (resp && resp.data && resp.data.error) {
                        //Handle errors
                        this.errormsg = resp.data.error ? resp.data.error.message : false;
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }
                })
                .catch(
                    function (err) {
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                )
                .finally(() => {
                    this.$forceUpdate();
                });
        },
        getcity(item, idx, planIdx) {
            this.domains[planIdx].geos.splice(idx, 1, item);
            this.tableindex = idx;

            axios
                .post(`${this.$root.serverUrl}/admin/domain/geos`, item)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.$bvToast.toast("Geos updated successfully.", {
                            title: <strong>Information: Geos Updated</strong>,
                            variant: "info",
                            solid: true,
                        });
                    }
                });
        },
        async getClient() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/client`
            );

            if (resp && resp.data && resp.data.error) {
                console.log("");
            } else {
                this.client = resp && resp.data ? resp.data.data : this.client;
            }
        },
        async getProvider() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/provider`
            );
            if (resp && resp.data && resp.data.error) {
                console.log("");
            } else {
                this.provider = resp && resp.data ? resp.data.data : this.provider;
            }
        },
        async behaviors() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/campaign/behavior`
            );
            if (resp && resp.data && resp.data.error) {
                console.log("");
            } else {
                this.behavior = resp && resp.data ? resp.data.data : this.behavior;
            }
        },
        async stricts() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/campaign/strict`
            );
            if (resp.data && resp.data.error) {
                console.log("");
            } else {
                this.strict = resp.data.data;
            }
        },
        async waitUntil() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/campaign/waituntil`
            );
            if (resp.data && resp.data.error) {
                console.log("");
            } else {
                this.waituntil = resp.data.data;
            }
        },
        async methods() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/campaign/method`
            );
            if (resp.data && resp.data.error) {
                console.log("");
            } else {
                this.method = resp.data.data;
            }
        },
        async flows() {
            let resp = await axios.get(
                `${this.$root.serverUrl}/admin/dropdown/campaign/flow`
            );
            if (resp.data && resp.data.error) {
                console.log("");
            } else {
                this.flow = resp.data.data;
            }
        },
        windowListener: function (e) {
            if (
                e.keyCode === 13 &&
                !this.editor &&
                !this.showReport &&
                !this.searching
            ) {
                this.search();
            }
        },
        editLimits: function () {
            this.limits = Object.assign({}, this.item.hourly_thresholds);
            window.$("#result").modal("show");
        },
        saveLimits: function () {
            this.item.hourly_thresholds = this.limits;
            this.$forceUpdate();
            window.$("#result").modal("hide");
        },
        create: function () {
            this.inboxEnhanced ={},
            this.item = {
                geo: [],
                timezone: "EST",
                hourly_thresholds: {},
                navigationTimeout: 60000,
                above: 0.7,
                limiter:'yes',
                loadImages: 'no',
                fqTag:'yes',
                sideLoad:'yes',
                hotJar:'yes',
                proxyIntercept:'no',
                flow:'queue',
                bounce:'no',
                waitUntil:'domcontentloaded',
                snoop:'no',
                domain:'https://yourdomain.com',
                fileName:'YourCreative.html',
                name:'Campaign Name',
                startDate:  moment().format('YYYY-MM-DD'),
                enddate: moment().add(7,'d').format('YYYY-MM-DD'),
                startTime:'08:00',
                endTime:'16:00',
                method:'worker',
                target:1000,
                clicksdaily_min:35,
                clicksdaily:35,
                max_distance:10,
                localLinks:'yes',
                alternative: 'no',
                referersArray:['https://referer.com','http://other.com'],
                blacklistArray:['/terms', '/privacy' ,'/about','/privacy-policy','/privacy','/privacypolicy','/unsubscribe','/wp-login','/feed','/wp-admin','/sitemap','/login','/logout'],
                behavior:'',
                flowRate:'',
                mobile:'yes',
                video:'no',
                videoFps:5,
                videoQuality:5,
                setGeo:'no',
                provider:'luminate',
                threshold:'1',
                linkLevel:'1',
                evMode:'normal',
                csvAppend:'',
            };
            this.geo = [];
            this.geos.country = 'US';
            this.campaignId = "";
            this.linkLevelTos = [];
            if (this.$route.query.cp_id) {
                this.item.client_id = this.$route.query.client_id;
                this.item.product_type = this.$route.query.pt;
                this.item.client_product_id = this.$route.query.cp_id;
            }
            this.editor = true;
            this.flowChange();
        },
        back: function () {
            this.showReport = null;
            this.selected = {};
            this.geos = {};
        },
        selectItem: function (e) {
            this.showReport = true;
            this.report = Object.assign(this.report, this.originalReport);
            this.nextCampaign = true;
            this.selected = e;
            this.reports(this.selected);
        },
        resetItem: function () {
            this.resetDescriptionEmpty = null;
            this.resetPlaceHolder = "";
            if (this.note.description.length === 0) {
                this.resetDescriptionEmpty = false;
                this.resetPlaceHolder = "Please enter a valid reason.....";
                setTimeout(
                    function () {
                        this.resetDescriptionEmpty = null;
                        this.resetPlaceHolder = "";
                    }.bind(this),
                    2000
                );
                return;
            }
            this.note.user_id = this.$root.apikey;
            this.note.campaign_id = this.resetSelected._id;
            this.note.created_ts = new Date(
                Date.UTC(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                )
            );
            //Handle resetting campaign
            swal({
                title: "Reset to Active",
                text: "Are you sure?",
                icon: "info",
                buttons: ["No", "Yes"],
            }).then(
                function (val) {
                    if (!val) return;

                    var request = {
                        apikey: this.$root.apikey,
                        id: this.resetSelected._id,
                        note: this.note,
                    };
                    // this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/campaigns/reset`,
                        data: request,
                        responseType: "json",
                        method: "POST",
                    })
                        .then(
                            function (resp) {
                                //Process the results
                                this.$root.preloader = false;
                                if (resp.data && !resp.data.error) {
                                    //Saved successfully, now update the parent
                                    swal({
                                        title: "Reset",
                                        text: "Your request was completed successfully",
                                        icon: "success",
                                    }).then(
                                        function () {
                                            this.search();
                                            this.$refs["my-modal"].hide();
                                        }.bind(this)
                                    );
                                } else {
                                    //Handle errors
                                    swal({
                                        title: "Reset",
                                        text: "Unable to complete your request",
                                        icon: "error",
                                    });
                                }
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                this.$root.preloader = false;
                                this.errormsg = err.message || "An unexpected error occurred";
                                swal({ title: "Error", text: this.errormsg, icon: "error" });
                            }.bind(this)
                        );
                }.bind(this)
            );
        },
        resetItemModal: function (obj) {
            this.note.description = "";
            this.resetSelected = obj;
            this.$refs["my-modal"].show();
            this.resetModal = true;
            this.resetTitle = `Reset Campaign (${obj.name})`;
        },
        saveActivate() {
            this.item.status = "active"
            this.save(true)
        },
        save: function (boolean) {
            //Handle saving the changes
            this.item.geo = Object.assign(this.geos);
            var request = {
                apikey: this.$root.apikey,
                id: this.item._id,
                args: this.item,
            };
            // this.$root.preloader = true;

            if (this.from !== this.item.threshold) {
                request.args.from = this.from;
                request.args.threshold_update = true;
            }

            request.args.country =  request.args.geo.country
            request.args.changes = [];
            for (const hour in this.item.hourly_thresholds) {
                if (this.hour[hour] !== this.item.hourly_thresholds[hour]) {
                    request.args.changes.push({
                        hour: hour,
                        to: this.item.hourly_thresholds[hour] || 0,
                        from: this.hour[hour] || 0,
                    });
                    request.args.threshold_hourly_update = true;
                }
            }

            request.args.edit = boolean     //should be true allow edit
            if(this.$route.query.campaign) request.args.edit = boolean //should be false to allow copy
            if(this.campaignId || this.$route.query.campaign){
                this.item["_id"] = this.campaignId //if copied campaign
                request.args. cid = this.campaignId
            }

            axios({
                url: `${this.$root.serverUrl}/admin/campaigns`,
                data: request,
                responseType: "json",
                method: this.item["_id"] ? "POST" : "PUT",
            })
                .then(
                    function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            this.campaignId = resp.data.data.id;
                            this.newCampaignId = true
                            this.search()
                            swal({
                                title: "Notice",
                                text: "Your changes were saved successfully, Please Remember to" +
                                    " configure your Geo Locations and Time on Site",
                                icon: "success",
                            });
                        }
                        if (resp.data.error) {
                            //Handle errors
                            this.errormsg = resp.data.error ? resp.data.error.message : false;
                            this.$root.preloader = false;
                            // console.log(resp.data.error.message)
                            // alert(resp.data.error)
                            swal({ title: "Error", text: this.errormsg, icon: "error" });
                            // swal({title:"Error", text: resp.data.error, icon:"error"})
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                );
        },
        bulk: function () {
            //Handle saving the changes
            if (!this.action.status) delete this.action.status;
            if (!this.action.mobile) delete this.action.mobile;
            if (!this.action.threshold) delete this.action.threshold;
            if (!this.action.method) delete this.action.method;
            var request = {
                apikey: this.$root.apikey,
                ids: this.selectedCamp,
                args: this.action,
            };
            // this.$root.preloader = true;

            request.args.changes = [];
            for (const id in this.selectedCamp) {
                for (const campaign in this.items.data) {
                    if (this.items.data[campaign]._id === this.selectedCamp[id]) {
                        if (this.action.threshold !== this.items.data[campaign].threshold) {
                            request.args.changes.push({
                                campaign_id: this.selectedCamp[id],
                                from: this.items.data[campaign].threshold || 0,
                            });
                        }
                    }
                }
            }

            axios({
                url: `${this.$root.serverUrl}/admin/campaigns/bulk`,
                data: request,
                responseType: "json",
                method: "POST",
            })
                .then(
                    function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            swal({
                                title: "Notice",
                                text: "Your changes were saved successfully",
                                icon: "success",
                            }).then(
                                function (val) {
                                    if (val) {
                                        this.action = { mobile: "", status: "" };
                                        this.selectedCamp = [];
                                        this.search();
                                    }
                                }.bind(this)
                            );
                        } else {
                            //Handle errors
                            this.errormsg =
                                resp.data && resp.data.error ? resp.data.error : false;
                            this.$root.preloader = false;
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                );
        },
        setStatus(obj) {
            let option = "activate"; // which route
            let value = "Activate"; //disabled or active

            if (obj.status == "active") {
                option = "disable";
                value = "Disable";
            }
            swal({
                title: value,
                text: "Are you sure?",
                icon: "info",
                buttons: ["No", "Yes"],
            }).then(
                function (val) {
                    if (!val) return;

                    var request = { apikey: this.$root.apikey, id: obj._id };
                    // this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/campaigns/${option}/${obj._id}`,
                        data: request,
                        responseType: "json",
                        method: "POST",
                    })
                        .then(
                            function (resp) {
                                //Process the results
                                this.$root.preloader = false;
                                if (resp.data && !resp.data.error) {
                                    //update successfully, now update the parent
                                    swal({
                                        title: value,
                                        text: "Your request was completed successfully",
                                        icon: "success",
                                    }).then(
                                        function () {
                                            this.search();
                                        }.bind(this)
                                    );
                                } else {
                                    //Handle errors
                                    swal({
                                        title: value,
                                        text: resp.data.error.message,
                                        icon: "error",
                                    });
                                }
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                this.$root.preloader = false;
                                this.errormsg = err.message || "An unexpected error occured";
                                swal({ title: "Error", text: this.errormsg, icon: "error" });
                            }.bind(this)
                        );
                }.bind(this)
            );
        },
        remove: function (obj) {
            //Handle removing the item
            swal({
                title: "Remove",
                text: "Are you sure?",
                icon: "info",
                buttons: ["No", "Yes"],
            }).then(
                function (val) {
                    if (!val) return;

                    var request = { apikey: this.$root.apikey, id: obj._id };
                    // this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/campaigns/${obj._id}`,
                        data: request,
                        responseType: "json",
                        method: "DELETE",
                    })
                        .then(
                            function (resp) {
                                //Process the results
                                this.$root.preloader = false;
                                if (resp.data && !resp.data.error) {
                                    //Saved successfully, now update the parent
                                    swal({
                                        title: "Remove",
                                        text: "Your request was completed successfully",
                                        icon: "success",
                                    }).then(
                                        function () {
                                            this.search();
                                        }.bind(this)
                                    );
                                } else {
                                    //Handle errors
                                    swal({
                                        title: "Remove",
                                        text: resp.data.error.message,
                                        icon: "error",
                                    });
                                }
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                this.$root.preloader = false;
                                this.errormsg = err.message || "An unexpected error occurred";
                                swal({ title: "Error", text: this.errormsg, icon: "error" });
                            }.bind(this)
                        );
                }.bind(this)
            );
        },
        link: function (obj) {
            this.linkItem = obj;
            this.$forceUpdate()
        },
        campaignListShow:function(data){
            // $bvModal.show('bv-campaign-example')
            this.campaignListData = data
            this.$refs.bvCampaignExample.show();
        },
        editInboxEnhance:function(obj){
            this.item = obj
            this.editor = true;
            this.editMood = true

            this.item.product_type =  'inboxEnhanced'
            this.item.rule=  { sender_email: '', behaviors: ['reply']},
            // this.item = obj
            this.currentView = 'inboxEnhanced'
            // this.$forceUpdate()
        },
        edit: function (obj) {
            this.item = Object.assign({}, obj);
            this.itemView = Object.assign({}, obj);

            if (!this.item.hourly_thresholds) this.item.hourly_thresholds = {}
            if (this.item.above == undefined) this.item.above = 0.7;

            this.campaignId = this.item._id;
            this.geo = this.item.geos || [];
            this.linkLevelTos = this.item.linkLevelTos || [];
            this.plansid = this.item.product_type;
            this.from = obj.threshold;
            this.hour = obj.hourly_thresholds || {};
            this.loadPlan();
            if (!this.item.geo) {
                this.item.geo = {};
            } else {
                this.geos = Object.assign(this.item.geo);
                this.Getstates();
                this.Getcities();
            }
            if (obj.product_type == "adverifier") this.editor = true;
            this.$forceUpdate();
        },
        copy: function (obj) {
            //Copy the item
            this.item = Object.clone({}, obj);
            this.item.name = `Copy Of ${this.item.name}`;
            if(typeof this.item.above === "undefined") this.item.above = 0.7;
            delete this.item._id;
            delete this.item.tracking;
            delete this.item.today;
            delete this.item.yesterday;
            this.editor = true;
        },
        reports: function (obj) {
            //Show reports on the item
            this.report = Object.assign({}, this.originalReport);
            this.editor = false;
            this.report.name = obj.name;
            this.hitsToday = false;
            this.hitsYesterday = false;
            this.hitsMonth = false;
            this.hitsTotal = false;
            this.missesToday = false;
            this.missesYesterday = false;
            this.missesMonth = false;
            this.missesTotal = false;

            const tracker = [];
            switch (this.selectedStat) {
                case "Today": {
                    tracker.push(
                        { track: "today", lbl: "hitsToday", url: "stats", type: "hits" },
                        {
                            track: "today",
                            lbl: "missesToday",
                            url: "misses",
                            type: "misses",
                        }
                    );
                    break;
                }
                case "Yesterday": {
                    tracker.push(
                        {
                            track: "yesterday",
                            lbl: "hitsYesterday",
                            url: "stats",
                            type: "hits",
                        },
                        {
                            track: "yesterday",
                            lbl: "missesYesterday",
                            url: "misses",
                            type: "misses",
                        }
                    );
                    break;
                }
                case "This Month": {
                    tracker.push(
                        { track: "month", lbl: "hitsMonth", url: "stats", type: "hits" },
                        {
                            track: "month",
                            lbl: "missesMonth",
                            url: "misses",
                            type: "misses",
                        }
                    );
                    break;
                }
                case "Total": {
                    tracker.push(
                        { track: "total", lbl: "hitsTotal", url: "stats", type: "hits" },
                        {
                            track: "total",
                            lbl: "missesTotal",
                            url: "misses",
                            type: "misses",
                        }
                    );
                    break;
                }
            }
            var request = { apikey: this.$root.apikey };
            for (let i in tracker) {
                axios
                    .get(
                        `${this.$root.serverUrl}/admin/campaigns/${tracker[i].url}/${obj._id}?task=${tracker[i].track}`,
                        { params: request }
                    )
                    .then(
                        function (resp) {
                            this[tracker[i].lbl] = true;
                            this.report.stats[tracker[i].type][tracker[i].track] =
                                resp.data.data[tracker[i].track];
                            //this.$root.preloader = false
                        }.bind(this)
                    )
                    .catch((err) => {
                        console.log("report.error", err);
                        swal({
                            title: "Oops",
                            text: `Sorry, I am unable to complete your request. ${err.message}`,
                            icon: "error",
                        });
                    });
            }

            /* axios.get(`${this.$root.serverUrl}/admin/campaigns/stats/${obj._id}`, {params:request}).then(function(resp){
                    //Store the stats
                    this.hitsToday = true;
                    this.report.stats.hits.today = resp.data.data.today;
                    this.report.stats.hits.yesterday = resp.data.data.yesterday;
                    this.report.stats.hits.month = resp.data.data.month;
                    this.report.stats.hits.total = resp.data.data.total;

                }.bind(this)).catch((err)=>{
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });*/
        },
        nextReport: function () {
            const tableLength = this.items.data.length - 1;
            const currentIndex = this.items.data.indexOf(this.selected);
            const nextIndex = currentIndex + 1;
            this.selected = this.items.data[nextIndex];
            this.reports(this.selected);
            if (nextIndex >= tableLength) {
                this.nextCampaign = false;
                //todo figure out how to fire infinite loading manually
                /* this.hitsToday = false;
                     this.hitsYesterday = false;
                     this.hitsMonth = false;
                     this.hitsTotal = false;
                     this.missesToday = false;
                     this.missesYesterday = false;
                     this.missesMonth = false;
                     this.missesTotal = false;
                     this.infiniteId += 5;
                     this.infiniteHandler();
                     this.nextCampaignTrigger = true;*/
            }
        },
        viewChart: function (type, elem, lbl) {
            if (this[lbl]) {
                const rep = this.report.stats[type][elem].report;
                this.report.chartdata = {
                    data: [
                        {
                            name: this.report.name,
                            "x-name": "Daily Hour",
                            "y-name": "Hits",
                            data: [],
                        },
                    ],
                };

                for (const hour in rep.hourly) {
                    this.report.chartdata.data[0].data.push({
                        label: `${hour}`,
                        value: rep.hourly[hour],
                    });
                }
            }
        },
        cancel: function () {
            this.editor = false;
            this.errormsg = false;
            // this.$router.go()
            this.search();
            this.geoL = {};
        },
        sortSearch: function () {
            this.sortClass =
                this.sortClass === "fa fa-long-arrow-up"
                    ? "fa fa-long-arrow-down"
                    : "fa fa-long-arrow-up";
            this.search();
        },
        searchById: async function (id) {
            this.searching = true;
            axios.get(`${this.$root.serverUrl}/admin/campaigns/${id}`).then((resp) => {
                this.searching = false;
                this.items = resp.data ? resp.data.data : [];
                this.items.name = `Copy Of ${this.items.name}`
                this.edit(this.items)

            }).catch(
                function (err) {
                    this.$root.preloader = false;
                    swal({ title: "Oops", text: err.message, icon: "error" });
                    this.searching = false;
                }.bind(this)
            );

        },
        paginate(page, max) {
            this.page = page;
            this.max = max;
            this.search();
        },
        searchN: async function () {
            this.page = 1;
            this.loading = true;
            this.search()
        },
        search: async function () {

            if(this.page == 0) {
                return
            }

            this.searching = true;
            let request = {
                apikey: this.$root.apikey,
                sSearch: null,
                filters: {},
                geo: {},
            };
            if (this.filter.client_product_id)
                request.filters.client_product_id = this.filter.client_product_id;
            if (this.filter.search) request.sSearch = this.filter.search;
            if (this.filter.type) request.filters.product_type = this.filter.type;
            if (this.filter.client) request.filters.client_id = this.filter.client;
            if (this.filter.proxy) request.filters.proxy = this.filter.proxy;
            if (this.filter.status) request.filters.status = this.filter.status;
            if (this.filter.method) request.filters.method = this.filter.method;
            if (this.filter.product)
                request.filters.product_type = this.filter.product;
            if (this.filter.country) request.geo.country = this.filter.country;
            if (this.filter.state) request.geo.state = this.filter.state;
            if (this.filter.city) request.geo.city = this.filter.city;
            if (this.filter.end_date) request.filters.end_date = this.filter.end_date;
            request.sortAsc = this.sortClass === "fa fa-long-arrow-up";
            request.sortTouchDate = this.filter.touch;
            request.page = this.page;
            request.max = this.max;
            request.all = true


            if(this.currentView != this.filter.product) {
                this.items = { total: 0, data: [] };
                this.loading = true;
            }
            this.currentView = this.filter.product;

            if(this.$route.query.campaign && this.items.data) request.all = false
            // this.$root.preloader = true;

            if (this.currentView !== "emailverifier") {
                axios
                    .get(`${this.$root.serverUrl}/admin/campaigns`, { params: request })
                    .then((resp) => {
                        //Store the stats
                        this.items = resp.data ? resp.data : { total: 0, data: [] };
                        console.log(resp.data,'data')
                        this.currentCount = this.max * this.page;

                        if(this.currentCount % this.max != 0 || this.currentCount > this.items.total) {
                            this.currentCount = this.max * (this.page-1)
                            this.currentCount+=this.items.data.length
                        }
                        this.$root.preloader = false;
                        this.searching = false;
                        this.loading = false;
                        //  this.item.push(...resp.data)
                        console.log('searing stayed')
                        // console.log(this.items.data)
                    }).catch((err) => {
                    this.$root.preloader = false;
                    swal({ title: "Oops", text: err.message, icon: "error" });
                    this.searching = false;
                    this.loading = false;
                })
            }
            else {
                let query = [];
                for (var key in request) {
                    if (request.hasOwnProperty(key)) {
                        if (request[key]) {
                            query.push(`${key}=${typeof request[key] === 'object' ? JSON.stringify(request[key]) : request[key]}`);
                        }
                    }
                }

                this.loading = true;

                // this.items = null;

                this.$nextTick(async () => {
                    try {
                        this.items = await new DataManager({
                            url: `${this.$root.serverUrl}/admin/campaigns?${query.join("&")}`,
                            adaptor: new WebApiAdaptor(),
                            crossDomain: true,
                            headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                        })

                    } catch (err) {
                        swal({ title: "Oops", text: err.message, icon: "error" });
                    }
                    this.searching = false;
                })

            }

        },
        hideLoader(hide) {
            this.loading = !hide;
        },
        loadClients: function () {
            //Load the clients
            var request = { apikey: this.$root.apikey };
            //this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/admin/campaigns/clients`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        //Store the stats
                        this.clients = resp.data && resp.data.data ? resp.data.data : [];
                        //this.$root.preloader = false;
                    }.bind(this)
                );
        },
        loadcountries: function () {
            var request = { apikey: this.$root.apikey, sSearch: "" };

            return axios
                .get(`${this.$root.serverUrl}/admin/countries`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.countries = resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadstates: function () {
            this.states = [];
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.filter.country)
                request.filters.country_iso_code = this.filter.country;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/state`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.states = resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        infiniteHandler($state) {
            this.page += 1;
            const request = {
                apikey: this.$root.apikey,
                sSearch: null,
                filters: {},
                geo: {},
            };
            if (this.filter.search) request.sSearch = this.filter.search;
            if (this.filter.type) request.filters.type = this.filter.type;
            if (this.filter.client) request.filters.client = this.filter.client;
            if (this.filter.proxy) request.filters.proxy = this.filter.proxy;
            if (this.filter.status) request.filters.status = this.filter.status;
            if (this.filter.method) request.filters.method = this.filter.method;
            if (this.filter.country) request.geo.country = this.filter.country;
            if (this.filter.state) request.geo.state = this.filter.state;
            if (this.filter.city) request.geo.city = this.filter.city;
            request.sortAsc = this.sortClass === "fa fa-long-arrow-up";
            request.sortTouchDate = this.filter.touch;
            request.page = this.page;
            request.max = this.max;

            axios
                .get(`${this.$root.serverUrl}/admin/campaigns`, { params: request })
                .then(
                    function (resp) {
                        $state.loaded();
                        if (resp.data && resp.data.data.length > 0) {
                            this.items.data.push(...resp.data.data);

                            if (this.nextCampaignTrigger) {
                                this.nextReport();
                                this.nextCampaignTrigger = false;
                            }
                        } else {
                            $state.complete();
                        }
                    }.bind(this)
                )
                .catch(function (err) {
                    $state.complete();
                    swal({ title: "Oops", text: err.message, icon: "error" });
                });
        },
        setSelectedStat(stat) {
            this.selectedStat = stat;
            this.reports(this.selected);
        },
        Getstates: function () {
            this.states = [];
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.geoL.country)
                request.filters.country_iso_code = this.geoL.country;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/state`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.states = resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        flowChange:function (){
            let rates = {
                'slow': {early: 32, peak: 0, late: 24, range:{min:8, max:14}},
                'normal': {early: 28, peak: -3, late: 28, range:{min:8, max:14}},
                'fast': {early: 16, peak: 0, late: 20, range:{min:8, max:14}},
            }
            console.log('campaign rates: ', rates)
            console.log('campaign: ', this.item)
            if(this.item && this.item.flowRate){
                let data = rates[this.item.flowRate];
                this.item.flowEarly = data.early;
                this.item.flowLate = data.late;
                this.item.flowPeak = data.peak;
                this.item.flowMin = data.range.min;
                this.item.flowMax = data.range.max;
            }
        },
        loadcities: function () {
            this.cities = [];
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.filter.country)
                request.filters.country_iso_code = this.filter.country;
            if (this.filter.state)
                request.filters.subdivision_1_iso_code = this.filter.state;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/city`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        handleEvCsvFile: function () {
            this.cities = [];
            var request = { apikey: this.$root.apikey, filters: {} };

            this.filters.content = this.item.csvContent;
            // if (this.filter.content)
            //     request.filters.country_iso_code = this.filter.country;
            // if (this.filter.state)
            //     request.filters.subdivision_1_iso_code = this.filter.state;

            return axios
                .get(`${this.$root.serverUrl}/admin/schedule/csv`, { params: request })
                .then(
                    function () {
                        //Store the stats
                        // this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        Getcities: function () {
            this.cities = [];
            var request = { apikey: this.$root.apikey, filters: {} };

            if (this.geoL.country)
                request.filters.country_iso_code = this.geoL.country;
            if (this.geoL.state)
                request.filters.subdivision_1_iso_code = this.geoL.state;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/city`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        simulate: function () {
            const msg = `
                    The selected campaigns will be simulated. This process will run in the background. Are you sure?
                `;
            swal({
                title: "Confirm Action",
                icon: "warning",
                text: msg,
                buttons: {
                    cancel: true,
                    confirm: true,
                },
            }).then((result) => {
                if (result) {
                    this.simulationData = [];
                    this.simulationActive = true;
                    this.$refs["simulate-modal-bulk"].show();
                    this.simulationTitle = `Simulating ${this.selectedCamp.length} Campaign(s)`;
                    var request = { apikey: this.$root.apikey, ids: this.selectedCamp };
                    axios({
                        url: `${this.$root.serverUrl}/admin/simulations/simulate`,
                        data: request,
                        responseType: "json",
                        method: "POST",
                    })
                        .then(
                            function (resp) {
                                this.simulationActive = false;
                                this.simulationData = resp.data;
                                //Process the results
                                console.log(resp);
                            }.bind(this)
                        )
                        .catch(
                            function (err) {
                                console.log(err);
                            }.bind(this)
                        );
                } else {
                    console.log();
                }
            });
        },
        simulateOne: function (obj) {
            this.$refs["simulate-modal"].show();
            this.simulationData = [];
            this.simulationActive = true;
            this.simulationTitle = `Simulating Campaign (${obj.name})`;
            var request = { apikey: this.$root.apikey, ids: [obj._id] };
            axios({
                url: `${this.$root.serverUrl}/admin/simulations/simulate`,
                data: request,
                responseType: "json",
                method: "POST",
            })
                .then(
                    function (resp) {
                        this.simulationActive = false;
                        this.simulationData = resp.data[0].stack;
                        //Process the results
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        console.log(err);
                    }.bind(this)
                );
        },
        simulationRowClass: function (item, type) {
            if (!item || type !== "row") return;
            return `table-${item.type}`;
        },
        handleEmailFile: function () {
            this.readFile(this.$refs["email-file"].files[0])
                .then(() => {})
                .catch(() => {});
        },
        preview: function () {
            this.readFile()
                .then(
                    function () {
                        const previewWindow = window.open("", "");
                        previewWindow.document.write(this.email.preview);
                    }.bind(this)
                )
                .catch(() => {
                    swal("!Oops", "A valid HTML file needs to be selected", "error");
                });
        },
        uploadFile: function () {
            if (typeof this.item._id === "undefined") {
                swal("!Oops", "Invalid campaign", "error");
                return;
            }
            this.readFile()
                .then(
                    function () {
                        const form = new FormData();
                        form.append("id", this.item._id);
                        form.append("email", this.email.file);
                        form.append("type", this.item.type);
                        this.email.uploading = true;
                        axios
                            .post(
                                `${this.$root.serverUrl}/admin/campaigns/email-upload`,
                                form,
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then(
                                function () {
                                    this.email.uploading = false;
                                    this.email.file = null;
                                    this.email.preview = "";
                                    swal(
                                        "!Success",
                                        "Email was uploaded successfully",
                                        "success"
                                    );
                                    this.search();
                                }.bind(this)
                            )
                            .catch(
                                function (err) {
                                    this.email.uploading = false;
                                    swal("!Oops", err.message, "error");
                                }.bind(this)
                            );
                    }.bind(this)
                )
                .catch(() => {
                    swal("!Oops", "A valid HTML file needs to be selected", "error");
                });
        },
        readFile: function (file = null) {
            return new Promise(
                function (resolve, reject) {
                    if (file === null) {
                        return this.email.file !== null ? resolve() : reject();
                    }
                    if (typeof file === "undefined") return reject();
                    let reader = new FileReader();

                    reader.onload = function (e) {
                        this.email.preview = e.target.result;
                        this.email.file = file;
                        resolve();
                    }.bind(this);

                    reader.readAsText(file);
                }.bind(this)
            );
        },
    },
    watch: {
        "filter.country": function () {
            this.filter.state = "";
            this.filter.city = "";
        },
        "filter.state": function () {
            this.filter.city = "";
        },
    },
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
#modalEvPopover___BV_modal_outer_,
#modalPopover___BV_modal_outer_ {
    z-index: 9999999999 !important;
}
.table {
    /*vertical-align: top;*/
    min-height: 300px;
    margin-top: 0.8%;
    width: 100%;
} /**/
/* button span {
        max-width: 0;
        -webkit-transition: max-width 1s;
        -moz-transition: max-width 1s;
        -o-transition: max-width 1s;
        transition: max-width 1s;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
    } */
/* button:hover span {
        max-width: 9rem;
    } */
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
