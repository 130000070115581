<template>
        <div class="modal-body backGround">
            <div class="row">
                <div class="col-12">
                    <csvdata :items="getData"/>
                </div>
            </div>
        </div>
</template>

<script>
import axios from "axios";

import csvdata from "@/components/campaigns/csvdata";
export default {
    name: "panelCsvService",
    props: ['stream', 'name', 'pid', 'campaign'],

    components:{
        csvdata
    },
    data() {
        return {
            pot:[]
        }
    },
    created(){
        // console.log('singleCsv created');
        // this.getCampaignData();
        // console.log('singleCsv created done');
    },
    mounted(){
        // console.log('singleCsv mounted ');
        // this.getCampaignData();
        // console.log('singleCsv mounted done');
    },
    computed:{
        getData(){
            return this.stream?this.stream:this.pot;
        }
    },
    methods: {
        run() {
            this.$emit('ok')
            this.$root.$emit('bv::hide::modal', 'singleCsv');
        },
        getCampaignData(campaign = null){
            let id = campaign.csvData ? campaign.csvData : '';
            console.log('c_id: ', id, campaign, campaign.csvData);
            let request = {
                c_id:id
            };
            request.timestamp = Date.now();

            console.log('cv request: ', request);
            axios
                .get(`${this.$root.serverUrl}/admin/campaigns/csvData`, {params:request})
                .then((resp) => {

                    console.log('singleCSV DATA resp data: ', resp.data, resp);
                    if (resp.data && resp.data.length > 0) {
                        let data = resp.data;
                        this.pot = data;
                        console.log('singleCSV DATA resp data: ', data);
                    }
                });
        }
    }
}
</script>

<style scoped>
    .backGround {
        /*background-color: #044751 !important;*/
    }
    .message {
        color: #9BC6A7 !important;
    }
    .labels {
        color: #fdc46b !important;
    }
    /deep/ .modal-dialog {
        min-width: 95% !important;
        min-height: 700px !important;
        max-height: 700px !important;
    }
</style>
