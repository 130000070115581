<template>
    <div style="width: 100%;">
        <div class="row col-12">
            <div class="row col-12"><h5>Distribution Preview</h5><br><hr></div>
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="getToday.product_type ==  'emailverifier' ">
                <div class="form-group">
                    <label>Flow Rate: Early</label>
                    <input type="number" v-model="getToday.flowEarly" class="form-control" min="0" disabled/>
                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="getToday.product_type ==  'emailverifier' ">
                <div class="form-group">
                    <label>Flow Rate: Late</label>
                    <input type="number" v-model="getToday.flowLate" class="form-control" min="0" disabled/>
                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="getToday.product_type ==  'emailverifier' ">
                <div class="form-group">
                    <label>Flow Rate: Peak</label>
                    <input type="number" v-model="getToday.flowPeak" class="form-control" min="0" disabled/>
                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="getToday.product_type ==  'emailverifier' ">
                <div class="form-group">
                    <label>Flow Rate: Range min</label>
                    <input type="number" v-model="getToday.flowMin" class="form-control" min="0" disabled/>
                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4" v-if="getToday.product_type ==  'emailverifier' ">
                <div class="form-group">
                    <label>Flow Rate: Range max</label>
                    <input type="number" v-model="getToday.flowMax" class="form-control" min="0" disabled/>
                    <div class="form-text text-muted">Maximum distance to search for proxies closest to geo in meters</div>
                </div>
            </div>
        </div>
        <ejs-chart width="max-content" id="currentDay"
                   :palettes='palettes'
                   :primaryXAxis="primaryXAxis"
                   :primaryYAxis="primaryYAxis"
                   :tooltip="tooltip"
                   :chartArea="chartArea">
            <e-series-collection>
                <e-series :dataSource="getData" type="Line" xName="x" yName="clicks" name="Distribution" width="2" dashArray="5,1" :marker="marker" ></e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { ChartPlugin, LineSeries, Legend, Tooltip, Category,  } from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

export default {
    name: "distribution.vue",
    props:['filter', 'itemDataset'],
    data() {
        return {
            palettes: ["#008000", "#800080"],
            primaryXAxis: {
                title: "days",
                majorGridLines: {width: 0},
                minorGridLines: {width: 0},
                majorTickLines: {width: 0},
                minorTickLines: {width: 0},
                interval: 1,
                lineStyle: {width: 0},
                valueType: "Category",
                labelFormat: "%{value}"

            },
            primaryYAxis: {
                title: "impressions",
                lineStyle: {width: 0},
                minimum: 0,
                majorTickLines: {width: 0},
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                minorTickLines: {width: 0},
            },
            chartArea: {
                border: {
                    width: 0
                }
            },
            marker: {
                visible: true
            },
            tooltip: {
                enable: true
            }
        };
    },
    provide: {
        chart: [LineSeries, Legend, Tooltip, Category, ]
    },
    computed: {
        getData() {
            let data = [
                // {x:0,clicks:0},
                // {x:1,clicks:10},
                // {x:2,clicks:20},
                // {x:3,clicks:0},
                // {x:4,clicks:23},
                // {x:5,clicks:0},
            ];
            if(this.itemDataset){
                let {
                    startDate,
                    startTime,
                    enddate,
                    endTime,
                    timezone,
                    flowRate,
                    target,
                    clicksdaily,
                }= this.getToday;
                let dataset = this.calculateFlowRate(flowRate, startDate, enddate, target, target, timezone, true);

                if (this.itemDataset.flow == 'custom') {
                    if (!startTime) startTime = "00:00"
                    if (!endTime) endTime = "23:59"

                    startTime = moment(startTime + ":00", 'HH:mm:ss');
                    endTime = moment(endTime + ":00", 'HH:mm:ss');

                    const seed = moment().startOf('day').unix()

                    dataset = this.getDistribution(startTime, endTime, Number(clicksdaily), 0, flowRate, seed)
                }

                console.log('diss data:',flowRate, startDate, enddate, target, timezone, dataset)
                for(let i = 0; i< dataset.length; i++){
                    data.push({x:i, clicks:dataset[i]})
                }
            }
            // data = this.$store.state ? this.$store.state.seriesDataDay : data;
            // console.log("getting data")
            return data;
        },
        getToday() {
            let data = {};
            data = this.itemDataset ? this.itemDataset : data;
            return data;
        },
        getPrevious() {
            let data = [];
            data = this.getData.previous ? this.getData.previous : data;
            return data;
        },
    },
    methods:{
        calculateFlowRatez(speed, start, end, max, remaining, zone, full){
            //flowRate calculation for threshold
            let startDate = moment(start || Date.now()).startOf('day');
            let endDate = moment(end || Date.now()).endOf('day');
            let days = endDate.diff(startDate, 'days');
            if(days < 0)
                return false;

            let data = (max - remaining) == 0 ? max : (max - remaining);
            let daily = Math.ceil((data) / (days||1));
            let hourly = [];
            let used = 0;
            let rates = {
                'slow': {early: 32, peak: 0, late: 24, range:{min:8, max:14}},
                'normal': {early: 28, peak: 0, late: 28, range:{min:8, max:14}},
                'fast': {early: 16, peak: 0, late: 20, range:{min:8, max:14}},
            }

            let hour = (full)? 0 : moment().tz(zone || 'EST').hour();

            for(var h=0;h<24;h++) {
                //Check the hours and adjust according to the time of day and natural email
                let clicks = daily - used;
                hourly[h] = 0;

                if(clicks >= 0 && h >= hour) {
                    if (h < rates[speed].range.min) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].early + h));
                    } else if (h >= rates[speed].range.min && h <= rates[speed].range.max) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].peak + h));
                    } else {
                        hourly[h] = Math.ceil(clicks / (rates[speed].late + h));
                    }

                    used += hourly[h];
                }
            }

            //Returns the object with the hourly_threshold
            return hourly;
        },
        calculateFlowRate(campaign, speed, start, end, max, remaining, zone, full){
            //flowRate calculation for threshold
            let startDate = moment(start || Date.now()).startOf('day');
            let endDate = moment(end || Date.now()).endOf('day');
            let days = endDate.diff(startDate, 'days');
            if(days < 0)
                return false;

            let data = (max - remaining) == 0 ? max : (max - remaining);
            let daily = Math.ceil((data) / (days||1));
            let hourly = [];
            let used = 0;
            let rates = {
                'slow': {early: 32, peak: 0, late: 24, range:{min:8, max:14}},
                'normal': {early: 28, peak: -3, late: 28, range:{min:8, max:14}},
                'fast': {early: 16, peak: 0, late: 20, range:{min:8, max:14}},
            }
            if(campaign && campaign.flowRate && speed.length > 0){
                // console.log('item: ', campaign)
                rates = {
                    'slow': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                    'normal': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                    'fast': {early: campaign.flowEarly, peak: campaign.flowPeak, late: campaign.flowLate, range:{min:campaign.flowMin, max:campaign.flowMax}},
                }
                // console.log('rates: ', rates[speed])

            }

            let hour = (full)? 0 : moment().tz(zone || 'EST').hour();

            for(var h=0;h<24;h++) {
                //Check the hours and adjust according to the time of day and natural email
                let clicks = daily - used;
                hourly[h] = 0;

                if(clicks >= 0 && h >= hour) {
                    if (h < rates[speed].range.min) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].early + h));
                    } else if (h >= rates[speed].range.min && h <= rates[speed].range.max) {
                        hourly[h] = Math.ceil(clicks / (rates[speed].peak + h));
                    } else {
                        hourly[h] = Math.ceil(clicks / (rates[speed].late + h));
                    }

                    used += hourly[h];
                }
            }

            //Returns the object with the hourly_threshold
            return hourly;
        },
        generateRandomizedDistribution(number, hours, mean, seed) {
            const random = this.seededRandom(seed);
            const standardDeviation = mean / 3;

            const factor = 1 / (standardDeviation * Math.sqrt(2 * Math.PI));

            // Gaussian function (bell curve)
            const gaussian = (x) => factor * Math.exp(-0.5 * Math.pow((x - mean) / standardDeviation, 2));

            // Create an array of numbers representing the distribution with added randomness
            const distribution = Array.from({ length: hours }, (_, i) => {
                const randomFactor = random() * 0.2 + 0.9; // Adjust the range of randomness as needed
                return gaussian(i) * randomFactor;
            });

            // Normalize the distribution so that the sum is equal to the provided number
            const sum = distribution.reduce((acc, val) => acc + val, 0);
            const normalizedDistribution = distribution.map(val => (val / sum) * number, 1);

            return normalizedDistribution;
        },
        round_to_precision(x, precision) {
            var y = +x + (precision === undefined ? 0.5 : precision/2);
            return y - (y % (precision === undefined ? 1 : +precision));
        },
        seededRandom(seed) {
            let x = seed || 0;
                return () => {
                x = Math.sin(x) * 10000;
                return x - Math.floor(x);
            }
        },
        getDistribution(startTime, endTime, clicks, delivered, flowRate, seed) {
            let runningHours = Math.round((endTime.unix() - startTime.unix()) / 3600);

            let mean = runningHours / 2;
            if (flowRate === "slow") mean = runningHours * 0.65;
            if (flowRate === "fast") mean = runningHours * 0.35;

            const randomizedDistribution = this.generateRandomizedDistribution(clicks, runningHours, mean, seed);

            let d = 0
            let distribution = []
            for (let s = 0; s < 24; s++) {
                distribution.push(0);
                if (s >= startTime.hour()) {
                    distribution[s] = randomizedDistribution[d];
                    d++;
                }
            }

            const h = moment().hour()
            const pastClicks = distribution.slice(0, h - 1);
            const calculatedSum = pastClicks.reduce((acc, val) => acc + val, 0);

            // Redistribute remaining emails evenly across remaining hours
            const remainingClicks = calculatedSum - 0;

            if (remainingClicks > 0) {
                const remainingHours = distribution.length - pastClicks.length;
                const redistributedValue = remainingClicks / remainingHours;

                distribution.forEach((val, index) => {
                    if ( index >= remainingHours ) {
                        distribution[index] = this.round_to_precision(val + redistributedValue, 1);
                    }
                });
            }

            distribution.forEach((val, index) => {
                distribution[index] = this.round_to_precision(val, 1);
            });

            return distribution
        }
    }
}
</script>

<style scoped>

</style>
