<template>
    <!-- The Modal -->
    <div class="modal fade" id="myLinkModal">
        <div class="modal-dialog modal-lg">
            <div v-if="loading" class="loader-body">
                <div class="loader"></div>
            </div>

            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h2 class="border-bottom pb-4 mb-4">
                        <a target="_blank" :href="`/link-breakdown/${item ? item._id : null}`">Links Breakdown</a>
                    </h2>

<!--                    <div v-if="item && item.unsubscribeLink" class="legend-cat mb-4">-->
<!--                        <div class="legend"><div class="unsub-bg"></div> Unsubscribe Link</div>-->
<!--                    </div>-->

                    <ejs-grid ref="lgrid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete' :pdfExportComplete='exportComplete'
                              :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings" :rowDataBound='rowDataBound'>
                        <e-columns>
                            <e-column field="index" headerText="Index" text-Align="Left" width="200"></e-column>
                            <e-column field="url" headerText="Link URL" text-Align="Left" width="700"></e-column>
                            <e-column field="above" headerText="Above Fold" text-Align="Left" width="200"></e-column>
                            <e-column field="below" headerText="Below Fold" text-Align="Left" width="200"></e-column>
                            <e-column field="clicks" headerText="Clicks" text-Align="Left"></e-column>
                            <e-column field="percent" headerText="%" text-Align="Left"></e-column>
                        </e-columns>
                        <e-aggregates>
                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Sum" field="above" :footerTemplate="sumTemplate"></e-column>
                                    <e-column type="Sum" field="below" :footerTemplate="sumTemplate"></e-column>
                                    <e-column type="Sum" field="clicks" :footerTemplate="sumTemplate"></e-column>
                                    <e-column type="Sum" field="percent" :footerTemplate="percentTemplate"></e-column>
                                </e-columns>
                            </e-aggregate>
                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Custom" field="above" :footerTemplate="aboveTemplate" :customAggregate="customAggregate"></e-column>
                                    <e-column type="Custom" field="below" :footerTemplate="belowTemplate" :customAggregate="customAggregate"></e-column>
                                </e-columns>
                            </e-aggregate>
                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Custom" field="below" :footerTemplate="subTitleTemplate"></e-column>
                                    <e-column type="Custom" field="clicks" :footerTemplate="subTemplate" :customAggregate="subAggregate"></e-column>
                                    <e-column type="Custom" field="percent" :footerTemplate="subPerTemplate" :customAggregate="subAggregate"></e-column>
                                </e-columns>
                            </e-aggregate>
                        </e-aggregates>
                    </ejs-grid>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import {GridPlugin, Group, Page, Sort, Aggregate, Toolbar, PdfExport, ExcelExport} from "@syncfusion/ej2-vue-grids";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import axios from "axios";
import swal from "sweetalert";


Vue.use(GridPlugin);

export default {
    name: "LinksModal",
    provide: {
        grid: [Page, Sort, Group, Aggregate, Toolbar, PdfExport,ExcelExport]
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        "item._id": {
            handler(newValue, oldValue) {
                console.log(newValue, oldValue)
                if(newValue) {
                    this.filter()
                    this.loading = true;
                    if(this.$refs.lgrid) this.$refs.lgrid.ej2Instances.pageSettings.currentPage = 1;
                }
            },
            deep: true
        }
    },
    data() {
        return {
            temp: null,
            loading: true,
            data: [],
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 20, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            sumTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            percentTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{Math.ceil(data.Sum) > 100 ? 100 : Math.ceil(data.Sum)}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            aboveTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Custom.above}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            belowTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Custom.below}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            subTemplate: function() {
                return {
                    template: Vue.component('subTemplate', {
                        template: `<span>{{data.Custom.total}}</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            subTitleTemplate: function() {
                return {
                    template: Vue.component('subTitleTemplate', {
                        template: `<span>Unsubscribed Clicks</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            subPerTemplate: function() {
                return {
                    template: Vue.component('subPerTemplate', {
                        template: `<span>{{data.Custom.percentage}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
        }
    },
    methods: {
        subAggregate : function () {
            let gridData = this.$refs.lgrid.ej2Instances.dataSource;

            let total = 0;
            let totalSum = 0;
            for (let i = 0; i < gridData.length; i++) {
                totalSum = totalSum + gridData[i]['clicks'];
                total = total + gridData[i]['unsubscribe']
            }

            return {total: total, percentage: Math.round((total/totalSum) * 100)}
        },
        customAggregate : function () {
            let gridData = this.$refs.lgrid.ej2Instances.dataSource;

            let totalSum = 0;
            let aboveSum = 0;
            let belowSum = 0;
            for (let i = 0; i < gridData.length; i++) {
                totalSum = totalSum + gridData[i]['clicks'];
                aboveSum = aboveSum + gridData[i]['above'];
                belowSum = belowSum + gridData[i]['below'];
            }

            return {above: Math.round((aboveSum/totalSum) * 100), below: Math.round((belowSum/totalSum) * 100)}
        },
        rowDataBound(args) {
            if (args.data.fold_link == this.item.unsubscribeLink) {
                console.log(args.data.url)
                args.row.classList.add('custom-row-class');
            }
        },
        actionBegin(args) {
            console.log(args)
            if(args.requestType === "paging") window.scrollTo(0,0);
        },
        exportComplete() {
            this.$refs.lgrid.hideSpinner();
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.$refs.lgrid.showSpinner();
                    (this.$refs.lgrid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    this.$refs.lgrid.showSpinner();
                    (this.$refs.lgrid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: `${this.item.name} - links report.xlsx`
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: `${this.item.name} - links report.pdf`
            };
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/admin/reports/emailverifier/link-breakdown/${this.item._id}`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        filter() {
            this.loading = true;
            axios({
                url: `${this.$root.serverUrl}/admin/reports/emailverifier/link-breakdown/${this.item._id}`,
                responseType: "json",
                method: "GET",
            }).then((resp) => {
                this.data = resp.data.Items || [];
                this.loading = false;
            }).catch((err) => {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({ title: "Error", text: this.errormsg, icon: "error" });
                this.loading = false;
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.campaign-container {
    gap: 40px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.modal-body {
    overflow: scroll;
}
.campaign-creative {
    flex: 1 0;
    border: 5px solid grey;
    background: grey;
    height: 100%;
    overflow: scroll;
}
/*.campaign-details {*/
/*  width: 50%;*/
/*}*/
.vrl {
    height: 200px;
    border-left: 1px solid grey;
}
.info-sec {
    display: flex;
    align-items: center;
    gap: 40px;
}
.info-sec i {
    font-size: 100px;
}
.mx-content {
    overflow: scroll;
    height: 100%;
}
.custom-row-class {
    background-color: red;
    color: #FFFFFF;
}
div.unsub-bg {
    background: rgba(255, 0, 0, 1);
}
.legend-cat {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.loader-body {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9999;
    background: #000000ad;
}

.loader {
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
